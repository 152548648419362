import {
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemCreatedAt,
  MayBe,
  ItemUpdatedAt
} from '../../../types';

import { JobId, JobNanoId, JobJobTitle } from '../../jobs/jobsTypes';
import {
  JobScorecardQuestionId,
  JobScorecardQuestionName
} from '../../jobScorecardQuestions/jobScorecardQuestionsTypes';
import {
  JobFunctionId,
  JobFunctionName
} from '../../jobFunctions/jobFunctionsTypes';

import {
  UserId,
  UserNanoId,
  UserBlockedAt,
  UserName,
  UserFullName,
  UserEmail,
  UserCreatedAt,
  UserImage,
  UserRoles,
  UserAccountType
} from '../../users/usersTypes';

export type JobScorecardId = FieldBrand<ItemID, 'JobScorecardId'>;
export type JobScorecardUuid = FieldBrand<ItemUUID, 'JobScorecardUuid'>;
export type MayBeJobScorecardUuid = MayBe<JobScorecardUuid>;
export type JobScorecardJobId = FieldBrand<JobId, 'JobScorecardJobId'>;
export type JobScorecardJob = FieldBrand<
  {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
  },
  'JobScorecardJob'
>;
export type JobScorecardUserId = FieldBrand<UserId, 'JobScorecardUserId'>;
export type JobScorecardUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    blockedAt: UserBlockedAt;
    name: UserName;
    fullName: UserFullName;
    email: UserEmail;
    createdAt: UserCreatedAt;
    image: UserImage;
    roles: UserRoles;
    accountType: UserAccountType;
  },
  'JobScorecardUser'
>;

export type JobScorecardName = FieldBrand<string, 'JobScorecardName'>;
export type JobScorecardPositionFit = FieldBrand<
  string,
  'JobScorecardPositionFit'
>;
export type JobScorecardComments = FieldBrand<string, 'JobScorecardComments'>;
export type JobScorecardJobScorecardQuestion = FieldBrand<
  { id: JobScorecardQuestionId; name: JobScorecardQuestionName },
  'JobScorecardJobScorecardQuestion'
>;
export type JobScorecardJobScorecardQuestions = FieldBrand<
  JobScorecardJobScorecardQuestion[],
  'JobScorecardJobScorecardQuestions'
>;

export type JobScorecardJobFunctionId = FieldBrand<
  JobFunctionId,
  'JobScorecardJobFunctionId'
>;

export type JobScorecardJobFunction = FieldBrand<
  { id: JobFunctionId; name: JobFunctionName },
  'JobScorecardJobFunction'
>;

export enum JobScorecardScorecardTypeEnums {
  'Recruiter Assessment' = 'assessment',
  Interview = 'interview',
  Meeting = 'meeting'
}

export type JobScorecardScorecardType = FieldBrand<
  JobScorecardScorecardTypeEnums,
  'JobScorecardScorecardType'
>;

export enum JobScorecardOverallEvaluationEnums {
  HireNow = 'hire_now',
  GreatFit = 'great_fit',
  GoodFit = 'good_fit',
  OkFit = 'ok_fit',
  NotAFit = 'not_fit'
}

export type JobScorecardOverallEvaluation = FieldBrand<
  JobScorecardOverallEvaluationEnums,
  'JobScorecardOverallEvaluation'
>;

export type JobScorecardNextStep = FieldBrand<string, 'JobScorecardNextStep'>;

export type JobScorecardCreatedAt = FieldBrand<
  ItemCreatedAt,
  'JobScorecardCreatedAt'
>;
export type JobScorecardUpdatedAt = FieldBrand<
  ItemUpdatedAt,
  'JobScorecardUpdatedAt'
>;

export type JobScorecardState = 'edit' | 'preview' | 'view';

export const enum JobScorecardFields {
  ID = 'id',
  UUID = 'uuid',
  JOB_ID = 'jobId',
  JOB = 'job',
  USER_ID = 'userId',
  USER = 'user',
  COMMENTS = 'comments',
  POSITION_FIT = 'positionFit',
  NAME = 'name',
  JOB_SCORECARD_QUESTIONS = 'jobScorecardQuestions',
  OVERALL_EVALUATION = 'overallEvaluation',
  NEXT_STEPS = 'nextSteps',
  SCORECARD_TYPE = 'scorecardType',
  JOB_FUNCTION_ID = 'jobFunctionId',
  JOB_FUNCTION = 'jobFunction',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt'
}
