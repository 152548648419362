import {
  JobResumeRecruiterStatus,
  JobResumeRecruiterStatuses
} from '../../jobResumeRecruitersTypes';

function renderJobResumeRecruiterStatus(status: JobResumeRecruiterStatus) {
  switch (status) {
    case JobResumeRecruiterStatuses.ACTIVELY_LOOKING:
      return 'Actively looking';
    case JobResumeRecruiterStatuses.ACTIVELY_INTERVIEWING:
      return 'Actively Interviewing';
    case JobResumeRecruiterStatuses.PASSIVELY_LOOKING:
      return 'Passively Looking';
    case JobResumeRecruiterStatuses.PASSIVELY_INTERVIEWING:
      return 'Passively Interviewing';
    case JobResumeRecruiterStatuses.NOT_LOOKING:
      return 'Not Looking';
    default:
      return 'None';
  }
}

export default renderJobResumeRecruiterStatus;
