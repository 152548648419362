import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react';
import { MouseEvent, useState } from 'react';

import { Button } from '../../../../../../helpers/Button';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { MoreIcon } from '../../../../../../icons/MoreIcon';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { JobNanoId } from '../../../../jobsTypes';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { ViewJobCandidateDetailsRoutes } from '../../ViewJobCandidateDetailsRoutes';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';
import { HiringPortalSourcingJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalSourcingJobCandidateDetailsRoutes';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import { SourcingJobCandidateDetailsRoutes } from '../../../../../sourcing/SourcingJobCandidateDetailsRoutes';

interface ViewJobCandidateDetailsCandidateHeaderMenuProps {
  jobNanoId: JobNanoId;
  jobCandidateNanoId: JobCandidateNanoId;
  departmentNanoId?: DepartmentNanoId;
  isHiringPortalPath?: boolean;
  isProspectPath?: boolean;
}

export default function ViewJobCandidateDetailsCandidateHeaderMenu({
  jobNanoId,
  jobCandidateNanoId,
  departmentNanoId = '' as DepartmentNanoId,
  isHiringPortalPath,
  isProspectPath
}: ViewJobCandidateDetailsCandidateHeaderMenuProps) {
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const { isOpen, onToggle, onClose } = useDisclosure();

  const hiringPortalSourcingRoutes =
    isHiringPortalPath && isProspectPath
      ? HiringPortalSourcingJobCandidateDetailsRoutes
      : null;

  const hiringPortalRoutes = isHiringPortalPath
    ? HiringPortalJobCandidateDetailsRoutes
    : null;

  const prospectRoute =
    hiringPortalSourcingRoutes || SourcingJobCandidateDetailsRoutes;

  const route = hiringPortalRoutes || ViewJobCandidateDetailsRoutes;

  const MENUS = [
    {
      title: 'Edit',
      link: isProspectPath
        ? prospectRoute.edit(departmentNanoId, jobCandidateNanoId)
        : route.edit(
            jobNanoId as JobNanoId,
            jobCandidateNanoId as JobCandidateNanoId
          )
    },
    {
      title: 'Share Job'
    },
    {
      title: 'Snooze'
    },
    {
      title: 'Unsubscribe'
    },
    {
      title: 'Delete'
    }
  ];

  const changeStage = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setSelectedMenuItem(value);

    onClose();
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy placement="bottom-end">
      <PopoverTrigger>
        <Flex alignItems="stretch" w="full">
          <IconButton
            ml="auto"
            aria-label=""
            size="medium"
            boxShadow="none"
            color="gray.600"
            hierarchy="ghost"
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
            minH={8}
            minW={8}
          >
            <MoreIcon />
          </IconButton>
        </Flex>
      </PopoverTrigger>

      <PopoverContent p={1} w={48} overflow="hidden">
        <Flex flexDir="column" overflow="auto">
          {MENUS.map(({ title, link }) => {
            const As = link ? NextLinkHelper : Box;
            return (
              <As key={title} href={link || ''} style={{ width: '100%' }}>
                <Button
                  px={2}
                  py={1}
                  h="auto"
                  size="small"
                  color="inherit"
                  textAlign="left"
                  lineHeight={1.5}
                  fontSize="inherit"
                  borderRadius="none"
                  fontWeight="inherit"
                  hierarchy="unstyled"
                  textTransform="capitalize"
                  width="100%"
                  isDisabled={selectedMenuItem === title}
                  onClick={(e) => {
                    e.stopPropagation();
                    changeStage(e);
                  }}
                  as={link ? 'a' : 'button'}
                  value={title}
                  _hover={{
                    bg: 'gray.100',
                    cursor: 'pointer',
                    textDecoration: 'none'
                  }}
                >
                  {title}
                </Button>
              </As>
            );
          })}
        </Flex>
      </PopoverContent>
    </Popover>
  );
}
