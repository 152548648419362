import {
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import { Text } from '../../../../../../../helpers/Text';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';
import { InputField } from '../../../../../../../helpers/forms/formFields/InputField';
import { RadioGroupFieldControl } from '../../../../../../../helpers/forms/formFields/RadioGroupField/components/RadioGroupFieldControl';
import { SelectFieldControl } from '../../../../../../../helpers/forms/formFields/SelectField/components/SelectFieldControl';
import { CleaveInputFieldControl } from '../../../../../../../helpers/forms/formFields/CleaveInputField/components/CleaveInputFieldControl';

import { IsDisabled } from '../../../../../../../types';
import { BaseSalaryInputFormFieldFormProps } from '../../BaseSalaryInputFormField.types';

import {
  BaseSalaryFrequencyEnums,
  BaseSalaryFrequencyOptions,
  BaseSalaryRadioInputOptions,
  BaseSalaryRadioInputOptionsEnums
} from '../../BaseSalaryInputFormField.data';

import { FormatNumber } from '../../../../../../../utils/FormatNumber';
import { renderBaseSalary } from '../../../../../utils/renderBaseSalary';
import { baseSalaryBreakdown } from '../../../../../utils/baseSalaryBreakdown';
import { RefCallBack } from 'react-hook-form';

interface BaseSalaryInputFormFieldControlProps {
  onChange?: (val: string) => void;
  value?: string;
  name?: string;
  label?: string;
  ref?: RefCallBack;
  placeholder?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  errorMessage?: string | null;
}

function BaseSalaryInputFormFieldControl({
  label,
  placeholder,
  name,
  value = '',
  onChange,
  ref,
  isDisabled,
  isRequired,
  errorMessage
}: BaseSalaryInputFormFieldControlProps) {
  const [inputValues, setInputValues] =
    useState<BaseSalaryInputFormFieldFormProps>({
      maximum: '',
      minimum: '',
      salary_type: BaseSalaryRadioInputOptionsEnums.RANGE,
      frequency: BaseSalaryFrequencyEnums.ANNUALLY
    });

  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleSubmit = useCallback(() => {
    const { salary_type, minimum, maximum, frequency } = inputValues;

    const maxNumber = FormatNumber.currencyToNumber(maximum);
    const minNumber = FormatNumber.currencyToNumber(minimum);

    const shortMin = FormatNumber.shortNumber(minNumber, 2, true);
    const shortMax = FormatNumber.shortNumber(maxNumber, 2, true);

    const value =
      salary_type === BaseSalaryRadioInputOptionsEnums.RANGE
        ? `${salary_type}_${shortMin}_${shortMax}_${frequency}`
        : `${salary_type}_${shortMax}_${frequency}`;

    onChange?.(value);
    onClose();
  }, [inputValues, onClose, onChange]);

  const handleUpdateInputValuesWithField = useCallback<(value: string) => void>(
    async (value) => {
      if (value && !inputValues.minimum && !inputValues.maximum) {
        const salaryBreakdown = await baseSalaryBreakdown(value);
        setInputValues(salaryBreakdown);
      }
    },
    [inputValues.minimum, inputValues.maximum]
  );

  useEffect(() => {
    handleUpdateInputValuesWithField(value);
  }, [value, handleUpdateInputValuesWithField]);

  const { salary_type, minimum, maximum, frequency } = inputValues;

  const disableSave =
    salary_type === BaseSalaryRadioInputOptionsEnums.RANGE
      ? !salary_type || !minimum || !maximum || !frequency
      : !salary_type || !maximum || !frequency;

  return (
    <Popover isOpen={isOpen}>
      <PopoverTrigger>
        <InputField
          label={label || 'Base Salary'}
          placeholder={placeholder || 'Select base salary'}
          isReadOnly
          ref={ref}
          onClick={onToggle}
          value={renderBaseSalary(value)}
          isDisabled={isDisabled}
          isRequired={isRequired}
          name={name}
          errorMessage={errorMessage}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <Stack spacing={4}>
            <Text textStyle="body2Regular" color="gray.500">
              Base pay amount
            </Text>
            <RadioGroupFieldControl
              options={BaseSalaryRadioInputOptions}
              name="salary_type"
              stackDirection="column"
              value={salary_type}
              onChange={(value) =>
                setInputValues({
                  ...inputValues,
                  salary_type: value as BaseSalaryRadioInputOptionsEnums
                })
              }
            />
            <HStack
              divider={
                <Text textStyle="body1Medium" color="gray.900">
                  -
                </Text>
              }
            >
              {salary_type === BaseSalaryRadioInputOptionsEnums.RANGE ? (
                <CleaveInputFieldControl
                  type="text"
                  cleave
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand'
                  }}
                  value={inputValues.minimum}
                  onChange={(e) =>
                    setInputValues({ ...inputValues, minimum: e.target.value })
                  }
                  leftAddon="$"
                />
              ) : null}
              <CleaveInputFieldControl
                type="text"
                cleave
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand'
                }}
                value={inputValues.maximum}
                onChange={(e) =>
                  setInputValues({ ...inputValues, maximum: e.target.value })
                }
                leftAddon="$"
              />
            </HStack>
            <SelectFieldControl
              options={BaseSalaryFrequencyOptions}
              name="frequency"
              value={frequency}
              onChange={(value) =>
                setInputValues({
                  ...inputValues,
                  frequency: value as BaseSalaryFrequencyEnums
                })
              }
            />
            <Flex alignItems="center" justifyContent="flex-end" gap={2}>
              <PureButtonHelper
                hierarchy="tertiary"
                size="small"
                i18nText="Cancel"
                onClick={onClose}
              />
              <PureButtonHelper
                size="small"
                i18nText="Save"
                isDisabled={disableSave as IsDisabled}
                onClick={handleSubmit}
              />
            </Flex>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default BaseSalaryInputFormFieldControl;
