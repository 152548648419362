import { Avatar, Flex } from '@chakra-ui/react';
import { Text } from '../../../../../helpers/Text';
import { UserIcon } from '../../../../../icons/UserIcon';

interface NoTalentPlaceholderProps {
  profileType?: string;
}

function NoTalentPlaceholder({
  profileType = 'talent'
}: NoTalentPlaceholderProps) {
  return (
    <Flex
      h="100%"
      w="100%"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      gap={4}
    >
      <Avatar size="xl" icon={<UserIcon w="100%" h="100%" fill="gray.50" />} />
      <Text textStyle="body1Medium" color="gray.900">
        No {profileType} found
      </Text>
    </Flex>
  );
}

export default NoTalentPlaceholder;
