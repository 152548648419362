import { Box, Flex, Stack } from '@chakra-ui/react';

import { useFetchJobCandidateByNanoId } from '../../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import {
  FetchJobCandidateEnabled,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidateResponse,
  fetchJobCandidateQuery
} from '../../../../../jobCandidates/queries/fetchJobCandidate.query';
import {
  JobId,
  JobInterviewers,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity,
  MayBeJobCompany,
  MayBeJobHiringManager
} from '../../../../jobsTypes';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { ViewJobCandidateDetailsAssessmentSelection } from './components/ViewJobCandidateDetailsAssessmentSelection';
import { ViewJobCandidateDetailsAssessmentSendEmail } from './components/ViewJobCandidateDetailsAssessmentSendEmail';
import { Heading } from '../../../../../../helpers/Heading';

interface ViewJobCandidateDetailsSendAssessmentSidebarProps {
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
    cities: MayBeJobCities;
    city: MayBeJobCity;
    interviewers: JobInterviewers;
    hiringManager: MayBeJobHiringManager;
    company: MayBeJobCompany;
  };
  jobCandidateNanoId: JobCandidateNanoId;
}

function ViewJobCandidateDetailsSendAssessmentSidebar({
  job,
  jobCandidateNanoId
}: ViewJobCandidateDetailsSendAssessmentSidebarProps) {
  const { totalSteps, currentStep } =
    useViewJobCandidateDetailsSendAssessmentContext();
  const totalStepsArray = Array.from(Array(totalSteps).keys());

  const { jobCandidate } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: jobCandidateNanoId as JobCandidateNanoId,
      enabled: !!jobCandidateNanoId as FetchJobCandidateEnabled,
      cacheKey: JobCandidatesCache.showCacheKey(),
      query: fetchJobCandidateQuery
    });

  return (
    <>
      <Stack
        spacing={0}
        top="73px"
        right={0}
        w="400px"
        zIndex={999}
        pos="fixed"
        bg="white"
        flexDir="column"
        pointerEvents="none"
        h="calc(100vh - 137px)"
        borderLeft="1px solid"
        borderLeftColor="gray.200"
      >
        <Flex p={6} pb={2} gap={4}>
          {totalStepsArray.map((step) => (
            <Box
              h={1}
              w="full"
              key={step}
              borderRadius="full"
              bg={currentStep < step + 1 ? 'gray.300' : 'primary.500'}
            />
          ))}
        </Flex>

        <Flex
          px={6}
          pt={4}
          pb={6}
          mr={-6}
          gap={6}
          h="full"
          w="full"
          overflow="auto"
          flexDir="column"
          pointerEvents="all"
        >
          <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
            Candidate assessment by email
          </Heading>

          {jobCandidate && (
            <>
              {currentStep === 1 && (
                <ViewJobCandidateDetailsAssessmentSelection
                  jobCandidate={jobCandidate}
                  job={job}
                />
              )}

              {currentStep === 2 && (
                <ViewJobCandidateDetailsAssessmentSendEmail
                  jobCandidate={jobCandidate}
                  job={job}
                />
              )}
            </>
          )}
        </Flex>
      </Stack>
    </>
  );
}

export default ViewJobCandidateDetailsSendAssessmentSidebar;
