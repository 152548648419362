import { JobCategory, JobNanoId } from '../jobs/jobsTypes';

import { JobResumeRecruiterNanoId } from '../jobResumeRecruiters/jobResumeRecruitersTypes';

import { UserNanoId } from '../users/usersTypes';

const baseCareersPath = '/career-site';

export class CareerSiteRoutes {
  static index() {
    return `${baseCareersPath}`;
  }

  static jobAssessment() {
    return `${baseCareersPath}/assessment`;
  }

  static jobs(recruiterNanoId: UserNanoId) {
    return `${baseCareersPath}/${recruiterNanoId}/jobs`;
  }

  static jobPreview(recruiterNanoId: UserNanoId, jobNanoId: JobNanoId) {
    return `${baseCareersPath}/${recruiterNanoId}/jobs/${jobNanoId}`;
  }

  static job(jobNanoId: JobNanoId) {
    return `${baseCareersPath}/jobs/${jobNanoId}`;
  }

  static confirmEmail(jobNanoId: JobNanoId, email: string) {
    return `${baseCareersPath}/jobs/${jobNanoId}/confirm-email/${email}`;
  }

  static welcomeBase() {
    return `${baseCareersPath}/jobs/welcome`;
  }

  static welcome(jobNanoId: JobNanoId) {
    return `${baseCareersPath}/jobs/welcome/${jobNanoId}`;
  }

  static apply(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/apply`;
  }

  static basicInformation(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/basic-information`;
  }

  static preferences(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/preferences`;
  }

  static qualifications(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/qualifications`;
  }

  static skills(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/skills`;
  }

  static review(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/review`;
  }

  static workExperience(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/work-experience`;
  }

  static certifications(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/certifications`;
  }

  static education(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/education`;
  }

  static profile(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/profile`;
  }

  static profileFiles(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/profile/files`;
  }

  static profileAvailabilityModal(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/profile?availabilityModal=true`;
  }

  static jobDetails(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId,
    jobCategory: JobCategory
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/profile/${jobCategory}/${jobNanoId}`;
  }

  static assessment(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/assessment`;
  }

  static assessmentQuestions(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/assessment-questions`;
  }

  static assessmentResponse(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/assessment-response`;
  }

  static assessmentProjects(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/assessment-projects`;
  }

  static assessmentReferences(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/assessment-references`;
  }

  static congrats(
    jobNanoId: JobNanoId,
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return `${baseCareersPath}/jobs/${jobNanoId}/${jobResumeRecruiterNanoId}/congrats`;
  }
}
