import { JobId, JobNanoId } from '../jobs/jobsTypes';
import {
  FetchJobScorecardsCacheKey,
  FetchJobScorecardCacheKey
} from './jobScorecardsTypes';

export class JobScorecardsCache {
  static indexCacheKey() {
    return 'jobScorecards' as FetchJobScorecardsCacheKey;
  }

  static showCacheKey() {
    return 'jobScorecard' as FetchJobScorecardCacheKey;
  }

  static jobIndexCacheKey(jobNanoId: JobNanoId | JobId) {
    return `jobScorecards-job-${jobNanoId}` as FetchJobScorecardsCacheKey;
  }
}
