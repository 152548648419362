import snakeCase from 'lodash/snakeCase';

import {
  FetchEmploymentsQuery,
  EmploymentFields,
  EmploymentId,
  EmploymentNanoId,
  EmploymentName,
  MayBeEmploymentStartDate,
  MayBeEmploymentEndDate,
  EmploymentEmploymentType,
  EmploymentDescription,
  EmploymentCurrently,
  MayBeEmploymentCompanyId,
  MayBeEmploymentCompany,
  MayBeEmploymentStateId,
  MayBeEmploymentState,
  MayBeEmploymentCityId,
  MayBeEmploymentCity,
  EmploymentJobResumeRecruiterId,
  EmploymentCreatedAt,
  EmploymentJobDepartment,
  // EmploymentJobFunctions,
  EmploymentSpecializationIds,
  EmploymentSpecializations,
  EmploymentIndustryIds,
  EmploymentIndustries,
  EmploymentJobTitle,
  EmploymentJobTitleId,
  EmploymentDisciplineId,
  EmploymentDiscipline,
  MayBeEmploymentReasonForLeaving,
  MayBeEmploymentSupervisorsTitle,
  MayBeEmploymentSupervisorsName,
  EmploymentJobFunctionIds,
  EmploymentJobFunctions,
  EmploymentJobSatisfaction,
  MayBeEmploymentMoreAboutLeaving
} from '../employmentsTypes';

export interface FetchEmploymentsResponse {
  [EmploymentFields.ID]: EmploymentId;
  [EmploymentFields.NANO_ID]: EmploymentNanoId;
  [EmploymentFields.NAME]: EmploymentName;
  [EmploymentFields.START_DATE]: MayBeEmploymentStartDate;
  [EmploymentFields.END_DATE]: MayBeEmploymentEndDate;
  [EmploymentFields.EMPLOYMENT_TYPE]: EmploymentEmploymentType;
  [EmploymentFields.DESCRIPTION]: EmploymentDescription;
  [EmploymentFields.JOB_DEPARTMENT]: EmploymentJobDepartment;
  // [EmploymentFields.JOB_FUNCTIONS]: EmploymentJobFunctions;
  [EmploymentFields.CREATED_AT]: EmploymentCreatedAt;
  [EmploymentFields.CURRENTLY]: EmploymentCurrently;
  [EmploymentFields.COMPANY_ID]: MayBeEmploymentCompanyId;
  [EmploymentFields.COMPANY]: MayBeEmploymentCompany;
  [EmploymentFields.STATE_ID]: MayBeEmploymentStateId;
  [EmploymentFields.STATE]: MayBeEmploymentState;
  [EmploymentFields.CITY_ID]: MayBeEmploymentCityId;
  [EmploymentFields.CITY]: MayBeEmploymentCity;
  [EmploymentFields.JOB_RESUME_RECRUITER_ID]: EmploymentJobResumeRecruiterId;
  [EmploymentFields.SPECIALIZATION_IDS]: EmploymentSpecializationIds;
  [EmploymentFields.SPECIALIZATIONS]: EmploymentSpecializations;
  [EmploymentFields.INDUSTRY_IDS]: EmploymentIndustryIds;
  [EmploymentFields.INDUSTRIES]: EmploymentIndustries;
  [EmploymentFields.JOB_TITLE_ID]: EmploymentJobTitleId;
  [EmploymentFields.JOB_TITLE]: EmploymentJobTitle;
  [EmploymentFields.DISCIPLINE_ID]: EmploymentDisciplineId;
  [EmploymentFields.DISCIPLINE]: EmploymentDiscipline;
  [EmploymentFields.JOB_FUNCTION_IDS]: EmploymentJobFunctionIds;
  [EmploymentFields.JOB_FUNCTIONS]: EmploymentJobFunctions;
  [EmploymentFields.SUPERVISORS_NAME]: MayBeEmploymentSupervisorsName;
  [EmploymentFields.SUPERVISORS_TITLE]: MayBeEmploymentSupervisorsTitle;
  [EmploymentFields.REASON_FOR_LEAVING]: MayBeEmploymentReasonForLeaving;
  [EmploymentFields.MORE_ABOUT_LEAVING]: MayBeEmploymentMoreAboutLeaving;
  [EmploymentFields.JOB_SATISFACTION]: EmploymentJobSatisfaction;
}

export const fetchEmploymentsColumns = [
  EmploymentFields.ID,
  EmploymentFields.NANO_ID,
  EmploymentFields.NAME,
  EmploymentFields.START_DATE,
  EmploymentFields.END_DATE,
  EmploymentFields.EMPLOYMENT_TYPE,
  EmploymentFields.DESCRIPTION,
  EmploymentFields.JOB_DEPARTMENT,
  EmploymentFields.CREATED_AT,
  EmploymentFields.CURRENTLY,
  EmploymentFields.COMPANY_ID,
  EmploymentFields.STATE_ID,
  EmploymentFields.CITY_ID,
  EmploymentFields.JOB_RESUME_RECRUITER_ID,
  EmploymentFields.JOB_TITLE_ID,
  EmploymentFields.DISCIPLINE_ID,
  EmploymentFields.JOB_SATISFACTION,
  EmploymentFields.JOB_FUNCTION_ID
];

const fetchEmploymentsSBColumns = fetchEmploymentsColumns.map(
  (fetchEmploymentsColumn) => snakeCase(fetchEmploymentsColumn)
);

export const fetchEmploymentsQuery = `
  ${fetchEmploymentsSBColumns.join(',')},
  user:user_id(id,nano_id,name,image:image_id(id,nano_id,name,file,key,created_at)),
  company:company_id(id,nano_id,name,created_at,image:image_id(id,nano_id,name,file,key,created_at)),
  state:state_id(id,nano_id,name,iso_code),
  city:city_id(id,nano_id,name,label)
` as FetchEmploymentsQuery;
