import { Collapse, Flex } from '@chakra-ui/react';
import { TextareaField } from '../../../../../../../../helpers/forms/formFields/TextareaField';
import Rating from '../../../../../../../../helpers/Rating/Rating';
import { Text } from '../../../../../../../../helpers/Text';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';

interface CustomiseCandidateSubmissionEvaluationItem {
  evaluationKey: string;
  index: number;
  isOpen: boolean;
  onToggle: () => void;
}

export default function CustomiseCandidateSubmissionEvaluationItem({
  evaluationKey,
  isOpen,
  onToggle
}: CustomiseCandidateSubmissionEvaluationItem) {
  const { evaluations, updateEvaluations } =
    useViewJobCandidateDetailsSubmissionContext();

  const evaluation = evaluations[evaluationKey];

  if (!evaluation.scorecardQuestion) return <></>;

  return (
    <Flex flexDir="column" gap={2}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text textStyle="body1Regular" lineHeight="20px">
          {evaluation.scorecardQuestion}
        </Text>

        <Rating
          value={evaluation.rating}
          onClick={(event) => {
            const { value } = event.currentTarget;
            updateEvaluations({
              ...evaluation,
              evaluationKey: evaluationKey,
              rating: parseInt(value)
            });

            onToggle();
          }}
          iconSize={4}
        />
      </Flex>

      <Collapse in={isOpen}>
        <Flex p={0.5} gap={2} flexDir="column">
          <TextareaField
            rows={3}
            placeholder="Add a comment"
            fontSize="sm"
            value={evaluation.comment}
            onChange={(event) => {
              const { value } = event.currentTarget;
              updateEvaluations({
                ...evaluation,
                evaluationKey: evaluationKey,
                comment: value
              });
            }}
          />
        </Flex>
      </Collapse>
    </Flex>
  );
}
