import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemIDs,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  ItemEmail
} from '../../../types';

import {
  CityId,
  CityIds,
  CityLabel,
  CityName,
  CityNanoId
} from '../../cities/citiesTypes';

import {
  CompanyId,
  CompanyName,
  CompanyNanoId
} from '../../companies/companiesTypes';

import {
  ImageId,
  ImageNanoId,
  ImageKey,
  ImageName,
  ImageFile,
  ImageCreatedAt
} from '../../images/imagesTypes';

import {
  IndustryId,
  IndustryIds,
  IndustryName
} from '../../industries/industriesTypes';

import { JobId, JobName, JobNanoId } from '../../jobs/jobsTypes';

import {
  JobResumeId,
  JobResumeNanoId,
  JobResumeFirstName,
  JobResumeLastName,
  JobResumeJobTitle,
  JobResumePhone,
  JobResumeEmail,
  JobResumeStateId,
  JobResumeCityId,
  JobResumeFileAttachments
} from '../../jobResumes/jobResumesTypes';

import { SkillId, SkillIds } from '../../skills/skillsTypes';

import {
  SpecializationId,
  SpecializationIds,
  SpecializationName
} from '../../specializations/specializationsTypes';

import {
  StateId,
  StateIsoCode,
  StateName,
  StateNanoId
} from '../../states/statesTypes';

import { UserUuid } from '../../users/usersTypes';

export type JobResumeRecruiterId = FieldBrand<ItemID, 'JobResumeRecruiterId'>;
export type MayBeJobResumeRecruiterId = MayBe<JobResumeRecruiterId>;
export type JobResumeRecruiterIds = FieldBrand<
  ItemIDs,
  'JobResumeRecruiterIds'
>;
export type JobResumeRecruiterUuid = FieldBrand<
  ItemUUID,
  'JobResumeRecruiterUuid'
>;
export type MayBeJobResumeRecruiterUuid = MayBe<JobResumeRecruiterUuid>;
export type JobResumeRecruiterNanoId = FieldBrand<
  ItemNanoId,
  'JobResumeRecruiterNanoId'
>;
export type MayBeJobResumeRecruiterNanoId = MayBe<JobResumeRecruiterNanoId>;
export type JobResumeRecruiterName = FieldBrand<
  string,
  'JobResumeRecruiterName'
>;
export type JobResumeRecruiterTitle = FieldBrand<
  string,
  'JobResumeRecruiterTitle'
>;
export type JobResumeRecruiterPhone = FieldBrand<
  string,
  'JobResumeRecruiterPhone'
>;
export type JobResumeRecruiterEmail = FieldBrand<
  ItemEmail,
  'JobResumeRecruiterEmail'
>;
export type JobResumeRecruiterDescription = FieldBrand<
  string,
  'JobResumeRecruiterDescription'
>;
export type JobResumeRecruiterSearchableAt = FieldBrand<
  string,
  'JobResumeRecruiterSearchableAt'
>;
export type MayBeJobResumeRecruiterSearchableAt =
  MayBe<JobResumeRecruiterSearchableAt>;
export type JobResumeRecruiterUserUuid = FieldBrand<
  UserUuid,
  'JobResumeRecruiterUserUuid'
>;
export type JobResumeRecruiterLinkedinUrl = FieldBrand<
  string,
  'JobResumeRecruiterLinkedinUrl'
>;
export type JobResumeRecruiterStatus = FieldBrand<
  JobResumeRecruiterStatuses,
  'JobResumeRecruiterStatus'
>;
export type JobResumeRecruiterWorkplaceTypes = FieldBrand<
  string[],
  'JobResumeRecruiterWorkplaceTypes'
>;
export type JobResumeRecruiterEmploymentType = FieldBrand<
  string,
  'JobResumeRecruiterEmploymentType'
>;
export type JobResumeRecruiterEmploymentTypes = FieldBrand<
  JobResumeRecruiterEmploymentType[],
  'JobResumeRecruiterEmploymentTypes'
>;
export type JobResumeRecruiterPaidTimeOff = FieldBrand<
  string,
  'JobResumeRecruiterPaidTimeOff'
>;
export type JobResumeRecruiterMinimumDesiredPay = FieldBrand<
  string,
  'JobResumeRecruiterMinimumDesiredPay'
>;
export type JobResumeRecruiterNewJobStartAvailability = FieldBrand<
  string,
  'JobResumeRecruiterNewJobStartAvailability'
>;
export type JobResumeRecruiterActivelyInterviewing = FieldBrand<
  string,
  'JobResumeRecruiterActivelyInterviewing'
>;
export type JobResumeRecruiterCounterOffer = FieldBrand<
  string,
  'JobResumeRecruiterCounterOffer'
>;
export type JobResumeRecruiterEmployerCounterOffer = FieldBrand<
  string,
  'JobResumeRecruiterEmployerCounterOffer'
>;
export type MayBeJobResumeRecruiterMinimumDesiredPay =
  MayBe<JobResumeRecruiterMinimumDesiredPay>;
export type JobResumeRecruiterMaximumDesiredPay = FieldBrand<
  string,
  'JobResumeRecruiterMaximumDesiredPay'
>;
export type MayBeJobResumeRecruiterMaximumDesiredPay =
  MayBe<JobResumeRecruiterMaximumDesiredPay>;
export type JobResumeRecruiterPrimaryJobFunction = FieldBrand<
  string,
  'JobResumeRecruiterPrimaryJobFunction'
>;
export type JobResumeRecruiterWorkAuthorization = FieldBrand<
  string,
  'JobResumeRecruiterWorkAuthorization'
>;
export type JobResumeRecruiterEmploymentStatus = FieldBrand<
  string,
  'JobResumeRecruiterEmploymentStatus'
>;
export type JobResumeRecruiterPhoneCallAvailability = FieldBrand<
  string,
  'JobResumeRecruiterPhoneCallAvailability'
>;
export type JobResumeRecruiterFutureRequirements = FieldBrand<
  string,
  'JobResumeRecruiterFutureRequirements'
>;
export type JobResumeRecruiterProfessionalExperience = FieldBrand<
  string,
  'JobResumeRecruiterProfessionalExperience'
>;
export type MayBeJobResumeRecruiterProfessionalExperience =
  MayBe<JobResumeRecruiterProfessionalExperience>;

export type JobResumeRecruiterCompanyId = FieldBrand<
  CompanyId,
  'JobResumeRecruiterCompanyId'
>;
export type MayBeJobResumeRecruiterCompanyId =
  MayBe<JobResumeRecruiterCompanyId>;
export type JobResumeRecruiterCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
  },
  'JobResumeRecruiterCompany'
>;
export type MayBeJobResumeRecruiterCompany = MayBe<JobResumeRecruiterCompany>;
export type JobResumeRecruiterStateId = FieldBrand<
  StateId,
  'JobResumeRecruiterStateId'
>;
export type MayBeJobResumeRecruiterStateId = MayBe<JobResumeRecruiterStateId>;
export type JobResumeRecruiterState = FieldBrand<
  {
    id: StateId;
    nanoId: StateNanoId;
    name: StateName;
    isoCode: StateIsoCode;
  },
  'JobResumeRecruiterState'
>;
export type MayBeJobResumeRecruiterState = MayBe<JobResumeRecruiterState>;
export type JobResumeRecruiterCityId = FieldBrand<
  CityId,
  'JobResumeRecruiterCityId'
>;
export type MayBeJobResumeRecruiterCityId = MayBe<JobResumeRecruiterCityId>;
export type JobResumeRecruiterCityIds = FieldBrand<
  CityIds,
  'JobResumeRecruiterCityIds'
>;
export type MayBeJobResumeRecruiterCityIds = MayBe<JobResumeRecruiterCityIds>;
export type JobResumeRecruiterCity = FieldBrand<
  {
    id: CityId;
    nanoId: CityNanoId;
    name: CityName;
    label: CityLabel;
  },
  'JobResumeRecruiterCity'
>;
export type MayBeJobResumeRecruiterCity = MayBe<JobResumeRecruiterCity>;
export type JobResumeRecruiterCities = FieldBrand<
  {
    id: CityId;
    nanoId: CityNanoId;
    name: CityName;
    label: CityLabel;
  }[],
  'JobResumeRecruiterCities'
>;
export type MayBeJobResumeRecruiterCities = MayBe<JobResumeRecruiterCities>;
export type JobResumeRecruiterJobId = FieldBrand<
  JobId,
  'JobResumeRecruiterJobId'
>;
export type JobResumeRecruiterJob = FieldBrand<
  {
    id: JobId;
    nanoId: JobNanoId;
    name: JobName;
  },
  'JobResumeRecruiterJob'
>;
export type JobResumeRecruiterJobResumeId = FieldBrand<
  JobResumeId,
  'JobResumeRecruiterJobResumeId'
>;
export type JobResumeRecruiterJobResume = FieldBrand<
  {
    id: JobResumeId;
    nanoId: JobResumeNanoId;
    firstName: JobResumeFirstName;
    lastName: JobResumeLastName;
    jobTitle: JobResumeJobTitle;
    email: JobResumeEmail;
    phone: JobResumePhone;
    stateId: JobResumeStateId;
    cityId: JobResumeCityId;
    fileAttachments: JobResumeFileAttachments;
  },
  'JobResumeRecruiterJobResume'
>;
export type JobResumeRecruiterImageId = FieldBrand<
  ImageId,
  'JobResumeRecruiterImageId'
>;
export type MayBeJobResumeRecruiterImageId = MayBe<JobResumeRecruiterImageId>;
export type JobResumeRecruiterImage = FieldBrand<
  {
    id: ImageId;
    nanoId: ImageNanoId;
    name: ImageName;
    file: ImageFile;
    key: ImageKey;
    createdAt: ImageCreatedAt;
  },
  'JobResumeRecruiterImage'
>;
export type MayBeJobResumeRecruiterImage = MayBe<JobResumeRecruiterImage>;
export type JobResumeRecruiterIndustryIds = FieldBrand<
  IndustryIds,
  'JobResumeRecruiterIndustryIds'
>;
export type JobResumeRecruiterIndustries = FieldBrand<
  { id: IndustryId; name: IndustryName }[],
  'JobResumeRecruiterIndustries'
>;
export type JobResumeRecruiterSpecializationIds = FieldBrand<
  SpecializationIds,
  'JobResumeRecruiterSpecializationIds'
>;
export type JobResumeRecruiterSpecializations = FieldBrand<
  { id: SpecializationId; name: SpecializationName }[],
  'JobResumeRecruiterSpecializations'
>;
export type JobResumeRecruiterSkillIds = SkillIds;
export type JobResumeRecruiterJobResumeRecruitersSkills = {
  required: boolean;
  skillId: SkillId;
}[];
export type JobResumeRecruiterUsersCount = number;
export type JobResumeRecruiterCreatedAt = FieldBrand<
  ItemCreatedAt,
  'JobResumeRecruiterCreatedAt'
>;

export enum JobResumeRecruiterStatuses {
  NONE = 'none',
  ACTIVELY_LOOKING = 'actively_looking',
  ACTIVELY_INTERVIEWING = 'actively_interviewing',
  PASSIVELY_LOOKING = 'passively_looking',
  PASSIVELY_INTERVIEWING = 'passively_interviewing',
  OFF_MARKET = 'off_market',
  NOT_LOOKING = 'not_looking'
}

export enum JobResumeRecruiterEmploymentTypesEnum {
  NONE = 'none',
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
  CONTRACT = 'contract',
  INTERN = 'intern'
}

export enum JobResumeRecruiterProfessionalExperiences {
  NONE = 'none',
  ACTIVELY_LOOKING = 'actively_looking',
  ACTIVELY_INTERVIEWING = 'actively_interviewing',
  PASSIVELY_LOOKING = 'passively_looking',
  PASSIVELY_INTERVIEWING = 'passively_interviewing',
  OFF_MARKET = 'off_market',
  NOT_LOOKING = 'not_looking'
}

export enum JobResumeRecruiterActivelyInterviewingOptions {
  YES = 'yes',
  NO = 'no'
}

export enum JobResumeRecruiterCounterOfferOptions {
  YES = 'yes',
  NO = 'no',
  NOT_ANTIIPATED = 'not_anticipated',
  ANTICIPATED_ONE_WEEK = 'anticipated_one_week',
  ANTICIPATED_TWO_WEEK = 'anticipated_two_weeks'
}

export const enum JobResumeRecruiterFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  TITLE = 'title',
  PHONE = 'phone',
  EMAIL = 'email',
  LINKEDIN_URL = 'linkedinUrl',
  STATUS = 'status',
  EMPLOYMENT_TYPES = 'employmentTypes',
  WORKPLACE_TYPES = 'workplaceTypes',
  PREFERRED_LOCATIONS = 'preferredLocations',
  PAID_TIME_OFF = 'paidTimeOff',
  MINIMUM_DESIRED_PAY = 'minimumDesiredPay',
  MAXIMUM_DESIRED_PAY = 'maximumDesiredPay',
  PRIMARY_JOB_FUNCTION = 'primaryJobFunction',
  WORK_AUTHORIZATION = 'workAuthorization',
  FUTURE_REQUIREMENTS = 'futureRequirements',
  PROFESSIONAL_EXPERIENCE = 'professionalExperience',
  DESCRIPTION = 'description',
  SEARCHABLE_AT = 'searchableAt',
  JOB_RESUME_ID = 'jobResumeId',
  JOB_RESUME = 'jobResume',
  STATE_ID = 'stateId',
  STATE = 'state',
  CITY_ID = 'cityId',
  CITY = 'city',
  CITY_IDS = 'cityIds',
  CITIES = 'cities',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  IMAGE_ID = 'imageId',
  IMAGE = 'image',
  INDUSTRY_IDS = 'industryIds',
  INDUSTRIES = 'industries',
  SPECIALIZATION_IDS = 'specializationIds',
  SPECIALIZATIONS = 'specializations',
  SKILL_IDS = 'skillIds',

  JOB_RESUME_RECRUITERS_SKILLS = 'jobResumeRecruitersSkills',
  USERS_COUNT = 'usersCount',
  CREATED_AT = 'createdAt',

  EMPLOYMENT_STATUS = 'employmentStatus',
  PHONE_CALL_AVAILABILITY = 'phoneCallAvailability',

  NEW_JOB_START_AVAILABILITY = 'newJobStartAvailability',
  EMPLOYER_COUNTER_OFFER = 'employerCounterOffer',
  ACTIVELY_INTERVIEWING = 'activelyInterviewing',
  COUNTER_OFFER = 'counterOffer'
}
