import {
  Box,
  Container,
  Flex,
  Grid,
  Stack,
  useDisclosure,
  useMediaQuery
} from '@chakra-ui/react';
import router from 'next/router';
import { ReactNode } from 'react';
import { FullPageLoader } from '../../../../../../helpers/FullPageLoader';
import { Logo } from '../../../../../../helpers/Logo';
import { LogoIconLoop } from '../../../../../../helpers/Lotties/LogoIconLoop';
import { PageHead } from '../../../../../../helpers/PageHead';
import { Text } from '../../../../../../helpers/Text';
import { useFetchJobResumeRecruiterByNanoId } from '../../../../../jobResumeRecruiters/hooks/useFetchJobResumeRecruiterByNanoId';
import { JobResumeRecruitersCache } from '../../../../../jobResumeRecruiters/JobResumeRecruitersCache';
import { JobResumeRecruiterNanoId } from '../../../../../jobResumeRecruiters/jobResumeRecruitersTypes';
import {
  FetchJobResumeRecruiterResponse,
  fetchJobResumeRecruiterQuery
} from '../../../../../jobResumeRecruiters/queries/fetchJobResumeRecruiter.query';
import { useFetchJobByNanoId } from '../../../../../jobs/hooks/useFetchJobByNanoId';
import { JobsCache } from '../../../../../jobs/JobsCache';
import { JobCategory, JobNanoId } from '../../../../../jobs/jobsTypes';
import {
  FetchJobResponse,
  fetchJobQuery
} from '../../../../../jobs/queries/fetchJob.query';
import { CareerSiteRoutes } from '../../../../CareerSiteRoutes';
import { CareerSiteAccountDropdown } from '../../../../components/CareerSiteAccountDropdown';
import { CareerSiteApplyPageRightSidebar } from '../../components/CareerSiteApplyPageRightSidebar';
import { CareerSiteApplyProfilePageBio } from './components/CareerSiteApplyProfilePageBio';
import { CareerSiteApplyProfilePageJobHeader } from './components/CareerSiteApplyProfilePageJobHeader';
import { CareerSiteApplyProfilePageNav } from './components/CareerSiteApplyProfilePageNav';
import { CareerSiteApplyProfilePageOverview } from './components/CareerSiteApplyProfilePageOverview';

interface CareerSiteApplyProfileLayoutProps {
  children: ReactNode;
  jobNanoId: JobNanoId;
  onSignOut: () => void;
  jobResumeRecruiterNanoId: JobResumeRecruiterNanoId;
}

export default function CareerSiteApplyProfileLayout({
  children,
  onSignOut,
  jobNanoId,
  jobResumeRecruiterNanoId
}: CareerSiteApplyProfileLayoutProps) {
  const [isDesktop] = useMediaQuery('(min-width: 992px)', {
    ssr: true,
    fallback: false
  });

  const { jobResumeRecruiter, jobResumeRecruiterIsLoading } =
    useFetchJobResumeRecruiterByNanoId<FetchJobResumeRecruiterResponse>({
      jobResumeRecruiterNanoId: jobResumeRecruiterNanoId,
      query: fetchJobResumeRecruiterQuery,
      cacheKey: JobResumeRecruitersCache.showCacheKey()
    });

  const { job } = useFetchJobByNanoId<FetchJobResponse>({
    query: fetchJobQuery,
    jobNanoId: jobNanoId,
    cacheKey: JobsCache.showCacheKey()
  });

  const { onToggle: toggleProfileOverview, isOpen: isProfileOverviewOpen } =
    useDisclosure({
      defaultIsOpen: true
    });

  const goToJobAppliedDetails = (nanoId: JobNanoId) => {
    router.push(
      CareerSiteRoutes.jobDetails(
        nanoId as JobNanoId,
        jobResumeRecruiterNanoId as JobResumeRecruiterNanoId,
        'job-applied' as JobCategory
      )
    );
  };

  const goToNewJobDetails = (nanoId: JobNanoId) => {
    router.push(
      CareerSiteRoutes.jobDetails(
        nanoId as JobNanoId,
        jobResumeRecruiterNanoId as JobResumeRecruiterNanoId,
        'new-job' as JobCategory
      )
    );
  };

  const appliedJobDetailsUrl = (nanoId: JobNanoId) => {
    return CareerSiteRoutes.jobDetails(
      nanoId as JobNanoId,
      jobResumeRecruiterNanoId as JobResumeRecruiterNanoId,
      'job-applied' as JobCategory
    );
  };

  const newJobDetailsUrl = (nanoId: JobNanoId) => {
    return CareerSiteRoutes.jobDetails(
      nanoId as JobNanoId,
      jobResumeRecruiterNanoId as JobResumeRecruiterNanoId,
      'new-job' as JobCategory
    );
  };

  return (
    <>
      {jobResumeRecruiter && (
        <PageHead i18nTitle={`${jobResumeRecruiter?.name}'s profile`} />
      )}

      <Grid
        gridTemplateRows={{ base: '80px 1fr', lg: '80px 1fr 48px' }}
        height="100vh"
        gap={{ base: 0, lg: 6 }}
        bg={{ lg: 'gray.50' }}
      >
        <Flex
          bg="white"
          alignItems="center"
          justifyContent="center"
          boxShadow="0px 1px 2px -1px rgba(0, 0, 0, 0.32)"
          zIndex={4}
          px={6}
        >
          <Container>
            <Flex alignItems="center" justifyContent="stretch" flex={1}>
              <Box
                flex={1}
                display={{
                  base: jobResumeRecruiterNanoId && 'none',
                  md: 'flex'
                }}
              />

              <Flex
                gap={4}
                justifyContent="flex-end"
                alignItems="center"
                flex={1}
              >
                {jobResumeRecruiterNanoId ? (
                  <CareerSiteAccountDropdown
                    jobResumeRecruiterNanoId={jobResumeRecruiterNanoId}
                    onSignOut={onSignOut}
                  />
                ) : null}
              </Flex>
            </Flex>
          </Container>
        </Flex>

        <Flex
          px={4}
          pt={{ base: 0 }}
          pb={{ base: 4, lg: 0 }}
          flexDir={isDesktop ? 'row' : 'column'}
          justifyContent={isDesktop ? 'center' : 'flex-start'}
          alignItems={isDesktop ? 'flex-start' : 'center'}
          overflow="auto"
          gap={6}
        >
          <Box
            w="100%"
            h="full"
            flex={1}
            maxW="1100px"
            transitionProperty="max-width"
            transitionDuration="fast"
          >
            {jobResumeRecruiterIsLoading && (
              <FullPageLoader loader={<LogoIconLoop />} />
            )}

            {job && jobResumeRecruiter && (
              <Box>
                <CareerSiteApplyProfilePageJobHeader job={job} />

                <CareerSiteApplyProfilePageBio
                  jobResumeRecruiter={jobResumeRecruiter}
                />

                <CareerSiteApplyProfilePageNav
                  jobResumeRecruiterNanoId={jobResumeRecruiterNanoId}
                  jobNanoId={jobNanoId}
                />

                <Flex gap={4} h="100%" pos="relative">
                  <CareerSiteApplyProfilePageOverview
                    jobResumeRecruiter={jobResumeRecruiter}
                    onToggle={toggleProfileOverview}
                    isOpen={isProfileOverviewOpen}
                    jobNanoId={jobNanoId}
                  />

                  <Stack
                    flex={1}
                    spacing={0}
                    pos="relative"
                    overflow="hidden"
                    w="100%"
                  >
                    {children}
                  </Stack>
                </Flex>
              </Box>
            )}
          </Box>

          <CareerSiteApplyPageRightSidebar
            goToJobAppliedDetails={goToJobAppliedDetails}
            goToNewJobDetails={goToNewJobDetails}
            appliedJobDetailsUrl={appliedJobDetailsUrl}
            newJobDetailsUrl={newJobDetailsUrl}
            currentJobNanoId={jobNanoId}
          />
        </Flex>

        <Flex
          h={12}
          gap={2}
          bg="#F7F7F9"
          alignItems="center"
          justifyContent="center"
          display={{ base: 'none', lg: 'flex' }}
          boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
        >
          <Flex alignItems="center">
            <Text color="gray.600" mt={-2.5}>
              Powered by
            </Text>
            <Logo h={8} w={20} />
          </Flex>
        </Flex>
      </Grid>
    </>
  );
}
