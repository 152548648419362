import { DateUtils } from '../../../../utils/DateUtils';
import { CalendarHelper } from '../CalendarHelper';

interface ScheduleHelperCalendarProps {
  minDate?: Date | null;
  maxDate?: Date | null;
  selectedDate: string;
  disableWeekend?: boolean;
  selectedTimeSlots: string[];
  handleDateSelection: (date: string) => void;
}

export default function ScheduleHelperCalendar({
  minDate,
  maxDate,
  selectedDate,
  disableWeekend,
  selectedTimeSlots,
  handleDateSelection
}: ScheduleHelperCalendarProps) {
  const selectedDates = selectedTimeSlots.flatMap((range) => {
    const [start] = range.split('/');
    const date = DateUtils.toDate(start);
    return date ? [date] : [];
  });

  return (
    <CalendarHelper
      maxDate={maxDate}
      date={DateUtils.toDate(selectedDate)}
      minDate={minDate || DateUtils.toDate(DateUtils.now())}
      onChange={(date) => handleDateSelection(date.toISOString())}
      disableWeekend={disableWeekend}
      selected={selectedDates}
    />
  );
}
