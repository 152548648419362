import compact from 'lodash/compact';

import { APP_HOST } from '../config';

export class AuthRoutes {
  static signIn() {
    return '/sign-in';
  }

  static signUp() {
    return '/sign-up';
  }

  static forgotPassword() {
    return '/forgot-password';
  }

  static resetPassword(options = { host: false }) {
    return compact<string>([
      options.host ? APP_HOST : null,
      '/reset-password'
    ]).join('');
  }

  static createProfile() {
    return '/create-profile';
  }

  static workEmail() {
    return '/work-email';
  }

  static workEmailWithSlug(slug: string) {
    return `/work-email/${slug}`;
  }

  static createProfileWithSlug(slug: string) {
    return `/create-profile/${slug}`;
  }

  static acceptInvitation() {
    return '/accept-invitation';
  }

  static continueAcceptInvitation(slug: string) {
    return `/accept-invitation/${slug}/form`;
  }

  static continueSubmissionReview(slug: string) {
    return `/review-submissions/${slug}/form`;
  }

  static securityCheck() {
    return '/security-check';
  }

  static getStarted() {
    return '/get-started';
  }

  static magicLink() {
    return '/magic-link';
  }

  static confirmEmail(email: string) {
    return `/confirm-email/${email}`;
  }

  static welcomeBack() {
    return `/welcome-back`;
  }
}
