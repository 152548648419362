import {
  JobCandidateSearchTypeLabel,
  JobCandidateSearchTypeLabels
} from '../../jobCandidatesTypes';

function renderJobCandidateSearchTypeLabel(
  jobCandidateSearchTypeLabel: JobCandidateSearchTypeLabel
) {
  switch (jobCandidateSearchTypeLabel) {
    case JobCandidateSearchTypeLabels.NONE:
      return 'None';
    case JobCandidateSearchTypeLabels.NEW:
      return 'New';
    case JobCandidateSearchTypeLabels.LEAD:
      return 'Lead';
    case JobCandidateSearchTypeLabels.PROSPECT:
      return 'Recruit';
    case JobCandidateSearchTypeLabels.APPLICANT:
      return 'Applicant';
    case JobCandidateSearchTypeLabels.CANDIDATE:
      return 'Candidate';
    default:
      return 'New';
  }
}

export default renderJobCandidateSearchTypeLabel;
