import { ButtonGroup, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { ScheduleHelperModal } from '../../../../../../../../../../../helpers/ScheduleHelper/modal/ScheduleHelperModal';
import { Text } from '../../../../../../../../../../../helpers/Text';
import { IconButton } from '../../../../../../../../../../../helpers/buttons/IconButton';
import { TextareaField } from '../../../../../../../../../../../helpers/forms/formFields/TextareaField';
import { CalendarIcon } from '../../../../../../../../../../../icons/CalendarIcon';
import { ClockIcon } from '../../../../../../../../../../../icons/ClockIcon';
import { MentionIcon } from '../../../../../../../../../../../icons/MentionIcon';
import { SmileyIcon } from '../../../../../../../../../../../icons/SmileyIcon';
import { JobCandidatesCache } from '../../../../../../../../../../jobCandidates/JobCandidatesCache';
import { useFetchJobCandidateByNanoId } from '../../../../../../../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import {
  FetchJobCandidateEnabled,
  JobCandidateNanoId
} from '../../../../../../../../../../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidateResponse,
  fetchJobCandidateQuery
} from '../../../../../../../../../../jobCandidates/queries/fetchJobCandidate.query';
import { useViewJobCandidateModalPhoneSidebarContext } from '../../../context';

type ViewJobCandidateModalPhoneSidebarTextAreaProps = {
  allowSmiley?: boolean;
  allowMention?: boolean;
  allowScheduling?: boolean;
  schedulingOpenDefault?: boolean;
};

const ViewJobCandidateModalPhoneSidebarTextArea = ({
  allowSmiley = true,
  allowMention = true,
  schedulingOpenDefault = false,
  allowScheduling
}: ViewJobCandidateModalPhoneSidebarTextAreaProps) => {
  const { job, jobCandidate: phoneCandidate } =
    useViewJobCandidateModalPhoneSidebarContext();

  const { jobCandidate } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: phoneCandidate?.nanoId as JobCandidateNanoId,
      enabled: !!phoneCandidate?.nanoId as FetchJobCandidateEnabled,
      cacheKey: JobCandidatesCache.showCacheKey(),
      query: fetchJobCandidateQuery
    });

  const {
    isOpen: isOpenPopup,
    onToggle: onTogglePopup,
    onClose: onClosePopup
  } = useDisclosure({ defaultIsOpen: schedulingOpenDefault });

  const [hasScheduling] = useState(false);

  return (
    <>
      <Stack
        p={3}
        bg="white"
        borderRadius="base"
        border="1px solid"
        borderColor="gray.300"
      >
        <TextareaField
          p={0}
          bg="white"
          resize="none"
          border="none"
          outline="none"
          _focusVisible={{
            border: 'none'
          }}
        />

        <ButtonGroup spacing={3} justifyContent="flex-end">
          {allowScheduling && (
            <IconButton
              p={0}
              minW={0}
              size="small"
              aria-label="time"
              onClick={onTogglePopup}
              icon={<ClockIcon />}
              hierarchy="link"
            />
          )}

          {allowMention && (
            <IconButton
              p={0}
              minW={0}
              size="small"
              aria-label="mention"
              icon={<MentionIcon />}
              hierarchy="link"
            />
          )}

          {allowSmiley && (
            <IconButton
              p={0}
              minW={0}
              size="small"
              aria-label="smiley"
              icon={<SmileyIcon />}
              hierarchy="link"
            />
          )}
        </ButtonGroup>

        {hasScheduling ? (
          <Stack spacing={2}>
            <Text textStyle="upperCase1Medium" color="gray.600">
              Availability
            </Text>

            <Flex gap={2}>
              <CalendarIcon w={5} h={5} />

              <Stack spacing={0} color="gray.500">
                <Text>Thursday, Mar 23 (10:00 - 11:00 PM)</Text>
                <Text>Saturday, Mar 25 (10:00 - 11:00 PM)</Text>
                <Text>Monday, Mar 27 (10:00 - 11:00 AM)</Text>
              </Stack>
            </Flex>
          </Stack>
        ) : null}
      </Stack>

      {job && jobCandidate && (
        <ScheduleHelperModal
          isOpen={isOpenPopup}
          onSubmit={console.log}
          onCancel={onClosePopup}
          jobNanoId={job.nanoId}
          jobCandidate={jobCandidate}
          title="Interview for Python Developer role"
        />
      )}
    </>
  );
};

export default ViewJobCandidateModalPhoneSidebarTextArea;
