import { JobNanoId } from '../../../../../jobs/jobsTypes';

import { CareerSiteRoutes } from '../../../../CareerSiteRoutes';

import { Button } from '../../../../../../helpers/Button';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';

interface ApplyNowButtonProps {
  jobNanoId: JobNanoId;
  size?: 'small' | 'medium' | 'large';
  width?: 'large' | 'full';
}

function ApplyNowButton({
  jobNanoId,
  width,
  size = 'small'
}: ApplyNowButtonProps) {
  return (
    <NextLinkHelper href={CareerSiteRoutes.job(jobNanoId)}>
      <Button
        as="a"
        size={size}
        w={width === 'large' ? 60 : width}
        _hover={{
          textDecoration: 'none',
          cursor: 'pointer',
          bg: 'primary.600'
        }}
      >
        Apply Now
      </Button>
    </NextLinkHelper>
  );
}

export default ApplyNowButton;
