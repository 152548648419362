import {
  JobResumeRecruiterStatus,
  JobResumeRecruiterStatuses
} from '../../jobResumeRecruitersTypes';

function renderJobResumeRecruiterStatusColor(status: JobResumeRecruiterStatus) {
  switch (status) {
    case JobResumeRecruiterStatuses.ACTIVELY_LOOKING:
      return { bg: '#E8FAF5', color: '#0F8E6A', border: '#BDF1E2' };
    case JobResumeRecruiterStatuses.ACTIVELY_INTERVIEWING:
      return {
        bg: '#EAF2FA',
        color: '#287CC9',
        border: 'rgba(40, 124, 201, 0.2)'
      };
    case JobResumeRecruiterStatuses.PASSIVELY_LOOKING:
      return { bg: '#EDF8FB', color: '#21667C', border: '#CDEAF4' };
    case JobResumeRecruiterStatuses.PASSIVELY_INTERVIEWING:
      return { bg: '#F2EBFB', color: '#7830D6', border: '#DAC6F4' };
    case JobResumeRecruiterStatuses.NOT_LOOKING:
      return {
        bg: '#FEEDEB',
        color: '#BA3724',
        border: 'rgba(241, 71, 47, 0.2)'
      };
    default:
      return { bg: '#EDF8FB', color: '#21667C', border: '#CDEAF4' };
  }
}

export default renderJobResumeRecruiterStatusColor;
