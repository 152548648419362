import { DepartmentNanoId } from '../departments/departmentsTypes';
import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../jobs/jobsTypes';
import {
  FetchCandidateSubmissionJobCandidatesCacheKey,
  FetchCandidateSubmissionJobCandidateCacheKey
} from './candidateSubmissionJobCandidatesTypes';

export class CandidateSubmissionJobCandidatesCache {
  static indexCacheKey() {
    return 'candidateSubmissionJobCandidates' as FetchCandidateSubmissionJobCandidatesCacheKey;
  }

  static showCacheKey() {
    return 'candidateSubmissionJobCandidate' as FetchCandidateSubmissionJobCandidateCacheKey;
  }

  static jobIndexCacheKey(jobNanoId: JobNanoId) {
    return `candidateSubmissionJobCandidates-job-${jobNanoId}` as FetchCandidateSubmissionJobCandidatesCacheKey;
  }

  static jobCandidateIndexCacheKey(jobCandidateNanoId: JobCandidateNanoId) {
    return `candidateSubmissionJobCandidates-jobCandidateNanoId-${jobCandidateNanoId}` as FetchCandidateSubmissionJobCandidatesCacheKey;
  }

  static departmentIndexCacheKey(departmentNanoId: DepartmentNanoId) {
    return `candidateSubmissionJobCandidates-department-${departmentNanoId}` as FetchCandidateSubmissionJobCandidatesCacheKey;
  }
}
