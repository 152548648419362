import { useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import debounce from 'lodash/debounce';
import take from 'lodash/take';

import { ErrorMessage } from '../../../../../types';

import {
  FetchEmploymentsFilters,
  FetchEmploymentsPageSize,
  FetchEmploymentsSort,
  EmploymentFields,
  EmploymentId,
  EmploymentName,
  MayBeEmploymentCompany,
  EmploymentJobTitle
} from '../../../../employments/employmentsTypes';

import { JobResumeRecruiterId } from '../../../../jobResumeRecruiters/jobResumeRecruitersTypes';

import {
  fetchEmploymentsQuery,
  FetchEmploymentsResponse
} from '../../../../employments/queries/fetchEmployments.query';

import { usePaginatedEmployments } from '../../../../employments/hooks/usePaginatedEmployments';

import { EmploymentsCache } from '../../../../employments/EmploymentsCache';

import {
  MultiSelectField,
  MultiSelectFieldReactHookFormControl,
  MultiSelectFieldReactHookFormFieldPath
} from '../../../../../helpers/forms/formFields/MultiSelectField';

interface SelectEmploymentsFormFieldItem {
  employments:
    | {
        id: EmploymentId;
        name: EmploymentName;
        company: MayBeEmploymentCompany;
        jobTitle: EmploymentJobTitle;
      }[]
    | null;
}

interface SelectEmploymentsFormFieldProps<T extends FieldValues> {
  control: MultiSelectFieldReactHookFormControl<T>;
  placeholder?: string;
  jobResumeRecruiterId?: JobResumeRecruiterId;
  item?: SelectEmploymentsFormFieldItem;
  errorMessage: ErrorMessage;
  withSelectAllButton?: boolean;
  maxNumberOfEmployments?: number;
}

function SelectEmploymentsFormField<T extends FieldValues>({
  control,
  placeholder = 'Employments',
  jobResumeRecruiterId,
  item,
  errorMessage,
  withSelectAllButton,
  maxNumberOfEmployments
}: SelectEmploymentsFormFieldProps<T>) {
  const {
    employmentsIsLoading,
    employmentsErrorMessage,
    changeEmploymentsFilters
  } = usePaginatedEmployments<FetchEmploymentsResponse>({
    query: fetchEmploymentsQuery,
    cacheKey: EmploymentsCache.indexCacheKey(),
    initialPageSize: 100 as FetchEmploymentsPageSize,
    initialFilters: {
      ...(jobResumeRecruiterId
        ? {
            [EmploymentFields.JOB_RESUME_RECRUITER_ID]: {
              operator: 'eq',
              value: jobResumeRecruiterId
            }
          }
        : {})
    } as unknown as FetchEmploymentsFilters,
    initialSort: {
      [EmploymentFields.START_DATE]: {
        ascending: false
      }
    } as unknown as FetchEmploymentsSort
  });

  const {
    employmentsIsLoading: allEmploymentsIsLoading,
    employmentsErrorMessage: allEmploymentsErrorMessage,
    fetchEmployments
  } = usePaginatedEmployments<FetchEmploymentsResponse>({
    query: fetchEmploymentsQuery,
    cacheKey: EmploymentsCache.allExistingCacheKey(),
    initialPageSize: 100 as FetchEmploymentsPageSize,
    initialFilters: {
      ...(jobResumeRecruiterId
        ? {
            [EmploymentFields.JOB_RESUME_RECRUITER_ID]: {
              operator: 'eq',
              value: jobResumeRecruiterId
            }
          }
        : {})
    } as unknown as FetchEmploymentsFilters,
    initialSort: {
      [EmploymentFields.START_DATE]: {
        ascending: false
      }
    } as unknown as FetchEmploymentsSort
  });

  const defaultCurrentEmployment = item?.employments
    ? item.employments.map(({ name, jobTitle, company, id }) => ({
        value: id,
        label: [company?.name, jobTitle?.name || name].join(' - ')
      }))
    : undefined;

  const debouncedFilterEmployments = debounce<(updatedValue: string) => void>(
    (updatedValue) =>
      changeEmploymentsFilters({
        [EmploymentFields.NAME]: {
          operator: 'ilike',
          value: `${updatedValue}%`
        }
      })
  );

  const onSelectAllJobs = useCallback(async () => {
    const { data: allEmployments } = await fetchEmployments({
      nextFilters: {
        [EmploymentFields.NAME]: {
          operator: 'ilike',
          value: ''
        }
      }
    });

    const allSelectedEmployments = maxNumberOfEmployments
      ? take(allEmployments, maxNumberOfEmployments)
      : allEmployments;

    const allJobsValue = allSelectedEmployments.map((employment) => ({
      value: employment.id,
      label: [
        employment.company?.name,
        employment.jobTitle?.name || employment.name
      ].join(' - ')
    }));

    return allJobsValue;
  }, [fetchEmployments, maxNumberOfEmployments]);

  return (
    <MultiSelectField
      placeholder={placeholder}
      // options={employments.map((employment) => ({
      options={(item?.employments ?? []).map((employment) => ({
        value: employment.id,
        label: [
          employment.company?.name,
          employment.jobTitle?.name || employment.name
        ].join(' - ')
      }))}
      control={control}
      name={'employmentIds' as MultiSelectFieldReactHookFormFieldPath<T>}
      isLoading={employmentsIsLoading || allEmploymentsIsLoading}
      defaultValue={defaultCurrentEmployment}
      errorMessage={
        errorMessage || employmentsErrorMessage || allEmploymentsErrorMessage
      }
      onInputChange={debouncedFilterEmployments}
      addNewButtonToValueAction={onSelectAllJobs}
      addNewButtonLabel="Select all jobs"
      addNewButtonHierarchy="primary"
      maxValues={maxNumberOfEmployments}
      addNewButtonWithoutPlusIcon
      withAddNewButton={withSelectAllButton}
    />
  );
}

export default SelectEmploymentsFormField;
