import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  StackDivider,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react';
import { useShareCandidatesProfileContext } from '../../../../../../context';
import { Button } from '../../../../../../helpers/Button';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { Heading } from '../../../../../../helpers/Heading';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { ChevronLeftIcon } from '../../../../../../icons/ChevronLeftIcon';
import { EmailIcon } from '../../../../../../icons/EmailIcon';
import { FolderIcon } from '../../../../../../icons/FolderIcon';
import { LinkedInIcon } from '../../../../../../icons/LinkedInIcon';
import { MessageIcon } from '../../../../../../icons/MessageIcon';
import { PhoneIcon } from '../../../../../../icons/PhoneIcon';
import { ShareIcon } from '../../../../../../icons/ShareIcon';
import { ViewJobCandidateModalPhoneSidebarHelper } from '../../../../components/modals/ViewJobCandidateModal/helpers/ViewJobCandidateModalPhoneSidebarHelper';
import { JobId, JobJobTitle, JobName, JobNanoId } from '../../../../jobsTypes';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import {
  JobCandidateDetailsJobCandidate,
  JobCandidateDetailsPageHandleGoBack
} from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsAddComment } from '../ViewJobCandidateDetailsAddComment';
import { ViewJobCandidateDetailsBio } from '../ViewJobCandidateDetailsBio';
import { ViewJobCandidateDetailsComposeEmail } from '../ViewJobCandidateDetailsComposeEmail';
import { ViewJobCandidateDetailsHeaderMenuAddJob } from '../ViewJobCandidateDetailsHeaderMenuAddJob';
import { ViewJobCandidateDetailsJobDetailsJob } from '../ViewJobCandidateDetailsJobDetails';
import startCase from 'lodash/startCase';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { ButtonWithRef } from '../../../../../../helpers/ButtonWithRef';

interface ViewJobCandidateDetailsHeaderMenuProps {
  jobCandidate: JobCandidateDetailsJobCandidate;
  hasScrolled: boolean;
  requiresCredit: boolean;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
  };
  setSelectedJobs?: (jobs: ViewJobCandidateDetailsJobDetailsJob) => void;
  handleGoBack: JobCandidateDetailsPageHandleGoBack;
  isHiringPortalPath: boolean;
}

export default function ViewJobCandidateDetailsHeaderMenu({
  job,
  hasScrolled,
  jobCandidate,
  requiresCredit,
  setSelectedJobs,
  handleGoBack,
  isHiringPortalPath
}: ViewJobCandidateDetailsHeaderMenuProps) {
  const { shareJobCandidate } = useShareCandidatesProfileContext();

  const { isCandidateSubmissionOpen } =
    useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen =
    isCandidateSubmissionOpen || isSendEmailAssessmentOpen;

  const {
    isOpen: isOpenPhonrCallActivity,
    onOpen: onOpenPhonrCallActivity,
    onToggle: onTogglePhonrCallActivity
  } = useDisclosure();

  return (
    <>
      <Flex
        h={14}
        top={0}
        gap={6}
        zIndex={99}
        position="sticky"
        overflow="hidden"
        bg={isRightSidebarOpen ? 'gray.50' : 'white'}
        pl={!isRightSidebarOpen ? 4 : { base: 4, xl: 12 }}
        pr={isRightSidebarOpen ? 4 : isHiringPortalPath ? 12 : 4}
      >
        {isRightSidebarOpen ? (
          <Flex alignItems="center">
            <Heading level="h3">
              Candidate {isCandidateSubmissionOpen && 'Submission'}{' '}
              {isSendEmailAssessmentOpen && 'Assessment'}
            </Heading>
          </Flex>
        ) : (
          <>
            <Flex
              h={28}
              top={0}
              flexDir="column"
              position="relative"
              transitionDuration="slow"
              transform={hasScrolled ? 'translate(0, -50%)' : undefined}
            >
              <Flex flex={1} h={14} alignItems="center">
                <NextLinkHelper href={handleGoBack().url} passHref>
                  <ButtonWithRef
                    as="a"
                    h={8}
                    opacity={hasScrolled ? 0 : 1}
                    size="medium"
                    display="flex"
                    color="gray.700"
                    fontWeight="normal"
                    hierarchy="unstyled"
                    leftIcon={<ChevronLeftIcon w={5} h={5} />}
                    onClick={() => handleGoBack().url}
                  >
                    Back to {startCase(handleGoBack().label)}
                  </ButtonWithRef>
                </NextLinkHelper>
              </Flex>

              <Flex
                h={14}
                gap={2}
                alignItems="center"
                top={hasScrolled ? 0 : '100%'}
                transitionDuration="slow"
                zIndex={999}
              >
                <ViewJobCandidateDetailsBio
                  requiresCredit={requiresCredit}
                  jobCandidate={jobCandidate}
                  isCollapsed
                  job={job}
                  isHiringPortalPath={isHiringPortalPath}
                />

                <Flex
                  w={6}
                  h={6}
                  bg="#069"
                  alignItems="center"
                  borderRadius="full"
                  justifyContent="center"
                >
                  <LinkedInIcon borderRadius="full" w={3.5} h={3.5} />
                </Flex>
              </Flex>
            </Flex>

            <Flex gap={4} flex={1} alignItems="center">
              <HStack
                ml="auto"
                spacing={4}
                flexWrap="wrap"
                alignItems="center"
                divider={<StackDivider />}
              >
                <ViewJobCandidateDetailsHeaderMenuAddJob
                  jobCandidate={jobCandidate}
                  setSelectedJobs={setSelectedJobs}
                />

                <Button
                  h={8}
                  size="medium"
                  display="flex"
                  color="gray.600"
                  hierarchy="unstyled"
                  leftIcon={<ShareIcon w={5} h={5} />}
                  onClick={() => shareJobCandidate(jobCandidate)}
                >
                  Share
                </Button>

                <Button
                  h={8}
                  size="medium"
                  display="flex"
                  color="gray.600"
                  hierarchy="unstyled"
                  leftIcon={<FolderIcon w={5} h={5} />}
                >
                  Move
                </Button>
              </HStack>
            </Flex>

            <Flex gap={3} alignItems="center">
              <ViewJobCandidateDetailsComposeEmail
                trigger={
                  <Tooltip label="Email" textTransform="capitalize" hasArrow>
                    <IconButton
                      h={6}
                      w={6}
                      minW={0}
                      minH={0}
                      value="email"
                      display="flex"
                      aria-label=""
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                      icon={<EmailIcon w={3.5} h={3.5} />}
                      hierarchy="tertiary"
                    />
                  </Tooltip>
                }
              />

              <ViewJobCandidateDetailsAddComment
                trigger={
                  <Tooltip label="Comment" textTransform="capitalize" hasArrow>
                    <IconButton
                      h={6}
                      w={6}
                      minW={0}
                      minH={0}
                      value="comment"
                      display="flex"
                      aria-label=""
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                      icon={<MessageIcon w={3.5} h={3.5} />}
                      hierarchy="tertiary"
                    />
                  </Tooltip>
                }
              />

              <HStack
                spacing={0}
                overflow="hidden"
                borderRadius="base"
                divider={<StackDivider borderColor="primary.100" />}
              >
                <Button
                  h={7}
                  px={4}
                  size="extra-small"
                  borderRadius="none"
                  onClick={onOpenPhonrCallActivity}
                  leftIcon={<PhoneIcon w={3} h={3} fill="white" />}
                >
                  Call
                </Button>

                <IconButton
                  h={7}
                  display="flex"
                  aria-label=""
                  size="extra-small"
                  alignItems="center"
                  borderRadius="none"
                  justifyContent="center"
                  icon={<ChevronDownIcon w={3.5} h={3.5} fill="white" />}
                />
              </HStack>
            </Flex>
          </>
        )}
      </Flex>

      <Drawer
        size="md"
        isOpen={isOpenPhonrCallActivity}
        onClose={onTogglePhonrCallActivity}
      >
        <DrawerOverlay />
        <DrawerContent p={0}>
          <DrawerBody p={0}>
            <ViewJobCandidateModalPhoneSidebarHelper
              fullWidth
              withoutBorder
              jobCandidate={jobCandidate}
              toggleFollowUpEmail={console.log}
              isSidebarOpen={isOpenPhonrCallActivity}
              sidebarToggle={onTogglePhonrCallActivity}
              personalPhoneNumber={jobCandidate.phone}
              companyPhoneNumber={'89303893023'}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
