import snakeCase from 'lodash/snakeCase';

import {
  FetchJobScorecardsQuery,
  JobScorecardFields,
  JobScorecardCreatedAt,
  JobScorecardId,
  JobScorecardName,
  JobScorecardUpdatedAt,
  JobScorecardJobId,
  JobScorecardJob,
  JobScorecardUserId,
  JobScorecardUser,
  JobScorecardComments,
  JobScorecardPositionFit,
  JobScorecardJobScorecardQuestions,
  JobScorecardOverallEvaluation,
  JobScorecardNextStep,
  JobScorecardJobFunctionId,
  JobScorecardJobFunction,
  JobScorecardScorecardType
} from '../jobScorecardsTypes';

export interface FetchJobScorecardsResponse {
  [JobScorecardFields.ID]: JobScorecardId;
  [JobScorecardFields.NAME]: JobScorecardName;
  [JobScorecardFields.POSITION_FIT]: JobScorecardPositionFit;
  [JobScorecardFields.COMMENTS]: JobScorecardComments;
  [JobScorecardFields.NEXT_STEPS]: JobScorecardNextStep;
  [JobScorecardFields.JOB_FUNCTION]: JobScorecardJobFunction;
  [JobScorecardFields.JOB_FUNCTION_ID]: JobScorecardJobFunctionId;
  [JobScorecardFields.SCORECARD_TYPE]: JobScorecardScorecardType;
  [JobScorecardFields.USER]: JobScorecardUser;
  [JobScorecardFields.USER_ID]: JobScorecardUserId;
  [JobScorecardFields.JOB]: JobScorecardJob;
  [JobScorecardFields.JOB_ID]: JobScorecardJobId;
  [JobScorecardFields.JOB_SCORECARD_QUESTIONS]: JobScorecardJobScorecardQuestions;
  [JobScorecardFields.OVERALL_EVALUATION]: JobScorecardOverallEvaluation;
  [JobScorecardFields.CREATED_AT]: JobScorecardCreatedAt;
  [JobScorecardFields.UPDATED_AT]: JobScorecardUpdatedAt;
}

export const fetchJobScorecardsColumns = [
  JobScorecardFields.ID,
  JobScorecardFields.NAME,
  JobScorecardFields.POSITION_FIT,
  JobScorecardFields.COMMENTS,
  JobScorecardFields.JOB_ID,
  JobScorecardFields.USER_ID,
  JobScorecardFields.CREATED_AT
];

const fetchJobScorecardsSBColumns = fetchJobScorecardsColumns.map(
  (fetchJobScorecardsColumn) => snakeCase(fetchJobScorecardsColumn)
);

export const fetchJobScorecardsQuery = fetchJobScorecardsSBColumns.join(
  ','
) as FetchJobScorecardsQuery;
