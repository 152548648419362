import {
  Flex,
  HStack,
  Stack,
  StackDivider,
  useDisclosure
} from '@chakra-ui/react';
import { useState } from 'react';
import { JobNanoId } from '../../main/jobs/jobsTypes';
import { DateUtils } from '../../utils/DateUtils';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { NextImageHelper } from '../NextImageHelper';
import { Text } from '../Text';
import { ScheduleHelperCandidate } from './components/ScheduleHelperCandidate';
import { ScheduleHelperDateAndTimeSelection } from './components/ScheduleHelperDateAndTimeSelection';
import { ScheduleHelperGuests } from './components/ScheduleHelperGuests';
import { ScheduleHelperSelectedTimeSlots } from './components/ScheduleHelperSelectedTimeSlots';
import { ScheduleHelperJobCandidate } from './ScheduleHelper.types';

interface ScheduleHelperProps {
  title?: string;
  minSlots?: number;
  maxSlots?: number;
  minDate?: Date | null;
  maxDate?: Date | null;
  jobNanoId?: JobNanoId;
  onCancel?: () => void;
  onSubmit?: () => void;
  selectDateLabel?: string;
  jobCandidate?: ScheduleHelperJobCandidate;
}

export default function ScheduleHelper({
  title,
  minDate,
  maxDate,
  onCancel,
  onSubmit,
  minSlots = 3,
  maxSlots = 3,
  jobCandidate,
  selectDateLabel
}: ScheduleHelperProps) {
  const defaultSelectedDate = minDate
    ? DateUtils.toISO(DateUtils.toInputFromJSDate(minDate))
    : DateUtils.daysFromTodayToISO(1);

  const [selectedDate, setSelectedDate] = useState(
    defaultSelectedDate as string
  );

  const [selectedTimeSlots, setSelectedTimeSlots] = useState<string[]>([]);

  const duration = { hour: 1, minute: 0 };

  const handleDateSelection = (dateSlot: string) => {
    setSelectedDate(dateSlot);
  };

  const handleTimeSelection = (timeSlot: string) => {
    setSelectedTimeSlots((prevSlots) => {
      if (prevSlots.includes(timeSlot)) {
        return prevSlots.filter((slot) => slot !== timeSlot);
      } else {
        return [...prevSlots, timeSlot];
      }
    });
  };

  const { isOpen: isOpenSendAvailability, onClose: onCloseSendAvailability } =
    useDisclosure();

  return (
    <Flex bg="white">
      <Stack
        w="auto"
        mx="auto"
        p={4}
        spacing={3}
        bg="white"
        divider={<StackDivider />}
      >
        {jobCandidate && (
          <ScheduleHelperCandidate jobCandidate={jobCandidate} />
        )}

        <HStack
          pb={5}
          w="fit-content"
          spacing={8}
          alignItems="stretch"
          divider={<StackDivider />}
        >
          <Flex w="xl" minH="sm" flexDir="column">
            {!isOpenSendAvailability ? (
              <ScheduleHelperDateAndTimeSelection
                minDate={minDate}
                maxDate={maxDate}
                minSlots={minSlots}
                maxSlots={maxSlots}
                duration={duration}
                selectedDate={selectedDate}
                selectDateLabel={selectDateLabel}
                handleDateSelection={handleDateSelection}
                handleTimeSelection={handleTimeSelection}
                selectedTimeSlots={selectedTimeSlots}
                disableWeekend={true}
              />
            ) : (
              <Flex
                flex={1}
                gap={4}
                flexDir="column"
                alignItems="center"
                justifyContent="center"
              >
                <NextImageHelper
                  width={128}
                  height={128}
                  alt="Careers"
                  layout="fixed"
                  src="/images/availability-success.svg"
                />

                <Text color="gray.600" textStyle="body1Regular">
                  You have successfully send the interview slots
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex w="xs" gap={6} flexDir="column" flex={1}>
            {title && (
              <Heading level="h4" color="gray.800">
                {title}
              </Heading>
            )}
            <ScheduleHelperGuests />

            <ScheduleHelperSelectedTimeSlots
              selectedSlots={selectedTimeSlots}
              duration={duration}
            />

            <Flex gap={4} mt="auto">
              {!!onCancel && (
                <Button
                  fontSize="sm"
                  flex={1}
                  hierarchy="secondary"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              )}

              {isOpenSendAvailability ? (
                <Button
                  flex={1}
                  fontSize="sm"
                  onClick={onCloseSendAvailability}
                >
                  Edit availability
                </Button>
              ) : (
                <Button
                  flex={1}
                  fontSize="sm"
                  onClick={onSubmit}
                  isDisabled={selectedTimeSlots.length < minSlots}
                >
                  Save availability
                </Button>
              )}
            </Flex>
          </Flex>
        </HStack>
      </Stack>
    </Flex>
  );
}
