import snakeCase from 'lodash/snakeCase';

import {
  FetchJobResumeRecruiterQuery,
  JobResumeRecruiterCreatedAt,
  JobResumeRecruiterFields,
  JobResumeRecruiterId,
  JobResumeRecruiterNanoId,
  JobResumeRecruiterName,
  JobResumeRecruiterPhone,
  JobResumeRecruiterEmail,
  JobResumeRecruiterLinkedinUrl,
  JobResumeRecruiterTitle,
  JobResumeRecruiterDescription,
  JobResumeRecruiterSearchableAt,
  MayBeJobResumeRecruiterMinimumDesiredPay,
  MayBeJobResumeRecruiterMaximumDesiredPay,
  JobResumeRecruiterStatus,
  JobResumeRecruiterProfessionalExperience,
  JobResumeRecruiterEmploymentTypes,
  JobResumeRecruiterWorkplaceTypes,
  JobResumeRecruiterPaidTimeOff,
  JobResumeRecruiterPrimaryJobFunction,
  JobResumeRecruiterWorkAuthorization,
  JobResumeRecruiterFutureRequirements,
  MayBeJobResumeRecruiterImageId,
  MayBeJobResumeRecruiterImage,
  MayBeJobResumeRecruiterStateId,
  MayBeJobResumeRecruiterState,
  MayBeJobResumeRecruiterCityId,
  MayBeJobResumeRecruiterCity,
  MayBeJobResumeRecruiterCompanyId,
  MayBeJobResumeRecruiterCompany,
  JobResumeRecruiterJobResumeId,
  JobResumeRecruiterJobResume,
  JobResumeRecruiterSpecializationIds,
  JobResumeRecruiterSpecializations,
  JobResumeRecruiterIndustryIds,
  JobResumeRecruiterIndustries,
  JobResumeRecruiterSkillIds,
  JobResumeRecruiterUsersCount,
  JobResumeRecruiterJobResumeRecruitersSkills,
  JobResumeRecruiterCityIds,
  JobResumeRecruiterCities,
  JobResumeRecruiterActivelyInterviewing,
  JobResumeRecruiterCounterOffer,
  JobResumeRecruiterEmployerCounterOffer,
  JobResumeRecruiterEmploymentStatus,
  JobResumeRecruiterNewJobStartAvailability,
  JobResumeRecruiterPhoneCallAvailability
} from '../jobResumeRecruitersTypes';

export interface FetchJobResumeRecruiterResponse {
  [JobResumeRecruiterFields.ID]: JobResumeRecruiterId;
  [JobResumeRecruiterFields.NANO_ID]: JobResumeRecruiterNanoId;
  [JobResumeRecruiterFields.NAME]: JobResumeRecruiterName;
  [JobResumeRecruiterFields.PHONE]: JobResumeRecruiterPhone;
  [JobResumeRecruiterFields.EMAIL]: JobResumeRecruiterEmail;
  [JobResumeRecruiterFields.LINKEDIN_URL]: JobResumeRecruiterLinkedinUrl;
  [JobResumeRecruiterFields.TITLE]: JobResumeRecruiterTitle;
  [JobResumeRecruiterFields.DESCRIPTION]: JobResumeRecruiterDescription;
  [JobResumeRecruiterFields.SEARCHABLE_AT]: JobResumeRecruiterSearchableAt;
  [JobResumeRecruiterFields.MINIMUM_DESIRED_PAY]: MayBeJobResumeRecruiterMinimumDesiredPay;
  [JobResumeRecruiterFields.MAXIMUM_DESIRED_PAY]: MayBeJobResumeRecruiterMaximumDesiredPay;
  [JobResumeRecruiterFields.STATUS]: JobResumeRecruiterStatus;
  [JobResumeRecruiterFields.PROFESSIONAL_EXPERIENCE]: JobResumeRecruiterProfessionalExperience;
  [JobResumeRecruiterFields.EMPLOYMENT_TYPES]: JobResumeRecruiterEmploymentTypes;
  [JobResumeRecruiterFields.WORKPLACE_TYPES]: JobResumeRecruiterWorkplaceTypes;
  [JobResumeRecruiterFields.PAID_TIME_OFF]: JobResumeRecruiterPaidTimeOff;
  [JobResumeRecruiterFields.PRIMARY_JOB_FUNCTION]: JobResumeRecruiterPrimaryJobFunction;
  [JobResumeRecruiterFields.WORK_AUTHORIZATION]: JobResumeRecruiterWorkAuthorization;
  [JobResumeRecruiterFields.FUTURE_REQUIREMENTS]: JobResumeRecruiterFutureRequirements;
  [JobResumeRecruiterFields.IMAGE_ID]: MayBeJobResumeRecruiterImageId;
  [JobResumeRecruiterFields.IMAGE]: MayBeJobResumeRecruiterImage;
  [JobResumeRecruiterFields.STATE_ID]: MayBeJobResumeRecruiterStateId;
  [JobResumeRecruiterFields.STATE]: MayBeJobResumeRecruiterState;
  [JobResumeRecruiterFields.CITY_ID]: MayBeJobResumeRecruiterCityId;
  [JobResumeRecruiterFields.CITY]: MayBeJobResumeRecruiterCity;
  [JobResumeRecruiterFields.CITY_IDS]: JobResumeRecruiterCityIds;
  [JobResumeRecruiterFields.CITIES]: JobResumeRecruiterCities;
  [JobResumeRecruiterFields.COMPANY_ID]: MayBeJobResumeRecruiterCompanyId;
  [JobResumeRecruiterFields.COMPANY]: MayBeJobResumeRecruiterCompany;
  [JobResumeRecruiterFields.JOB_RESUME_ID]: JobResumeRecruiterJobResumeId;
  [JobResumeRecruiterFields.JOB_RESUME]: JobResumeRecruiterJobResume;
  [JobResumeRecruiterFields.SPECIALIZATION_IDS]: JobResumeRecruiterSpecializationIds;
  [JobResumeRecruiterFields.SPECIALIZATIONS]: JobResumeRecruiterSpecializations;
  [JobResumeRecruiterFields.INDUSTRY_IDS]: JobResumeRecruiterIndustryIds;
  [JobResumeRecruiterFields.INDUSTRIES]: JobResumeRecruiterIndustries;
  [JobResumeRecruiterFields.SKILL_IDS]: JobResumeRecruiterSkillIds;
  [JobResumeRecruiterFields.JOB_RESUME_RECRUITERS_SKILLS]: JobResumeRecruiterJobResumeRecruitersSkills;
  [JobResumeRecruiterFields.USERS_COUNT]: JobResumeRecruiterUsersCount;
  [JobResumeRecruiterFields.CREATED_AT]: JobResumeRecruiterCreatedAt;
  [JobResumeRecruiterFields.EMPLOYMENT_STATUS]?: JobResumeRecruiterEmploymentStatus;
  [JobResumeRecruiterFields.PHONE_CALL_AVAILABILITY]?: JobResumeRecruiterPhoneCallAvailability;
  [JobResumeRecruiterFields.EMPLOYER_COUNTER_OFFER]?: JobResumeRecruiterEmployerCounterOffer;
  [JobResumeRecruiterFields.NEW_JOB_START_AVAILABILITY]?: JobResumeRecruiterNewJobStartAvailability;
  [JobResumeRecruiterFields.ACTIVELY_INTERVIEWING]?: JobResumeRecruiterActivelyInterviewing;
  [JobResumeRecruiterFields.COUNTER_OFFER]?: JobResumeRecruiterCounterOffer;
}

export const fetchJobResumeRecruiterColumns = [
  JobResumeRecruiterFields.ID,
  JobResumeRecruiterFields.NANO_ID,
  JobResumeRecruiterFields.NAME,
  JobResumeRecruiterFields.PHONE,
  JobResumeRecruiterFields.EMAIL,
  JobResumeRecruiterFields.LINKEDIN_URL,
  JobResumeRecruiterFields.TITLE,
  JobResumeRecruiterFields.DESCRIPTION,
  JobResumeRecruiterFields.SEARCHABLE_AT,
  JobResumeRecruiterFields.MINIMUM_DESIRED_PAY,
  JobResumeRecruiterFields.MAXIMUM_DESIRED_PAY,
  JobResumeRecruiterFields.STATUS,
  JobResumeRecruiterFields.PROFESSIONAL_EXPERIENCE,
  JobResumeRecruiterFields.EMPLOYMENT_TYPES,
  JobResumeRecruiterFields.WORKPLACE_TYPES,
  JobResumeRecruiterFields.PAID_TIME_OFF,
  JobResumeRecruiterFields.PRIMARY_JOB_FUNCTION,
  JobResumeRecruiterFields.WORK_AUTHORIZATION,
  JobResumeRecruiterFields.FUTURE_REQUIREMENTS,
  JobResumeRecruiterFields.IMAGE_ID,
  JobResumeRecruiterFields.STATE_ID,
  JobResumeRecruiterFields.CITY_ID,
  JobResumeRecruiterFields.CITY_IDS,
  JobResumeRecruiterFields.COMPANY_ID,
  JobResumeRecruiterFields.JOB_RESUME_ID,
  JobResumeRecruiterFields.JOB_RESUME,
  JobResumeRecruiterFields.INDUSTRY_IDS,
  JobResumeRecruiterFields.SPECIALIZATION_IDS,
  JobResumeRecruiterFields.SKILL_IDS,
  JobResumeRecruiterFields.JOB_RESUME_RECRUITERS_SKILLS,
  JobResumeRecruiterFields.USERS_COUNT,
  JobResumeRecruiterFields.CREATED_AT,

  JobResumeRecruiterFields.EMPLOYMENT_STATUS,
  JobResumeRecruiterFields.PHONE_CALL_AVAILABILITY,
  JobResumeRecruiterFields.EMPLOYER_COUNTER_OFFER,
  JobResumeRecruiterFields.NEW_JOB_START_AVAILABILITY,
  JobResumeRecruiterFields.ACTIVELY_INTERVIEWING,
  JobResumeRecruiterFields.COUNTER_OFFER
];

const fetchJobResumeRecruiterSBColumns = fetchJobResumeRecruiterColumns.map(
  (fetchJobResumeRecruiterColumn) => snakeCase(fetchJobResumeRecruiterColumn)
);

export const fetchJobResumeRecruiterQuery = `
  ${fetchJobResumeRecruiterSBColumns.join(',')},
  city_ids:cities!inner(id:city_id)
` as FetchJobResumeRecruiterQuery;
