import { Flex, Stack, useDisclosure } from '@chakra-ui/react';
import { Button } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { JobNanoId } from '../../../../../../../jobs/jobsTypes';
import { CareerSiteApplyProfilePageJobResumeRecruiter } from '../../../../components/CareerSiteApplyProfilePage/CareerSiteApplyProfilePage.types';
import { CareerSiteApplyProfilePageOverviewMyNxmoov } from '../CareerSiteApplyProfilePageOverviewMyNxmoov';
import { CareerSiteApplyProfilePageOverviewPreferences } from '../CareerSiteApplyProfilePageOverviewPreferences';
import { CareerSiteApplyProfilePageOverviewQualifications } from '../CareerSiteApplyProfilePageOverviewQualifications';
import { ViewJobCandidateDetailsProfileOverviewCalendar } from '../../../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsProfileOverview/components/ViewJobCandidateDetailsProfileOverviewCalendar';

interface CareerSiteApplyProfilePageOverviewProps {
  isOpen: boolean;
  onToggle: () => void;
  jobNanoId: JobNanoId;
  jobResumeRecruiter: CareerSiteApplyProfilePageJobResumeRecruiter;
}

export default function CareerSiteApplyProfilePageOverview({
  isOpen,
  onToggle,
  jobResumeRecruiter
}: CareerSiteApplyProfilePageOverviewProps) {
  const {
    isOpen: isHovered,
    onOpen: onMouseHover,
    onClose: onMouseLeave
  } = useDisclosure();

  return (
    <Stack
      top={16}
      spacing={0}
      overflow="auto"
      position={{ base: 'relative', md: 'sticky' }}
      overflowX="hidden"
      transitionDuration="slow"
      height="calc(100vh - 240px)"
      overflowY={isOpen && isHovered ? 'auto' : 'hidden'}
      onMouseOver={isOpen ? onMouseHover : undefined}
      onMouseLeave={isOpen ? onMouseLeave : undefined}
      minW={isOpen ? 352 : 6}
      w={isOpen ? 352 : 6}
      flexShrink={0}
    >
      <Stack pos="relative" spacing={0}>
        <Flex
          w="full"
          overflow="hidden"
          h={isHovered || !isOpen ? 10 : 0}
          transitionDuration="slow"
          alignItems="flex-start"
        >
          <Button
            p={0}
            minW={0}
            left={0}
            zIndex={1}
            display="flex"
            hierarchy="link"
            onClick={onToggle}
            _hover={{
              textDecoration: 'none'
            }}
          >
            <Flex
              w={6}
              h={6}
              transitionDuration="slow"
              bg={isOpen ? 'primary.500' : 'gray.200'}
              borderRadius="sm"
              p="1px"
              _before={{
                w: 2.5,
                bg: 'white',
                content: "''",
                position: 'relative',
                borderRadius: 'sm',
                left: isOpen ? 3 : 0
              }}
            />

            <Text
              pl={3}
              color="gray.700"
              textStyle="body1Regular"
              opacity={isOpen ? 1 : 0}
              display={isOpen ? 'block' : 'none'}
              visibility={isOpen ? 'visible' : 'hidden'}
            >
              Profile Overview
            </Text>
          </Button>
        </Flex>

        <Stack
          w="340px"
          spacing={4}
          position="relative"
          left={isOpen ? 0 : '-340px'}
          transitionDuration="slow"
        >
          <ViewJobCandidateDetailsProfileOverviewCalendar />

          <CareerSiteApplyProfilePageOverviewPreferences
            jobResumeRecruiter={jobResumeRecruiter}
          />

          <CareerSiteApplyProfilePageOverviewQualifications
            workAuthorization={jobResumeRecruiter.workAuthorization}
            jobResumeRecruiterId={jobResumeRecruiter.id}
          />

          <CareerSiteApplyProfilePageOverviewMyNxmoov
            jobResumeRecruiter={jobResumeRecruiter}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
