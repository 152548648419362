import React, { ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/layout';
import { Avatar } from '@chakra-ui/avatar';

import { Text } from '../../../../../Text';

import { CheckIcon } from '../../../../../../icons/CheckIcon';
import { ShareIcon } from '../../../../../../icons/ShareIcon';

interface MultiSelectFieldFormatOptionLabelProps {
  image?: string | ReactNode;
  withCheckIcon?: boolean;
  withShareIcon?: boolean;
  withImages?: boolean;
  label: string;
}

function MultiSelectFieldFormatOptionLabel({
  image,
  label,
  withCheckIcon,
  withImages,
  withShareIcon
}: MultiSelectFieldFormatOptionLabelProps) {
  const img1 =
    withImages && typeof image === 'string' ? (
      <Avatar size="2xs" src={image} />
    ) : null;

  const img2 = withImages && typeof image !== 'string' ? image : null;

  return (
    <Flex gap={3} direction="row" zIndex={1} w="100%" alignItems="center">
      {img1 || img2}
      <Text noOfLines={1}>{label}</Text>
      {withCheckIcon ? <CheckIcon fill="green.500" /> : null}
      {withShareIcon ? (
        <Box
          w={4}
          h={4}
          p={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderColor="green.500"
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="full"
          bg="green.100"
        >
          <ShareIcon w={2.5} h={2.5} fill={'green.500'} />
        </Box>
      ) : null}
    </Flex>
  );
}

export default MultiSelectFieldFormatOptionLabel;
