import {
  JobResumeRecruiterItemBffID,
  JobResumeRecruiterItemBffNanoID,
  FetchJobResumeRecruitersBffURL,
  FetchJobResumeRecruiterBffURL,
  FetchJobResumeRecruiterByNanoIdBffURL,
  CreateJobResumeRecruiterBffURL,
  UpdateJobResumeRecruiterBffURL,
  DeleteJobResumeRecruiterBffURL
} from './jobResumeRecruitersTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobResumeRecruitersBffRoutes extends BaseBffRoutes {
  protected static route = 'jobResumeRecruiters';

  static fetchJobResumeRecruitersRoute() {
    return this.fetchItemsRoute<FetchJobResumeRecruitersBffURL>();
  }

  static fetchJobResumeRecruitersIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobResumeRecruitersBffURL>();
  }

  static fetchJobResumeRecruiterRoute(
    jobResumeRecruiterItemBffID: JobResumeRecruiterItemBffID
  ) {
    return this.fetchItemRoute<FetchJobResumeRecruiterBffURL>(
      jobResumeRecruiterItemBffID
    );
  }

  static fetchJobResumeRecruiterIndexRoute(
    jobResumeRecruiterItemBffNanoID: JobResumeRecruiterItemBffNanoID
  ) {
    return this.fetchItemIndexRoute<FetchJobResumeRecruiterBffURL>(
      jobResumeRecruiterItemBffNanoID
    );
  }

  static fetchJobResumeRecruiterByNanoIdRoute(
    jobResumeRecruiterItemBffNanoID: JobResumeRecruiterItemBffNanoID
  ) {
    return this.fetchItemByNanoIdRoute<FetchJobResumeRecruiterByNanoIdBffURL>(
      jobResumeRecruiterItemBffNanoID
    );
  }

  static createJobResumeRecruiterRoute() {
    return this.createItemRoute<CreateJobResumeRecruiterBffURL>();
  }

  static updateJobResumeRecruiterRoute(
    jobResumeRecruiterItemBffNanoID:
      | JobResumeRecruiterItemBffID
      | JobResumeRecruiterItemBffNanoID
  ) {
    return this.updateItemRoute<UpdateJobResumeRecruiterBffURL>(
      jobResumeRecruiterItemBffNanoID
    );
  }

  static deleteJobResumeRecruiterRoute(
    jobResumeRecruiterItemBffNanoID: JobResumeRecruiterItemBffNanoID
  ) {
    return this.deleteItemRoute<DeleteJobResumeRecruiterBffURL>(
      jobResumeRecruiterItemBffNanoID
    );
  }
}
