import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { JobNanoId } from '../../../../main/jobs/jobsTypes';
import ScheduleHelper from '../../ScheduleHelper';
import { ScheduleHelperJobCandidate } from '../../ScheduleHelper.types';

interface ScheduleHelperModalProps {
  title?: string;
  isOpen: boolean;
  minSlots?: number;
  maxSlots?: number;
  minDate?: Date | null;
  maxDate?: Date | null;
  jobNanoId?: JobNanoId;
  onCancel?: () => void;
  onSubmit: () => void;
  selectDateLabel?: string;
  jobCandidate?: ScheduleHelperJobCandidate;
}

export default function ScheduleHelperModal({
  title,
  isOpen,
  minDate,
  maxDate,
  onCancel,
  onSubmit,
  minSlots,
  maxSlots,
  jobNanoId,
  jobCandidate,
  selectDateLabel
}: ScheduleHelperModalProps) {
  return (
    <Modal
      size="5xl"
      isOpen={isOpen}
      onClose={onCancel || onSubmit}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />

      <ModalContent>
        <ModalBody bg="green.300" p={0}>
          <ScheduleHelper
            title={title}
            minDate={minDate}
            maxDate={maxDate}
            minSlots={minSlots}
            maxSlots={maxSlots}
            onCancel={onCancel}
            onSubmit={onSubmit}
            jobNanoId={jobNanoId}
            jobCandidate={jobCandidate}
            selectDateLabel={selectDateLabel}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
