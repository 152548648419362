import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { DepartmentNanoId } from '../departments/departmentsTypes';
import { HiringPortalSourcingJobCandidateDetailsRoutes } from './HiringPortalSourcingJobCandidateDetailsRoutes';

const basePath = (
  departmentNanoId: DepartmentNanoId,
  jobCandidateNanoId: JobCandidateNanoId
) =>
  HiringPortalSourcingJobCandidateDetailsRoutes.activities(
    departmentNanoId,
    jobCandidateNanoId
  );

export class HiringPortalSourcingJobCandidateDetailsActivitiesRoutes {
  static index(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}`;
  }

  static all(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/all-activities`;
  }

  static phoneCalls(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/phone-calls`;
  }

  static callConnections(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/call-connections`;
  }

  static emails(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/emails`;
  }

  static meetings(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/meetings`;
  }

  static stageChanges(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/stage-changes`;
  }

  static comments(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/comments`;
  }

  static tasks(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/tasks`;
  }
}
