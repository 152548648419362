import { FieldBrand, ItemRecordID } from '../../../types';

export type JobResumeRecruiterRecordId = FieldBrand<
  ItemRecordID,
  'JobResumeRecruiterRecordId'
>;

export const enum JobResumeRecruiterIndexFields {
  ID = 'id',
  RECORD_ID = 'recordId',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  TITLE = 'title',
  PHONE = 'phone',
  EMAIL = 'email',
  LINKEDIN_URL = 'linkedinUrl',
  STATUS = 'status',
  EMPLOYMENT_TYPES = 'employmentTypes',
  WORKPLACE_TYPES = 'workplaceTypes',
  PAID_TIME_OFF = 'paidTimeOff',
  MINIMUM_DESIRED_PAY = 'minimumDesiredPay',
  MAXIMUM_DESIRED_PAY = 'maximumDesiredPay',
  PRIMARY_JOB_FUNCTION = 'primaryJobFunction',
  WORK_AUTHORIZATION = 'workAuthorization',
  FUTURE_REQUIREMENTS = 'futureRequirements',
  PROFESSIONAL_EXPERIENCE = 'professionalExperience',
  DESCRIPTION = 'description',
  SEARCHABLE_AT = 'searchableAt',
  CREATED_AT = 'createdAt',

  USER_UUID = 'userUuid',

  IMAGE_ID = 'imageId',
  IMAGE = 'image',
  IMAGE_NANO_ID = 'image.nanoId',
  IMAGE_NAME = 'image.name',
  IMAGE_KEY = 'image.key',
  IMAGE_FILE = 'image.file',

  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  COMPANY_NANO_ID = 'company.nanoId',
  COMPANY_NAME = 'company.name',

  STATE_ID = 'stateId',
  STATE = 'state',
  STATE_NANO_ID = 'state.nanoId',
  STATE_NAME = 'state.name',
  STATE_ISO_CODE = 'state.isoCode',

  CITY_ID = 'cityId',
  CITY = 'city',
  CITY_NANO_ID = 'city.nanoId',
  CITY_NAME = 'city.name',
  CITY_LABEL = 'city.label',

  CITY_IDS = 'cityIds',
  CITIES = 'cities',

  JOB_RESUME_ID = 'jobResumeId',
  JOB_RESUME = 'jobResume',

  JOB_RESUME_FILE_ATTACHMENTS_NANO_ID = 'jobResume.fileAttachments.nanoId',
  JOB_RESUME_FILE_ATTACHMENTS_NAME = 'jobResume.fileAttachments.name',
  JOB_RESUME_FILE_ATTACHMENTS_FILE = 'jobResume.fileAttachments.file',
  JOB_RESUME_FILE_ATTACHMENTS_SIZE = 'jobResume.fileAttachments.size',

  INDUSTRY_IDS = 'industryIds',
  INDUSTRIES = 'industries',
  INDUSTRIES_RECORD_ID = 'industries.recordId',
  INDUSTRIES_NANO_ID = 'industries.nanoId',
  INDUSTRIES_NAME = 'industries.name',

  SPECIALIZATION_IDS = 'specializationIds',
  SPECIALIZATIONS = 'specializations',
  SPECIALIZATIONS_RECORD_ID = 'specializations.recordId',
  SPECIALIZATIONS_NANO_ID = 'specializations.nanoId',
  SPECIALIZATIONS_NAME = 'specializations.name',

  SKILL_IDS = 'skillIds'
}
