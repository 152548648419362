import {
  Avatar,
  Box,
  Flex,
  Stack,
  StackDivider,
  SystemStyleObject,
  VStack
} from '@chakra-ui/react';
import lowerCase from 'lodash/lowerCase';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import { useCurrentUser } from '../../../../../../../../auth/hooks/useAuth';
import { AvatarHelper } from '../../../../../../../../helpers/AvatarHelper';
import { Button } from '../../../../../../../../helpers/Button';
import { MultiSelectFieldControl } from '../../../../../../../../helpers/forms/formFields/MultiSelectField/components/MultiSelectFieldControl';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Logo } from '../../../../../../../../helpers/Logo';
import { Rating } from '../../../../../../../../helpers/Rating';
import { Text } from '../../../../../../../../helpers/Text';
import { ImagesUrl } from '../../../../../../../images/ImagesUrl';
import { renderJobCandidateName } from '../../../../../../../jobCandidates/utils/renderJobCandidateName';
import { renderJobCandidateStatus } from '../../../../../../../jobCandidates/utils/renderJobCandidateStatus';
import { SkillBadgesCache } from '../../../../../../../skillBadges/SkillBadgesCache';
import { SkillBadgeJobCandidateId } from '../../../../../../../skillBadges/skillBadgesTypes';
import { CommonSkillsBox } from '../../../../../../../skills/components/boxes/CommonSkillsBox';
import { SkillsCache } from '../../../../../../../skills/SkillsCache';
import { SkillKinds } from '../../../../../../../skills/skillsTypes';
import {
  JobId,
  JobInterviewers,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity,
  MayBeJobCompany,
  MayBeJobHiringManager
} from '../../../../../../jobsTypes';
import { renderJobTitle } from '../../../../../../utils/renderJobTitle';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import useEmploymentData from '../../../../hooks/useEmploymentData/useEmploymentData';
import { JobCandidateDetailsJobCandidate } from '../../../../ViewJobCandidateDetailsPage.types';
import { AlertMessage } from '../../../../../../../../helpers/AlertMessage';
import {
  CandidateSubmissionFields,
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { JobLocationHelper } from '../../../../../../helpers/JobLocationHelper';
import { MultiSelectField } from '../../../../../../../../helpers/forms/formFields/MultiSelectField';

interface CustomiseCandidateSubmissionSendEmailProps {
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
    cities: MayBeJobCities;
    city: MayBeJobCity;
    interviewers: JobInterviewers;
    hiringManager: MayBeJobHiringManager;
    company: MayBeJobCompany;
  };
  jobCandidate: JobCandidateDetailsJobCandidate;
}

export default function CustomiseCandidateSubmissionSendEmail({
  job,
  jobCandidate
}: CustomiseCandidateSubmissionSendEmailProps) {
  const {
    sharedFields,
    recipients,
    errorMessage,
    control,
    validationErrors,
    evaluations
  } = useViewJobCandidateDetailsSubmissionContext();
  const currentUser = useCurrentUser();

  const { professionalExperience } = useEmploymentData({
    jobCandidateId: jobCandidate.id
  });

  const emailRecipientsSelectValue =
    recipients?.map(({ id, name, image }) => ({
      label: name,
      value: id,
      image: ImagesUrl.file(image) || ''
    })) || [];

  const totalRating = reduce(
    evaluations,
    (sum2, evaluation) => {
      return (sum2 = sum2 + evaluation.rating);
    },
    0
  );

  const averageRating = totalRating / size(evaluations);

  return (
    <Stack flex={1} spacing={5}>
      <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
        Submit Candidate
      </Heading>

      <Flex gap={4} flexDir="column" flex={1}>
        <Stack
          spacing={2}
          color="gray.600"
          textStyle="body1Regular"
          divider={<StackDivider />}
        >
          <Flex as="label" gap={1} cursor="pointer" alignItems="center">
            <Box as="span">To:</Box>

            <MultiSelectField
              withImages
              control={control}
              name={CandidateSubmissionFields.RECIPIENT_IDS}
              errorMessage={validationErrors.recipientIdsValidationError}
              reactSelectStyles={{
                control: (base: SystemStyleObject) => ({
                  ...base,
                  borderColor: 'transparent',
                  p: 0
                })
              }}
              defaultValue={emailRecipientsSelectValue}
              options={emailRecipientsSelectValue}
            />
          </Flex>

          <Flex as="label" gap={1} cursor="pointer" alignItems="center">
            <Box as="span">Cc:</Box>{' '}
            <MultiSelectFieldControl
              size="sm"
              withImages
              onChange={console.log}
              reactSelectStyles={{
                control: (base: SystemStyleObject) => ({
                  ...base,
                  borderColor: 'transparent',
                  p: 0
                })
              }}
              options={[]}
              isReadOnly
            />
          </Flex>

          <Flex as="label" gap={1} cursor="pointer" alignItems="center">
            <Box as="span">Subject:</Box>

            <Text color="gray.800" fontSize="sm" textTransform="capitalize">
              Candidate Submission: {renderJobCandidateName(jobCandidate)}
            </Text>
          </Flex>
        </Stack>

        <Box bg="gray.100" p={4} flex={1}>
          <Stack
            bg="white"
            spacing={0}
            overflow="hidden"
            borderRadius="md"
            border="1px solid"
            borderColor="gray.200"
          >
            <Flex
              pt={2.5}
              bg="white"
              alignItems="center"
              justifyContent="center"
              gap={1}
              flex={1}
            >
              {currentUser.company?.image ? (
                <Avatar
                  h={8}
                  w={8}
                  src={ImagesUrl.file(currentUser.company.image) || undefined}
                />
              ) : null}

              <Flex gap={0.5} flexDir="column" justifyContent="center">
                <Text fontSize="xs" lineHeight={1.15}>
                  {currentUser.company?.name}
                </Text>
                <Text fontSize="2xs" lineHeight={1.15} color="gray.600">
                  Talent Acquisition
                </Text>
              </Flex>
            </Flex>

            <Stack p={4} spacing={3}>
              <VStack spacing={1}>
                <Text color="gray.800" fontSize="md" textTransform="capitalize">
                  {lowerCase(renderJobTitle(job))}
                </Text>
                <JobLocationHelper job={job} />
              </VStack>

              <Stack bg="gray.100" spacing={4} p={2} borderRadius="base">
                <Flex gap={2} alignItems="center">
                  <AvatarHelper
                    h={8}
                    w={8}
                    image={
                      sharedFields.includes(
                        CandidateSubmissionShareSettingsFields.ProfilePhotos as CandidateSubmissionShareSettingsField
                      )
                        ? jobCandidate.image
                        : null
                    }
                  />

                  <Stack spacing={0}>
                    <Text
                      fontSize="sm"
                      noOfLines={1}
                      lineHeight={1.5}
                      fontWeight="semibold"
                    >
                      {renderJobCandidateName(jobCandidate)}
                    </Text>

                    <Text
                      fontSize="xs"
                      noOfLines={1}
                      lineHeight={1.25}
                      color="gray.600"
                    >
                      {renderJobTitle(job)}
                    </Text>

                    <Rating
                      py={1}
                      px={0}
                      value={averageRating}
                      iconSize={3}
                      readonly
                    />
                  </Stack>
                </Flex>

                <Flex flexDir="column" gap={1}>
                  <Text color="gray.500" textStyle="upperCase1Regular">
                    Status:{' '}
                    <Box
                      as="span"
                      color="gray.600"
                      textTransform="capitalize"
                      fontWeight="medium"
                    >
                      {renderJobCandidateStatus(jobCandidate.status)}
                    </Box>
                  </Text>

                  <Text color="gray.500" textStyle="upperCase1Regular">
                    Experience:{' '}
                    <Box
                      as="span"
                      color="gray.600"
                      textTransform="capitalize"
                      fontWeight="medium"
                    >
                      {professionalExperience}
                    </Box>
                  </Text>
                </Flex>

                <Stack spacing={2}>
                  <Text textStyle="upperCase1Medium" color="gray.600">
                    Software skills
                  </Text>

                  <CommonSkillsBox
                    withoutAddBlock
                    withoutEditDeleteFunctionality
                    skillKind={SkillKinds.COMPUTER}
                    jobCandidateId={jobCandidate.id}
                    skillBadgesCacheKey={SkillBadgesCache.jobCandidateCacheKey(
                      jobCandidate.id as SkillBadgeJobCandidateId
                    )}
                    skillsCacheKey={SkillsCache.computerCacheKey()}
                    newSkillsCacheKey={SkillsCache.computerNewSkillsCacheKey()}
                    maxSkillBadgesToShow={2}
                  />
                </Stack>

                <Stack spacing={2}>
                  <Text textStyle="upperCase1Medium" color="gray.600">
                    Industry skills
                  </Text>

                  <CommonSkillsBox
                    withoutAddBlock
                    withoutEditDeleteFunctionality
                    skillKind={SkillKinds.INDUSTRY}
                    jobCandidateId={jobCandidate.id}
                    skillBadgesCacheKey={SkillBadgesCache.jobCandidateCacheKey(
                      jobCandidate.id as SkillBadgeJobCandidateId
                    )}
                    skillsCacheKey={SkillsCache.industryCacheKey()}
                    newSkillsCacheKey={SkillsCache.industryNewSkillsCacheKey()}
                    maxSkillBadgesToShow={2}
                  />
                </Stack>
              </Stack>

              <Box>
                <Button size="small" px={5}>
                  View profile details
                </Button>
              </Box>

              <Text fontSize="xs" lineHeight={1.35}>
                Best Regards, <br />
              </Text>
              <Text fontSize="xs" lineHeight={1.35}>
                {currentUser.name} <br />
                {currentUser.title || currentUser.companyRoleName}
              </Text>
            </Stack>

            <Stack p={4} borderTop="1px solid" borderColor="gray.300">
              <Text
                fontSize="3xs"
                color="gray.600"
                lineHeight={1.5}
                textAlign="center"
              >
                You are receiving a nxmoov invitation email.{' '}
                <Box as="span" color="primary.500">
                  Unsubscribe or change frequency
                </Box>
                . This email was intended for Sherry Johnson (Senior Director,
                Talent Acquistion at AECOM). © 2023 Nxmoov LLC Litchfield Park,
                AZ 85340. Nxmoov and the Nxmoov logo are registered trademarks
                of Nxmoov
              </Text>

              <Flex bg="white" alignItems="center" justifyContent="center">
                <Text mb={1.5} fontSize="2xs">
                  Powered by
                </Text>
                <Logo h="26px" w="64px" />
              </Flex>
            </Stack>
          </Stack>
        </Box>
      </Flex>

      <AlertMessage message={errorMessage} />
    </Stack>
  );
}
