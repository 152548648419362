import { Box, Flex, Grid, GridItem } from '@chakra-ui/layout';
import { Show, useMediaQuery } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ReactNode, useCallback } from 'react';

import {
  MainActiveLink,
  useLeftSidebarContext,
  useRecruiterContext
} from '../../context';
import { useSubscribeToPreviousRouter } from '../../common/hooks/utils/usePreviousRouter';

import { HiringPortalRoutes } from '../../main/hiringPortal/HiringPortalRoutes';
import { HiringPortalSettingsRoutes } from '../../main/hiringPortal/HiringPortalSettingsRoutes';

import { CandidatesIcon } from '../../icons/CandidatesIcon';
import { HelpIcon } from '../../icons/HelpIcon';
import { JobsIcon } from '../../icons/JobsIcon';
import { SettingsIcon } from '../../icons/SettingsIcon';
import { PersonIcon } from '../../icons/PersonIcon';

import { Header } from './components/Header';
import { LeftSidebar } from './components/LeftSidebar';

import { SidebarMenuItem } from '../../helpers/SidebarMenuItem';

import { MobileFooterNav } from '../../helpers/MobileFooterNav';

import { HiringPortalLayoutRequiredProps } from './HiringPortalLayout.types';

import { CheckPermissionLayout } from '../../main/common/layouts/CheckPermissionLayout';

import { HiringPortalPermissions } from '../../main/hiringPortal/HiringPortalPermissions';
import { TalentPoolsPermissions } from '../../main/talentPools/TalentPoolsPermissions';
import { IconButton } from '../../helpers/buttons/IconButton';
import { HiringPortalSourcingRoutes } from '../../main/hiringPortal/HirngPortalSourcingRoutes';
import { MenuIconPipelines } from '../../icons/MenuIconPipelines';

export type ActiveLink =
  | 'dashboard'
  | 'jobs'
  | 'pipeline'
  | 'talent-pools'
  | 'prospects'
  | 'settings'
  | 'help';

interface HiringPortalLayoutProps {
  children: ReactNode;
  activeLink: MainActiveLink;
  footer?: ReactNode;
  action?: string;
}

function HiringPortalLayout({
  activeLink,
  onSignOut,
  children,
  totalTalentCredits = 200,
  footer,
  action
}: HiringPortalLayoutProps & HiringPortalLayoutRequiredProps) {
  const router = useRouter();
  const { toggleRecruiterSignUpModal } = useRecruiterContext();

  useSubscribeToPreviousRouter();

  const { isDocked } = useLeftSidebarContext();

  const handleMenuItemOnMouseOver = useCallback<(url: string) => void>(
    (url) => router.prefetch(url),
    [router]
  );

  const [isDesktop] = useMediaQuery('(min-width: 992px)', {
    ssr: true,
    fallback: false
  });

  return (
    <CheckPermissionLayout
      appAction={HiringPortalPermissions.HIRING_PORTAL_ACCESS}
      action={
        action || HiringPortalPermissions.READ_HIRING_PORTAL_CANDIDATES_PAGE
      }
    >
      <Show above="lg">
        <LeftSidebar colorScheme="light">
          <SidebarMenuItem
            title="Jobs"
            url={HiringPortalRoutes.jobs()}
            icon={JobsIcon}
            isActive={activeLink === 'jobs'}
            onMouseOver={handleMenuItemOnMouseOver}
          />
          <SidebarMenuItem
            title="Pipelines"
            icon={PersonIcon}
            url={HiringPortalRoutes.index()}
            isActive={activeLink === 'pipeline'}
            onMouseOver={handleMenuItemOnMouseOver}
          />
          <SidebarMenuItem
            title="Talent Pools"
            url={HiringPortalRoutes.talentPools()}
            icon={CandidatesIcon}
            isActive={activeLink === 'talent-pools'}
            isChildActive={['prospects'].includes(activeLink)}
            onMouseOver={handleMenuItemOnMouseOver}
            action={TalentPoolsPermissions.READ_TALENT_POOLS_INDEX_PAGE}
          >
            <SidebarMenuItem
              title="Prospects"
              url={HiringPortalSourcingRoutes.viewIndex()}
              icon={MenuIconPipelines}
              isActive={activeLink === 'prospects'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
          </SidebarMenuItem>

          <Box mt="auto">
            <SidebarMenuItem
              title="Settings"
              url={HiringPortalSettingsRoutes.personalAbout()}
              icon={SettingsIcon}
              isActive={activeLink === 'settings'}
              onMouseOver={handleMenuItemOnMouseOver}
            />

            <IconButton
              h={14}
              w="full"
              aria-label=""
              boxShadow="none"
              hierarchy="ghost"
              borderRadius="base"
              icon={<HelpIcon />}
              onClick={toggleRecruiterSignUpModal}
            />

            {/* <SidebarMenuItem
              title="Help"
              url="/help"
              icon={HelpIcon}
              isActive={activeLink === 'help'}
              onMouseOver={handleMenuItemOnMouseOver}
            /> */}
          </Box>
        </LeftSidebar>
      </Show>

      <Grid
        gridTemplateRows={{
          lg: footer ? 'max-content 1fr 48px' : 'max-content 1fr'
        }}
        height="100%"
        as="section"
        bg="gray.50"
        h="100vh"
        flexDirection="column"
        transitionDuration="slow"
        transitionProperty="margin-left"
        ml={{ lg: isDocked ? 20 : 60 }}
        position="relative"
        zIndex={1}
      >
        {isDesktop && (
          <GridItem display={{ base: 'none', lg: 'unset' }}>
            <Header
              onSignOut={onSignOut}
              totalTalentCredits={totalTalentCredits || 200}
            />
          </GridItem>
        )}

        <GridItem
          overflow="auto"
          h={{ base: 'calc(100vh - 68px)', lg: 'auto' }}
        >
          {children}
        </GridItem>

        {footer ? (
          <GridItem>
            <Flex
              h={12}
              bg="#F7F7F9"
              alignItems="center"
              justifyContent="center"
              display={{ base: 'none', md: 'flex' }}
              boxShadow="0px 0px 2px rgba(0, 0, 0, 0.25)"
            >
              {footer}
            </Flex>
          </GridItem>
        ) : null}

        {!isDesktop && <MobileFooterNav activeLink={activeLink} />}
      </Grid>
    </CheckPermissionLayout>
  );
}

export default HiringPortalLayout;
