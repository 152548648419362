import {
  Box,
  Flex,
  HStack,
  Spinner,
  StackDivider,
  VStack
} from '@chakra-ui/react';
import compact from 'lodash/compact';
import lowerCase from 'lodash/lowerCase';
import size from 'lodash/size';
import startCase from 'lodash/startCase';
import truncate from 'lodash/truncate';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import {
  Fragment,
  MouseEventHandler,
  RefObject,
  useCallback,
  useEffect
} from 'react';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { AvatarHelper } from '../../../../../../helpers/AvatarHelper';
import { Heading } from '../../../../../../helpers/Heading';
import { Text } from '../../../../../../helpers/Text';
import { IconButton } from '../../../../../../helpers/buttons/IconButton';
import { useChakraToast } from '../../../../../../helpers/useChakraToast';
import { HeartIcon } from '../../../../../../icons/HeartIcon';
import { JobsIcon } from '../../../../../../icons/JobsIcon';
import { LinkedInIcon } from '../../../../../../icons/LinkedInIcon';
import { LocationIcon } from '../../../../../../icons/LocationIcon';
import { renderLocation } from '../../../../../common/utils/renderLocation';
import { JobCandidateLabelHelper } from '../../../../../jobCandidates/helpers/JobCandidateLabelHelper';
import { renderJobCandidateName } from '../../../../../jobCandidates/utils/renderJobCandidateName';
import { LikedJobCandidatesCache } from '../../../../../likedJobCandidates/LikedJobCandidatesCache';
import { useCreateLikedJobCandidate } from '../../../../../likedJobCandidates/hooks/useCreateLikedJobCandidate';
import { useDeleteLikedJobCandidate } from '../../../../../likedJobCandidates/hooks/useDeleteLikedJobCandidate';
import { usePaginatedLikedJobCandidates } from '../../../../../likedJobCandidates/hooks/usePaginatedLikedJobCandidates';
import {
  FetchLikedJobCandidatesFilters,
  LikedJobCandidateFields,
  LikedJobCandidateId,
  LikedJobCandidateJobCandidateId,
  LikedJobCandidateUserId
} from '../../../../../likedJobCandidates/likedJobCandidatesTypes';
import {
  FetchLikedJobCandidatesResponse,
  fetchLikedJobCandidatesQuery
} from '../../../../../likedJobCandidates/queries/fetchLikedJobCandidates.query';
import { JobLocationHelper } from '../../../../helpers/JobLocationHelper';
import { JobId, JobJobTitle, JobName, JobNanoId } from '../../../../jobsTypes';
import { renderJobTitle } from '../../../../utils/renderJobTitle';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import {
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { usePaginatedCandidateSubmissionJobCandidates } from '../../../../../candidateSubmissionJobCandidates/hooks/usePaginatedCandidateSubmissionJobCandidates';
import {
  fetchCandidateSubmissionJobCandidatesQuery,
  FetchCandidateSubmissionJobCandidatesResponse
} from '../../../../../candidateSubmissionJobCandidates/queries/fetchCandidateSubmissionJobCandidates.query';
import { CandidateSubmissionJobCandidatesCache } from '../../../../../candidateSubmissionJobCandidates/CandidateSubmissionJobCandidatesCache';
import {
  CandidateSubmissionJobCandidateFields,
  FetchCandidateSubmissionJobCandidatesFilters,
  FetchCandidateSubmissionJobCandidatesPageSize
} from '../../../../../candidateSubmissionJobCandidates/candidateSubmissionJobCandidatesTypes';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import { SourcingJobCandidateDetailsRoutes } from '../../../../../sourcing/SourcingJobCandidateDetailsRoutes';
import { ViewJobCandidateDetailsRoutes } from '../../ViewJobCandidateDetailsRoutes';
import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { Rating } from '../../../../../../helpers/Rating';
import Link from 'next/link';
import { HiringPortalSourcingJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalSourcingJobCandidateDetailsRoutes';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';

interface ViewJobCandidateDetailsHeaderProps {
  isCollapsed?: boolean;
  requiresCredit?: boolean;
  jobCandidate: JobCandidateDetailsJobCandidate;
  scoreCard?: boolean;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
  };
  isBioOutsideViewport?: RefObject<HTMLDivElement>;
  isHiringPortalPath?: boolean;
  isProspectPath?: boolean;
  withoutReviewsPath?: boolean;
  isBasePath?: boolean;
  departmentNanoId?: DepartmentNanoId;
}

function ViewJobCandidateDetailsBio({
  job,
  isCollapsed,
  jobCandidate,
  requiresCredit,
  isBioOutsideViewport,
  scoreCard,
  isHiringPortalPath,
  isProspectPath,
  isBasePath,
  withoutReviewsPath,
  departmentNanoId
}: ViewJobCandidateDetailsHeaderProps) {
  const toast = useChakraToast();
  const currentUser = useCurrentUser();

  const { likedJobCandidates } =
    usePaginatedLikedJobCandidates<FetchLikedJobCandidatesResponse>({
      query: fetchLikedJobCandidatesQuery,
      cacheKey: LikedJobCandidatesCache.userIndexCacheKey(currentUser.id),
      initialFilters: {
        [LikedJobCandidateFields.USER_ID]: {
          operator: 'eq',
          value: currentUser.id
        },
        [LikedJobCandidateFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidate?.id
        }
      } as unknown as FetchLikedJobCandidatesFilters
    });

  const likedJobCandidate = likedJobCandidates.find(
    (candidate) => candidate.jobCandidateId === jobCandidate?.id
  );

  const {
    deleteLikedJobCandidate,
    deleteLikedJobCandidateIsLoading,
    deleteLikedJobCandidateErrorMessage
  } = useDeleteLikedJobCandidate({
    likedJobCandidateId: likedJobCandidate?.id as LikedJobCandidateId,
    cacheKeys: [LikedJobCandidatesCache.userIndexCacheKey(currentUser.id)]
  });

  const {
    createLikedJobCandidate,
    createLikedJobCandidateIsLoading,
    createLikedJobCandidateErrorMessage
  } = useCreateLikedJobCandidate({
    cacheKeys: [LikedJobCandidatesCache.userIndexCacheKey(currentUser.id)]
  });

  const {
    candidateSubmissionJobCandidates,
    candidateSubmissionJobCandidatesIsFetched
  } = usePaginatedCandidateSubmissionJobCandidates<FetchCandidateSubmissionJobCandidatesResponse>(
    {
      query: fetchCandidateSubmissionJobCandidatesQuery,
      cacheKey: CandidateSubmissionJobCandidatesCache.jobCandidateIndexCacheKey(
        jobCandidate.nanoId
      ),
      initialFilters: {
        [CandidateSubmissionJobCandidateFields.JOB_CANDIDATE_ID]: {
          operator: 'eq',
          value: jobCandidate.id
        }
      } as unknown as FetchCandidateSubmissionJobCandidatesFilters,
      initialPageSize: 15 as FetchCandidateSubmissionJobCandidatesPageSize
    }
  );

  const hiringPortalSourcingRoutes =
    isHiringPortalPath && isProspectPath
      ? HiringPortalSourcingJobCandidateDetailsRoutes
      : null;

  const hiringPortalRoutes = isHiringPortalPath
    ? HiringPortalJobCandidateDetailsRoutes
    : null;

  const prospectRoute =
    hiringPortalSourcingRoutes || SourcingJobCandidateDetailsRoutes;

  const route = hiringPortalRoutes || ViewJobCandidateDetailsRoutes;

  const prospectReviewsRoute = prospectRoute.profile(
    departmentNanoId as DepartmentNanoId,
    jobCandidate.nanoId
  );

  const normalReviewsRoute = route.profile(job.nanoId, jobCandidate.nanoId);

  const reviewsRoute = isProspectPath
    ? prospectReviewsRoute
    : normalReviewsRoute;

  const allSubmissionsWithEvaluation = filter(
    candidateSubmissionJobCandidates,
    (sumCan) => size(sumCan.candidateSubmission?.evaluations) > 0
  );

  const totalRating = reduce(
    allSubmissionsWithEvaluation,
    (sum, submissionCandidate) => {
      const totalSubmissionRating = reduce(
        submissionCandidate.candidateSubmission?.evaluations,
        (sum2, evaluation) => {
          return (sum2 = sum2 + evaluation.rating);
        },
        0
      );

      const averageSubmissionRating =
        totalSubmissionRating /
        size(submissionCandidate.candidateSubmission?.evaluations);

      return (sum = sum + averageSubmissionRating);
    },
    0
  );

  const numberOfReviews = size(allSubmissionsWithEvaluation);

  const averageRating = Number((totalRating / numberOfReviews).toFixed(2));

  const handleToggleLikeJobCandidate = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(
    (e) => {
      e.stopPropagation();
      if (likedJobCandidate) {
        deleteLikedJobCandidate({});
      } else {
        createLikedJobCandidate({
          userId: currentUser.id as LikedJobCandidateUserId,
          jobCandidateId: jobCandidate?.id as LikedJobCandidateJobCandidateId
        });
      }
    },
    [
      createLikedJobCandidate,
      currentUser.id,
      deleteLikedJobCandidate,
      jobCandidate?.id,
      likedJobCandidate
    ]
  );

  useEffect(() => {
    deleteLikedJobCandidateErrorMessage &&
      toast({
        title: deleteLikedJobCandidateErrorMessage ?? `An error occurred`,
        status: 'error',
        position: 'top-right',
        duration: 2000,
        isClosable: true
      });
  }, [deleteLikedJobCandidateErrorMessage, toast]);

  useEffect(() => {
    createLikedJobCandidateErrorMessage &&
      toast({
        title: createLikedJobCandidateErrorMessage ?? `An error occurred`,
        status: 'error',
        position: 'top-right',
        duration: 2000,
        isClosable: true
      });
  }, [createLikedJobCandidateErrorMessage, toast]);

  const isCandidateLiked = !!size(likedJobCandidate);
  const location = jobCandidate && renderLocation(jobCandidate);

  const { shareSettings } = useViewJobCandidateDetailsSubmissionContext();

  const showCandidateName =
    !requiresCredit &&
    shareSettings.includes(
      CandidateSubmissionShareSettingsFields.ProfileCandidateName as CandidateSubmissionShareSettingsField
    );

  const showJobTitle =
    showCandidateName &&
    jobCandidate.jobTitle &&
    shareSettings.includes(
      CandidateSubmissionShareSettingsFields.ProfileJobTitle as CandidateSubmissionShareSettingsField
    );

  const showCurrentCompany =
    jobCandidate.company?.name &&
    shareSettings.includes(
      CandidateSubmissionShareSettingsFields.ProfileCurrentCompany as CandidateSubmissionShareSettingsField
    );

  return (
    <Flex flexDir="column" gap={4} ref={isBioOutsideViewport}>
      <HStack spacing={4}>
        <Flex pos="relative">
          <AvatarHelper
            h={isCollapsed ? 10 : 14}
            w={isCollapsed ? 10 : 14}
            image={
              shareSettings.includes(
                CandidateSubmissionShareSettingsFields.ProfilePhotos as CandidateSubmissionShareSettingsField
              )
                ? jobCandidate.image
                : null
            }
          />

          <IconButton
            w={isCollapsed ? 4 : 5}
            h={isCollapsed ? 4 : 5}
            p={0}
            minW={4}
            right={0}
            bottom={0}
            aria-label=""
            pos="absolute"
            display="flex"
            border="1px solid"
            alignItems="center"
            borderColor="white"
            borderRadius="full"
            hierarchy="unstyled"
            justifyContent="center"
            transitionDuration="fast"
            onClick={handleToggleLikeJobCandidate}
            bg={isCandidateLiked ? 'red.100' : 'white'}
            icon={
              createLikedJobCandidateIsLoading ||
              deleteLikedJobCandidateIsLoading ? (
                <Spinner color="red.500" size="xs" />
              ) : (
                <HeartIcon
                  cursor="pointer"
                  stroke="red.500"
                  strokeWidth="3px"
                  w={isCollapsed ? 2 : 3}
                  h={isCollapsed ? 2 : 3}
                  fill={isCandidateLiked ? 'red.500' : 'transparent'}
                />
              )
            }
          />
        </Flex>

        <VStack spacing={1} alignItems="start">
          <HStack spacing={2}>
            <Heading
              flex={1}
              level={isCollapsed ? 'h3' : 'h2'}
              noOfLines={1}
              color="gray.900"
              wordBreak="break-all"
            >
              <>
                {!showCandidateName || requiresCredit
                  ? startCase(
                      lowerCase(jobCandidate.jobTitle || renderJobTitle(job))
                    )
                  : renderJobCandidateName(jobCandidate)}
              </>
            </Heading>

            <JobCandidateLabelHelper
              searchTypeLabel={jobCandidate.searchTypeLabel}
            />
          </HStack>

          {!isCollapsed && (
            <>
              {requiresCredit ? (
                <JobLocationHelper jobCandidate={jobCandidate} withIcon />
              ) : scoreCard ? (
                <Fragment>
                  <HStack direction="row" spacing={3}>
                    {(showJobTitle || showCurrentCompany) && (
                      <JobsIcon w="20px" h="20px" fill="gray.500" />
                    )}
                    <Text
                      textStyle="body1Regular"
                      wordBreak="break-all"
                      color="gray.900"
                      fontWeight={400}
                      noOfLines={1}
                    >
                      {compact([
                        showJobTitle &&
                          truncate(jobCandidate.jobTitle, {
                            length: 35,
                            omission: '...'
                          }),
                        showCurrentCompany && jobCandidate.company?.name
                      ]).join(' @ ')}
                    </Text>
                  </HStack>

                  {location &&
                    shareSettings.includes(
                      CandidateSubmissionShareSettingsFields.ProfileLocation as CandidateSubmissionShareSettingsField
                    ) && (
                      <HStack direction="row" spacing={3}>
                        <LocationIcon w="20px" h="20px" color="gray.500" />

                        <Text
                          textStyle="body1Regular"
                          color="gray.900"
                          wordBreak="break-all"
                          fontWeight={400}
                          noOfLines={1}
                        >
                          {location}
                        </Text>
                      </HStack>
                    )}
                </Fragment>
              ) : (
                <HStack spacing={4}>
                  <HStack spacing={4} minW={'150px'}>
                    <LoadingSkeleton
                      count={1}
                      loaded={candidateSubmissionJobCandidatesIsFetched}
                    >
                      <Rating value={averageRating} readonly />

                      <Box
                        as={withoutReviewsPath ? undefined : Link}
                        href={
                          isBasePath
                            ? '#candidateReviews'
                            : `${reviewsRoute}#candidateReviews`
                        }
                      >
                        <Text
                          textStyle="body1Medium"
                          textDecoration="underline"
                          color="primary.500"
                          whiteSpace="nowrap"
                          cursor="pointer"
                        >
                          {numberOfReviews} Review
                          {numberOfReviews > 1 ? 's' : ''}
                        </Text>
                      </Box>
                    </LoadingSkeleton>
                  </HStack>

                  {shareSettings.includes(
                    CandidateSubmissionShareSettingsFields.ProfileLinkedInUrl as CandidateSubmissionShareSettingsField
                  ) && (
                    <Flex
                      w={5}
                      h={5}
                      bg="#069"
                      alignItems="center"
                      borderRadius="full"
                      justifyContent="center"
                    >
                      <LinkedInIcon borderRadius="full" w={3.5} h={3.5} />
                    </Flex>
                  )}
                </HStack>
              )}
            </>
          )}
        </VStack>
      </HStack>

      {!requiresCredit && !isCollapsed && !scoreCard && (
        <HStack spacing={4} divider={<StackDivider />}>
          {(showJobTitle || showCurrentCompany) && (
            <HStack direction="row" spacing={3}>
              <JobsIcon w="20px" h="20px" fill="gray.500" />
              <Text
                textStyle="body1Regular"
                wordBreak="break-all"
                color="gray.900"
                fontWeight={400}
                noOfLines={1}
              >
                {compact([
                  showJobTitle &&
                    truncate(jobCandidate.jobTitle, {
                      length: 35,
                      omission: '...'
                    }),
                  showCurrentCompany && jobCandidate.company?.name
                ]).join(' @ ')}
              </Text>
            </HStack>
          )}

          {location &&
            shareSettings.includes(
              CandidateSubmissionShareSettingsFields.ProfileLocation as CandidateSubmissionShareSettingsField
            ) && (
              <HStack direction="row" spacing={3}>
                <LocationIcon w="20px" h="20px" color="gray.500" />

                <Text
                  textStyle="body1Regular"
                  color="gray.900"
                  wordBreak="break-all"
                  fontWeight={400}
                  noOfLines={1}
                >
                  {location}
                </Text>
              </HStack>
            )}
        </HStack>
      )}
    </Flex>
  );
}

export default ViewJobCandidateDetailsBio;
