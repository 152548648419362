import {
  JobScorecardItemBffID,
  FetchJobScorecardsBffURL,
  FetchJobScorecardBffURL,
  CreateJobScorecardBffURL,
  UpdateJobScorecardBffURL,
  DeleteJobScorecardBffURL,
  JobScorecardItemBffUuID
} from './jobScorecardsTypes';

import { BaseBffRoutes } from '../BaseBffRoutes';

export class JobScorecardsBffRoutes extends BaseBffRoutes {
  protected static route = 'jobScorecards';

  static fetchJobScorecardsRoute() {
    return this.fetchItemsRoute<FetchJobScorecardsBffURL>();
  }

  static fetchJobScorecardsIndexRoute() {
    return this.fetchItemsIndexRoute<FetchJobScorecardsBffURL>();
  }

  static fetchJobScorecardRoute(jobScorecardItemBffID: JobScorecardItemBffID) {
    return this.fetchItemRoute<FetchJobScorecardBffURL>(jobScorecardItemBffID);
  }

  static fetchJobScorecardIndexRoute(
    jobScorecardItemBffUuID: JobScorecardItemBffUuID
  ) {
    return this.fetchItemIndexRoute<FetchJobScorecardBffURL>(
      jobScorecardItemBffUuID
    );
  }

  static createJobScorecardRoute() {
    return this.createItemRoute<CreateJobScorecardBffURL>();
  }

  static updateJobScorecardRoute(jobScorecardItemBffID: JobScorecardItemBffID) {
    return this.updateItemRoute<UpdateJobScorecardBffURL>(
      jobScorecardItemBffID
    );
  }

  static deleteJobScorecardRoute(jobScorecardItemBffID: JobScorecardItemBffID) {
    return this.deleteItemRoute<DeleteJobScorecardBffURL>(
      jobScorecardItemBffID
    );
  }
}
