import {
  FetchJobResumeRecruitersCacheKey,
  FetchJobResumeRecruitersIndexCacheKey,
  FetchJobResumeRecruiterCacheKey,
  FetchJobResumeRecruiterIndexCacheKey
} from './jobResumeRecruitersTypes';

export class JobResumeRecruitersCache {
  static indexCacheKey() {
    return 'jobResumeRecruiters' as FetchJobResumeRecruitersCacheKey;
  }

  static listIndexCacheKey() {
    return 'jobResumeRecruiters-index' as FetchJobResumeRecruitersIndexCacheKey;
  }

  static showCacheKey() {
    return 'jobResumeRecruiter' as FetchJobResumeRecruiterCacheKey;
  }

  static showAnonymousCacheKey() {
    return 'jobResumeRecruiter-anonymous' as FetchJobResumeRecruiterCacheKey;
  }

  static showIndexCacheKey() {
    return 'jobResumeRecruiter-index' as FetchJobResumeRecruiterIndexCacheKey;
  }
}
