import {
  FetchJobResumeRecruitersQuery,
  FetchJobResumeRecruitersCountType,
  FetchJobResumeRecruitersPage,
  FetchJobResumeRecruitersPageSize,
  FetchJobResumeRecruitersFilters,
  FetchJobResumeRecruitersSort,
  FetchJobResumeRecruitersIndexQueryName,
  FetchJobResumeRecruitersIndexCountType,
  FetchJobResumeRecruitersIndexPage,
  FetchJobResumeRecruitersIndexPageSize,
  FetchJobResumeRecruitersIndexFilters,
  FetchJobResumeRecruitersIndexSort,
  FetchJobResumeRecruiterQuery,
  FetchJobResumeRecruiterIndexQueryName,
  CreateJobResumeRecruiterError,
  UpdateJobResumeRecruiterError,
  DeleteJobResumeRecruiterError,
  JobResumeRecruiterId,
  JobResumeRecruiterNanoId,
  JobResumeRecruiterName,
  JobResumeRecruiterJobId,
  JobResumeRecruiterJobResumeId,
  JobResumeRecruiterEmail,
  JobResumeRecruiterTitle,
  JobResumeRecruiterPhone,
  JobResumeRecruiterLinkedinUrl,
  JobResumeRecruiterStatus,
  JobResumeRecruiterPrimaryJobFunction,
  JobResumeRecruiterWorkAuthorization,
  JobResumeRecruiterFutureRequirements,
  JobResumeRecruiterProfessionalExperience,
  JobResumeRecruiterDescription,
  MayBeJobResumeRecruiterSearchableAt,
  MayBeJobResumeRecruiterImageId,
  MayBeJobResumeRecruiterStateId,
  MayBeJobResumeRecruiterCityId,
  MayBeJobResumeRecruiterCityIds,
  JobResumeRecruiterPhoneCallAvailability,
  JobResumeRecruiterEmploymentStatus
} from './jobResumeRecruitersTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobResumeRecruitersBffRoutes } from './JobResumeRecruitersBffRoutes';

interface FetchJobResumeRecruitersRequestParams {
  query: FetchJobResumeRecruitersQuery;
  countType?: FetchJobResumeRecruitersCountType;
  page?: FetchJobResumeRecruitersPage;
  pageSize?: FetchJobResumeRecruitersPageSize;
  filters?: FetchJobResumeRecruitersFilters;
  sort?: FetchJobResumeRecruitersSort;
}

interface FetchJobResumeRecruitersIndexRequestParams {
  queryName: FetchJobResumeRecruitersIndexQueryName;
  countType?: FetchJobResumeRecruitersIndexCountType;
  page?: FetchJobResumeRecruitersIndexPage;
  pageSize?: FetchJobResumeRecruitersIndexPageSize;
  filters?: FetchJobResumeRecruitersIndexFilters;
  sort?: FetchJobResumeRecruitersIndexSort;
}

interface FetchJobResumeRecruiterRequestParams {
  query: FetchJobResumeRecruiterQuery;
}

interface FetchJobResumeRecruiterIndexRequestParams {
  queryName: FetchJobResumeRecruiterIndexQueryName;
}

export interface JobResumeRecruiterRequestData {
  name?: JobResumeRecruiterName;
  title?: JobResumeRecruiterTitle;
  phone?: JobResumeRecruiterPhone;
  email?: JobResumeRecruiterEmail;
  imageId?: MayBeJobResumeRecruiterImageId;
  stateId?: MayBeJobResumeRecruiterStateId;
  cityId?: MayBeJobResumeRecruiterCityId;
  linkedinUrl?: JobResumeRecruiterLinkedinUrl;
  status?: JobResumeRecruiterStatus;
  primaryJobFunction?: JobResumeRecruiterPrimaryJobFunction;
  workAuthorization?: JobResumeRecruiterWorkAuthorization;
  futureRequirements?: JobResumeRecruiterFutureRequirements;
  professionalExperience?: JobResumeRecruiterProfessionalExperience;
  description?: JobResumeRecruiterDescription;
  searchableAt?: MayBeJobResumeRecruiterSearchableAt;
  cityIds?: MayBeJobResumeRecruiterCityIds;
  employmentStatus?: JobResumeRecruiterEmploymentStatus;
  phoneCallAvailability?: JobResumeRecruiterPhoneCallAvailability;
}

export interface CreateJobResumeRecruiterRequestData
  extends JobResumeRecruiterRequestData {
  jobId: JobResumeRecruiterJobId;
  jobResumeId?: JobResumeRecruiterJobResumeId;
  nanoId: JobResumeRecruiterNanoId;
}

export interface UpdateJobResumeRecruiterRequestData
  extends JobResumeRecruiterRequestData {
  name?: JobResumeRecruiterName;
}

export interface DeleteJobResumeRecruiterRequestData {
  jobResumeRecruiterNanoId: JobResumeRecruiterNanoId;
}

export interface CreateJobResumeRecruiterErrorResponse {
  message: CreateJobResumeRecruiterError;
}

export interface UpdateJobResumeRecruiterErrorResponse {
  message: UpdateJobResumeRecruiterError;
}

export interface DeleteJobResumeRecruiterErrorResponse {
  message: DeleteJobResumeRecruiterError;
}

export class JobResumeRecruitersBffRequests extends BaseBffRequests {
  static fetchJobResumeRecruiters<T>(
    params: FetchJobResumeRecruitersRequestParams
  ) {
    return this.fetchItems<T>(
      JobResumeRecruitersBffRoutes.fetchJobResumeRecruitersRoute(),
      params
    );
  }

  static fetchJobResumeRecruitersIndex<T>(
    params: FetchJobResumeRecruitersIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      JobResumeRecruitersBffRoutes.fetchJobResumeRecruitersIndexRoute(),
      params
    );
  }

  static fetchJobResumeRecruiter<T>(
    jobResumeRecruiterId: JobResumeRecruiterId,
    params: FetchJobResumeRecruiterRequestParams
  ) {
    return this.fetchItem<T>(
      JobResumeRecruitersBffRoutes.fetchJobResumeRecruiterRoute(
        jobResumeRecruiterId
      ),
      params
    );
  }

  static fetchJobResumeRecruiterIndex<T>(
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId,
    params: FetchJobResumeRecruiterIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobResumeRecruitersBffRoutes.fetchJobResumeRecruiterIndexRoute(
        jobResumeRecruiterNanoId
      ),
      params
    );
  }

  static fetchJobResumeRecruiterByNanoId<T>(
    jobResumeRecruiterNanoId: JobResumeRecruiterNanoId,
    params: FetchJobResumeRecruiterRequestParams
  ) {
    return this.fetchItemByNanoId<T>(
      JobResumeRecruitersBffRoutes.fetchJobResumeRecruiterByNanoIdRoute(
        jobResumeRecruiterNanoId
      ),
      params
    );
  }

  static createJobResumeRecruiter<T>(
    createJobResumeRecruiterData: CreateJobResumeRecruiterRequestData
  ) {
    return this.createItem<T, CreateJobResumeRecruiterRequestData>(
      JobResumeRecruitersBffRoutes.createJobResumeRecruiterRoute(),
      createJobResumeRecruiterData
    );
  }

  static async updateJobResumeRecruiter<T>(
    updateJobResumeRecruiterNanoId: JobResumeRecruiterNanoId,
    updateJobResumeRecruiterData: UpdateJobResumeRecruiterRequestData
  ) {
    return this.updateItem<T, UpdateJobResumeRecruiterRequestData>(
      JobResumeRecruitersBffRoutes.updateJobResumeRecruiterRoute(
        updateJobResumeRecruiterNanoId
      ),
      updateJobResumeRecruiterData
    );
  }

  static async deleteJobResumeRecruiter<T>(
    deleteJobResumeRecruiterNanoId: JobResumeRecruiterNanoId
  ) {
    return this.deleteItem<T>(
      JobResumeRecruitersBffRoutes.deleteJobResumeRecruiterRoute(
        deleteJobResumeRecruiterNanoId
      )
    );
  }
}
