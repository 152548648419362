import { Flex } from '@chakra-ui/react';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { JobId, JobJobTitle, JobName, JobNanoId } from '../../../../jobsTypes';
import { JobCandidateDetailsJobCandidate } from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsBio } from '../ViewJobCandidateDetailsBio';
import { ViewJobCandidateDetailsCandidateHeaderMenu } from '../ViewJobCandidateDetailsCandidateHeaderMenu';
import { ViewJobCandidateDetailsStageTasksManager } from '../ViewJobCandidateDetailsStageTasksManager';
import { RefObject } from 'react';
import { DepartmentNanoId } from '../../../../../departments/departmentsTypes';

interface ViewJobCandidateDetailsHeaderProps {
  requiresCredit: boolean;
  jobCandidate: JobCandidateDetailsJobCandidate;
  jobCandidateNanoId: JobCandidateNanoId;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
  };
  isBioOutsideViewport?: RefObject<HTMLDivElement>;
  isHiringPortalPath?: boolean;
  isBasePath?: boolean;
  isProspectPath?: boolean;
  departmentNanoId?: DepartmentNanoId;
}

function ViewJobCandidateDetailsHeader({
  job,
  jobCandidate,
  requiresCredit,
  jobCandidateNanoId,
  isBioOutsideViewport,
  isHiringPortalPath,
  isProspectPath,
  isBasePath,
  departmentNanoId
}: ViewJobCandidateDetailsHeaderProps) {
  return (
    <Flex
      p={4}
      gap={4}
      justifyContent="space-between"
      flexDir={{ base: 'column', xl: 'row' }}
      bg="white"
    >
      <ViewJobCandidateDetailsBio
        requiresCredit={requiresCredit}
        jobCandidate={jobCandidate}
        isBioOutsideViewport={isBioOutsideViewport}
        job={job}
        isHiringPortalPath={isHiringPortalPath}
        isProspectPath={isProspectPath}
        isBasePath={isBasePath}
        departmentNanoId={departmentNanoId}
      />

      <Flex flexDir="column">
        <ViewJobCandidateDetailsCandidateHeaderMenu
          jobNanoId={job.nanoId}
          jobCandidateNanoId={jobCandidateNanoId}
          isHiringPortalPath={isHiringPortalPath}
          isProspectPath={isProspectPath}
          departmentNanoId={departmentNanoId}
        />

        <ViewJobCandidateDetailsStageTasksManager
          jobCandidateNanoId={jobCandidateNanoId}
          requiresCredit={requiresCredit}
          jobCandidate={jobCandidate}
          jobCandidateDetails={jobCandidate}
          job={job}
          isHiringPortalPath={isHiringPortalPath}
        />
      </Flex>
    </Flex>
  );
}

export default ViewJobCandidateDetailsHeader;
