import {
  FetchImageQuery,
  ImageId,
  FetchImagesQuery,
  FetchImagesCountType,
  FetchImagesPage,
  FetchImagesPageSize,
  FetchImagesFilters,
  FetchImagesSort
} from './imagesTypes';

import { BaseBffRequests } from '../BaseBffRequests';
import { ImagesBffRoutes } from './ImagesBffRoutes';

interface FetchImagesRequestParams {
  query: FetchImagesQuery;
  countType?: FetchImagesCountType;
  page?: FetchImagesPage;
  pageSize?: FetchImagesPageSize;
  filters?: FetchImagesFilters;
  sort?: FetchImagesSort;
}

interface FetchImageRequestParams {
  query: FetchImageQuery;
}

interface CreateImageRequestData {
  fileName: string;
  name: string;
  contentType: string;
  fileSize: number;
}

export class ImagesBffRequests extends BaseBffRequests {
  static fetchImages<T>(params: FetchImagesRequestParams) {
    return this.fetchItems<T>(ImagesBffRoutes.fetchImagesRoute(), params);
  }

  static fetchImage<T>(imageId: ImageId, params: FetchImageRequestParams) {
    return this.fetchItem<T>(ImagesBffRoutes.fetchImageRoute(imageId), params);
  }

  static createImage(createImageData: CreateImageRequestData) {
    return this.createItem<
      { id: string; presignedUrl: string },
      CreateImageRequestData
    >(ImagesBffRoutes.createImageRoute(), createImageData);
  }
}
