import { useFetchJobCandidateByNanoId } from '../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';

import { JobCandidatesCache } from '../../../jobCandidates/JobCandidatesCache';

import {
  JobCandidateNanoId,
  JobCandidateSearchTypeLabels
} from '../../../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../../../jobs/jobsTypes';

import {
  fetchJobCandidateQuery,
  FetchJobCandidateResponse
} from '../../../jobCandidates/queries/fetchJobCandidate.query';

import { ReactNode, useCallback } from 'react';
import { ShareCandidatesProfileProvider } from '../../../../context';
import { useFetchDepartmentByNanoId } from '../../../departments/hooks/useFetchDepartmentByNanoId';
import {
  fetchDepartmentQuery,
  FetchDepartmentResponse
} from '../../../departments/queries/fetchDepartment.query';
import { DepartmentsCache } from '../../../departments/DepartmentsCache';
import {
  DepartmentId,
  DepartmentNanoId,
  FetchDepartmentEnabled
} from '../../../departments/departmentsTypes';
import { ViewJobCandidateDetailsSubmissionProvider } from '../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSubmissionContext';
import { ViewJobCandidateDetailsSendAssessmentProvider } from '../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { SourcingTalentProfilePageContent } from './components/SourcingTalentProfilePageContent';
import { ViewJobCandidateDetailsCandidateDetailsJobCandidate } from '../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsCandidateDetails';
import { ViewJobCandidateDetailsProvider } from '../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsContext';
import { useRouter } from 'next/router';
import { SourcingJobCandidateDetailsRoutes } from '../../SourcingJobCandidateDetailsRoutes';

interface SourcingTalentProfilePageProps {
  departmentNanoId: DepartmentNanoId;
  personNanoId: JobCandidateNanoId;
  children?: ReactNode;
  isBasePath?: boolean;
}

function SourcingTalentProfilePage({
  children,
  departmentNanoId,
  personNanoId,
  isBasePath
}: SourcingTalentProfilePageProps) {
  const router = useRouter();

  const { department, departmentErrorMessage } =
    useFetchDepartmentByNanoId<FetchDepartmentResponse>({
      query: fetchDepartmentQuery,
      departmentNanoId,
      enabled: (departmentNanoId !== 'all') as FetchDepartmentEnabled,
      cacheKey: DepartmentsCache.showDepartmentCacheKey(departmentNanoId)
    });

  const { jobCandidate, jobCandidateIsFetched, jobCandidateErrorMessage } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: personNanoId,
      query: fetchJobCandidateQuery,
      cacheKey: JobCandidatesCache.showCacheKey()
    });

  const requiresCredit =
    (jobCandidate?.searchTypeLabel === JobCandidateSearchTypeLabels.LEAD ||
      jobCandidate?.searchTypeLabel ===
        JobCandidateSearchTypeLabels.PROSPECT) &&
    jobCandidate?.workflowStage?.arrangement === 0;

  const handleRouteToProfile = useCallback(() => {
    if (isBasePath) return;
    const route = SourcingJobCandidateDetailsRoutes.profile(
      departmentNanoId,
      personNanoId
    );
    router.push(route);
  }, [personNanoId, departmentNanoId, router, isBasePath]);

  return (
    <ShareCandidatesProfileProvider
      jobNanoId={jobCandidate?.job?.nanoId as JobNanoId}
    >
      <ViewJobCandidateDetailsProvider>
        <ViewJobCandidateDetailsSubmissionProvider
          jobNanoId={jobCandidate?.job?.nanoId as JobNanoId}
          isHiringPortalPath={false}
          jobCandidateNanoId={personNanoId}
          handleRouteToProfile={handleRouteToProfile}
        >
          <ViewJobCandidateDetailsSendAssessmentProvider
            jobCandidateNanoId={personNanoId}
          >
            <SourcingTalentProfilePageContent
              department={
                department as {
                  nanoId: DepartmentNanoId;
                  id: DepartmentId;
                }
              }
              requiresCredit={requiresCredit}
              isBasePath={isBasePath}
              departmentErrorMessage={departmentErrorMessage}
              departmentNanoId={departmentNanoId}
              jobCandidate={
                jobCandidate as ViewJobCandidateDetailsCandidateDetailsJobCandidate
              }
              jobCandidateErrorMessage={jobCandidateErrorMessage}
              jobCandidateIsFetched={jobCandidateIsFetched}
              personNanoId={personNanoId}
            >
              {children}
            </SourcingTalentProfilePageContent>
          </ViewJobCandidateDetailsSendAssessmentProvider>
        </ViewJobCandidateDetailsSubmissionProvider>
      </ViewJobCandidateDetailsProvider>
    </ShareCandidatesProfileProvider>
  );
}

export default SourcingTalentProfilePage;
