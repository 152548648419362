import {
  useDisclosure,
  Popover,
  PopoverTrigger,
  Flex,
  Portal,
  PopoverContent,
  PlacementWithLogical,
  LayoutProps
} from '@chakra-ui/react';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Button, ButtonProps } from '../Button';
import { Text } from '../Text';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import { PureNextLinkHelper } from '../PureNextLinkHelper';
import { PureButtonHelper } from '../buttons/PureButtonHelper';
import { IsDisabled } from '../../types';

type OptionType = {
  label: string;
  value: string;
};

interface DropdownSelectProps extends ButtonProps {
  options: OptionType[];
  placement?: PlacementWithLogical;
  popoverWidth?: LayoutProps['width'];
  onOptionSelect?: (option: string) => void;
  selectedOption?: string;
  placeholder: string;
  optionRoute?: (option: string) => string;
  updateWithSelectedOption?: boolean;
}

function DropdownSelect({
  w,
  width,
  options,
  onClick,
  placeholder,
  popoverWidth,
  selectedOption,
  onOptionSelect,
  fontWeight = 'medium',
  placement = 'bottom-start',
  optionRoute,
  updateWithSelectedOption,
  ...rest
}: DropdownSelectProps) {
  const [selected, setSelected] = useState(selectedOption);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleOptionSelect = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    setSelected(value);
    onOptionSelect && onOptionSelect(value);
    !!onClick && onClick(e);
    onClose();
  };

  useEffect(() => {
    if (updateWithSelectedOption) {
      setSelected(selectedOption);
    }
  }, [updateWithSelectedOption, selectedOption]);

  const selectedLabel =
    options.find((option) => option.value === selected)?.label || placeholder;

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy placement={placement}>
      <PopoverTrigger>
        <Flex alignItems="stretch" w={w} width={width}>
          <Button
            p={0}
            h="100%"
            w="full"
            minH={6}
            minW={0}
            size="small"
            display="flex"
            alignItems="center"
            onClick={(e) => {
              e.stopPropagation();
              if (!rest?.isDisabled) {
                onToggle();
              }
            }}
            border="none"
            textAlign="left"
            boxShadow="none"
            fontSize="inherit"
            hierarchy="unstyled"
            aria-label="DropdownSelect"
            {...rest}
          >
            <Flex flex={1} gap={1} alignItems="center" fontSize="inherit">
              <Text flex={1} fontWeight={fontWeight}>
                {selectedLabel}
              </Text>
              <ChevronDownIcon fill="gray.500" />
            </Flex>
          </Button>
        </Flex>
      </PopoverTrigger>

      <Portal>
        <PopoverContent maxH={60} overflow="hidden" w={popoverWidth}>
          <Flex flexDir="column" overflow="auto">
            {options.map((option) =>
              optionRoute ? (
                <PureNextLinkHelper
                  type="button"
                  href={optionRoute?.(option.value) || ''}
                  px={2}
                  py={1}
                  h="auto"
                  size="small"
                  color="inherit"
                  textAlign="left"
                  lineHeight={1.5}
                  fontSize="inherit"
                  borderRadius="none"
                  fontWeight="400"
                  hierarchy="unstyled"
                  isDisabled={selected === option.value}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOptionSelect(e as MouseEvent<HTMLButtonElement>);
                  }}
                  value={option.value}
                  key={option.value}
                  sx={{
                    fontWeight: '400 !important'
                  }}
                  _hover={{
                    bg: 'gray.100'
                  }}
                  i18nText={option.label}
                />
              ) : (
                <PureButtonHelper
                  type="button"
                  px={2}
                  py={1}
                  h="auto"
                  size="small"
                  color="inherit"
                  textAlign="left"
                  lineHeight={1.5}
                  fontSize="inherit"
                  borderRadius="none"
                  fontWeight="400"
                  hierarchy="unstyled"
                  disabled={(selected === option.value) as IsDisabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOptionSelect(e as MouseEvent<HTMLButtonElement>);
                  }}
                  value={option.value}
                  key={option.value}
                  sx={{
                    fontWeight: '400 !important'
                  }}
                  _hover={{
                    bg: 'gray.100'
                  }}
                  i18nText={option.label}
                />
              )
            )}
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

export default DropdownSelect;
