import { Box, Flex } from '@chakra-ui/react';
import { useReactHookForm } from '../../../../common/hooks/base/useReactHookForm';
import { SelectTimeZoneFormField } from '../../../../main/common/components/formFields/SelectTimeZoneFormField';
import { Heading } from '../../../Heading';
import { ScheduleHelperDuration } from '../../ScheduleHelper.types';
import { ScheduleHelperCalendar } from '../ScheduleHelperCalendar';
import { ScheduleHelperTimeRange } from '../ScheduleHelperTimeRange';

interface ScheduleHelperDateAndTimeSelectionProps {
  selectDateLabel?: string;
  minSlots?: number;
  maxSlots?: number;
  minDate?: Date | null;
  maxDate?: Date | null;
  duration: ScheduleHelperDuration;
  handleDateSelection: (date: string) => void;
  handleTimeSelection: (timeSlot: string) => void;
  selectedTimeSlots: string[];
  selectedDate: string;
  disableWeekend?: boolean;
}

export default function ScheduleHelperDateAndTimeSelection({
  selectDateLabel,
  maxDate,
  minDate,
  maxSlots,
  duration,
  selectedDate,
  disableWeekend,
  handleDateSelection,
  handleTimeSelection,
  selectedTimeSlots
}: ScheduleHelperDateAndTimeSelectionProps) {
  const { control } = useReactHookForm({
    defaultValues: {
      timeZone: '-08:00'
    }
  });

  return (
    <Flex flexDir="column" gap={6} w="xl">
      <Heading level="h5" color="gray.800">
        {selectDateLabel ?? 'Select date and Time'}{' '}
        <Box as="span" color="gray.600">
          (select{' '}
          <Box as="span" color="gray.800">
            {maxSlots}
          </Box>{' '}
          time slots)
        </Box>
      </Heading>

      <Flex gap={9} flex={1}>
        <Flex flex={1} flexDir="column" gap={5}>
          <ScheduleHelperCalendar
            maxDate={maxDate}
            minDate={minDate}
            selectedDate={selectedDate}
            disableWeekend={disableWeekend}
            selectedTimeSlots={selectedTimeSlots}
            handleDateSelection={handleDateSelection}
          />

          <SelectTimeZoneFormField control={control} errorMessage="" label="" />
        </Flex>

        <ScheduleHelperTimeRange
          maxSlots={maxSlots}
          duration={duration}
          selectedDate={selectedDate}
          selectedTimeSlots={selectedTimeSlots}
          handleTimeSelection={handleTimeSelection}
        />
      </Flex>
    </Flex>
  );
}
