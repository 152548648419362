import {
  FetchJobResumeRecruiterCacheKey,
  FetchJobResumeRecruiterNanoID,
  FetchJobResumeRecruiterQuery,
  FetchJobResumeRecruiterEnabled,
  FetchJobResumeRecruiterError,
  FetchJobResumeRecruiterErrorMessage,
  FetchJobResumeRecruiterIsFetched,
  FetchJobResumeRecruiterIsRefetching,
  FetchJobResumeRecruiterIsLoading,
  FetchJobResumeRecruiterIsPlaceholderData
} from '../../jobResumeRecruitersTypes';

import { useFetchItemByNanoId } from '../../../common/hooks/useFetchItemByNanoId';

import { JobResumeRecruitersBffRequests } from '../../JobResumeRecruitersBffRequests';

interface FetchJobResumeRecruiterByNanoIdOptions<
  FetchJobResumeRecruiterResponseType
> {
  jobResumeRecruiterNanoId: FetchJobResumeRecruiterNanoID;
  query: FetchJobResumeRecruiterQuery;
  cacheKey: FetchJobResumeRecruiterCacheKey;
  enabled?: FetchJobResumeRecruiterEnabled;
  placeholderJobResumeRecruiter?: FetchJobResumeRecruiterResponseType;
  placeholderData?: () => FetchJobResumeRecruiterResponseType;
}

function useFetchJobResumeRecruiterByNanoId<
  FetchJobResumeRecruiterResponseType
>({
  jobResumeRecruiterNanoId,
  query,
  cacheKey,
  enabled,
  placeholderJobResumeRecruiter,
  placeholderData
}: FetchJobResumeRecruiterByNanoIdOptions<FetchJobResumeRecruiterResponseType>) {
  const {
    item,
    itemError,
    itemErrorMessage,
    itemIsFetched,
    itemIsRefetching,
    itemIsLoading,
    itemIsPlaceholderData,
    refetchItem
  } = useFetchItemByNanoId<
    FetchJobResumeRecruiterResponseType,
    FetchJobResumeRecruiterError
  >({
    itemNanoId: jobResumeRecruiterNanoId,
    cacheKey,
    enabled,
    placeholderItem: placeholderJobResumeRecruiter,
    placeholderData,
    queryFn: () =>
      JobResumeRecruitersBffRequests.fetchJobResumeRecruiterByNanoId<FetchJobResumeRecruiterResponseType>(
        jobResumeRecruiterNanoId,
        {
          query
        }
      )
  });

  return {
    jobResumeRecruiter: item,
    jobResumeRecruiterError: itemError,
    jobResumeRecruiterErrorMessage:
      itemErrorMessage as FetchJobResumeRecruiterErrorMessage,
    jobResumeRecruiterIsFetched:
      itemIsFetched as FetchJobResumeRecruiterIsFetched,
    jobResumeRecruiterIsRefetching:
      itemIsRefetching as FetchJobResumeRecruiterIsRefetching,
    jobResumeRecruiterIsLoading:
      itemIsLoading as FetchJobResumeRecruiterIsLoading,
    jobResumeRecruiterIsPlaceholderData:
      itemIsPlaceholderData as FetchJobResumeRecruiterIsPlaceholderData,
    refetchJobResumeRecruiter: refetchItem
  };
}

export default useFetchJobResumeRecruiterByNanoId;
