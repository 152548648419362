import {
  FetchJobScorecardsQuery,
  FetchJobScorecardsCountType,
  FetchJobScorecardsPage,
  FetchJobScorecardsPageSize,
  FetchJobScorecardsFilters,
  FetchJobScorecardsSort,
  FetchJobScorecardsIndexQueryName,
  FetchJobScorecardsIndexCountType,
  FetchJobScorecardsIndexPage,
  FetchJobScorecardsIndexPageSize,
  FetchJobScorecardsIndexFilters,
  FetchJobScorecardsIndexSort,
  FetchJobScorecardQuery,
  FetchJobScorecardIndexQueryName,
  CreateJobScorecardError,
  UpdateJobScorecardError,
  DeleteJobScorecardError,
  JobScorecardId,
  JobScorecardJobId,
  JobScorecardName,
  JobScorecardPositionFit,
  JobScorecardComments,
  JobScorecardUuid,
  JobScorecardJobScorecardQuestions,
  JobScorecardNextStep,
  JobScorecardOverallEvaluation,
  JobScorecardScorecardType,
  JobScorecardJobFunctionId
} from './jobScorecardsTypes';

import { BaseBffRequests } from '../BaseBffRequests';

import { JobScorecardsBffRoutes } from './JobScorecardsBffRoutes';

interface FetchJobScorecardsRequestParams {
  query: FetchJobScorecardsQuery;
  countType?: FetchJobScorecardsCountType;
  page?: FetchJobScorecardsPage;
  pageSize?: FetchJobScorecardsPageSize;
  filters?: FetchJobScorecardsFilters;
  sort?: FetchJobScorecardsSort;
}

interface FetchJobScorecardsIndexRequestParams {
  queryName: FetchJobScorecardsIndexQueryName;
  countType?: FetchJobScorecardsIndexCountType;
  page?: FetchJobScorecardsIndexPage;
  pageSize?: FetchJobScorecardsIndexPageSize;
  filters?: FetchJobScorecardsIndexFilters;
  sort?: FetchJobScorecardsIndexSort;
}

interface FetchJobScorecardRequestParams {
  query: FetchJobScorecardQuery;
}

interface FetchJobScorecardIndexRequestParams {
  queryName: FetchJobScorecardIndexQueryName;
}

export interface JobScorecardRequestData {
  jobId: JobScorecardJobId;
  name: JobScorecardName;
  positionFit: JobScorecardPositionFit;
  comments: JobScorecardComments;
  nextSteps: JobScorecardNextStep;
  overallEvaluation: JobScorecardOverallEvaluation;
  scorecardType: JobScorecardScorecardType;
  jobFunctionId: JobScorecardJobFunctionId;
  jobScorecardQuestions: JobScorecardJobScorecardQuestions;
}

export interface CreateJobScorecardDefaultRequestData
  extends JobScorecardRequestData {
  id?: JobScorecardId;
}

export type CreateJobScorecardRequestData =
  CreateJobScorecardDefaultRequestData;

export type UpdateJobScorecardRequestData = JobScorecardRequestData;

export interface CreateJobScorecardErrorResponse {
  message: CreateJobScorecardError;
}

export interface UpdateJobScorecardErrorResponse {
  message: UpdateJobScorecardError;
}

export interface DeleteJobScorecardErrorResponse {
  message: DeleteJobScorecardError;
}

export class JobScorecardsBffRequests extends BaseBffRequests {
  static fetchJobScorecards<T>(params: FetchJobScorecardsRequestParams) {
    return this.fetchItems<T>(
      JobScorecardsBffRoutes.fetchJobScorecardsRoute(),
      params
    );
  }

  static fetchJobScorecardsIndex<T>(
    params: FetchJobScorecardsIndexRequestParams
  ) {
    return this.fetchItemsIndex<T>(
      JobScorecardsBffRoutes.fetchJobScorecardsIndexRoute(),
      params
    );
  }

  static fetchJobScorecard<T>(
    jobScorecardId: JobScorecardId,
    params: FetchJobScorecardRequestParams
  ) {
    return this.fetchItem<T>(
      JobScorecardsBffRoutes.fetchJobScorecardRoute(jobScorecardId),
      params
    );
  }

  static fetchJobScorecardIndex<T>(
    jobScorecardUuId: JobScorecardUuid,
    params: FetchJobScorecardIndexRequestParams
  ) {
    return this.fetchItemIndex<T>(
      JobScorecardsBffRoutes.fetchJobScorecardIndexRoute(jobScorecardUuId),
      params
    );
  }

  static createJobScorecard<T>(
    createJobScorecardData: CreateJobScorecardRequestData
  ) {
    return this.createItem<T, CreateJobScorecardRequestData>(
      JobScorecardsBffRoutes.createJobScorecardRoute(),
      createJobScorecardData
    );
  }

  static async updateJobScorecard<T>(
    updateJobScorecardId: JobScorecardId,
    updateJobScorecardData: UpdateJobScorecardRequestData
  ) {
    return this.updateItem<T, UpdateJobScorecardRequestData>(
      JobScorecardsBffRoutes.updateJobScorecardRoute(updateJobScorecardId),
      updateJobScorecardData
    );
  }

  static async deleteJobScorecard<T>(deleteJobScorecardId: JobScorecardId) {
    return this.deleteItem<T>(
      JobScorecardsBffRoutes.deleteJobScorecardRoute(deleteJobScorecardId)
    );
  }
}
