import {
  MayBe,
  FieldBrand,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  ItemUpdatedAt
} from '../../../types';
import {
  CertificationId,
  CertificationIds,
  CertificationName,
  CertificationNanoId
} from '../../certifications/certificationsTypes';

import {
  CityId,
  CityName,
  CityLabel,
  CityIds,
  CityCountry,
  CityState
} from '../../cities/citiesTypes';

import {
  CompanyId,
  CompanyIds,
  CompanyName,
  CompanyImage,
  CompanyDescription,
  CompanyFoundedAt,
  CompanyCompanyType,
  CompanyRevenue,
  CompanyIndustry,
  CompanySector,
  CompanySize,
  CompanyEmployeesNumber
} from '../../companies/companiesTypes';
import {
  DepartmentId,
  DepartmentName,
  DepartmentNanoId
} from '../../departments/departmentsTypes';

import {
  DisciplineId,
  DisciplineName
} from '../../disciplines/disciplinesTypes';
import {
  IndustryId,
  IndustryIds,
  IndustryName
} from '../../industries/industriesTypes';

import {
  JobCandidateId,
  JobCandidateNanoId
} from '../../jobCandidates/jobCandidatesTypes';

import {
  JobFunctionId,
  JobFunctionName
} from '../../jobFunctions/jobFunctionsTypes';

import { JobTitleId, JobTitleName } from '../../jobTitles/jobTitlesTypes';

import {
  SkillBadgeId,
  SkillBadgeIds,
  SkillBadgeNanoId,
  SkillBadgeRating,
  SkillBadgeExperience,
  SkillBadgeCertified,
  SkillBadgeSkill,
  SkillBadgeSkillLicense,
  SkillBadgeRequirements,
  SkillBadgeExperienceType
} from '../../skillBadges/skillBadgesTypes';

import { SkillIds } from '../../skills/skillsTypes';
import {
  SpecializationId,
  SpecializationIds,
  SpecializationName,
  SpecializationNanoId
} from '../../specializations/specializationsTypes';

import {
  UserId,
  UserIds,
  UserName,
  UserNanoId,
  UserTitle,
  MayBeUserState,
  MayBeUserImage,
  MayBeUserCity,
  MayBeUserCompany,
  UserFullName,
  UserProvidedRole,
  MayBeUserFirstName,
  MayBeUserLastName
} from '../../users/usersTypes';

import {
  WorkflowStageId,
  WorkflowStageNanoId,
  WorkflowStageName,
  WorkflowStageArrangement,
  WorkflowStageColor,
  WorkflowStageWorkflowStageTasks
} from '../../workflowStages/workflowStagesTypes';

export type JobId = FieldBrand<ItemID, 'JobId'>;
export type JobUUID = FieldBrand<ItemUUID, 'JobUUID'>;
export type JobNanoId = FieldBrand<ItemNanoId, 'JobNanoId'>;
export type JobCategory = FieldBrand<string, 'job-applied' | 'new-job'>;
export type MayBeJobNanoId = MayBe<JobNanoId>;
export type JobName = FieldBrand<string, 'JobName'>;
export type JobDepartmentKey = FieldBrand<string, 'JobDepartmentKey'>;
export type JobPrimaryJobFunction = FieldBrand<string, 'JobPrimaryJobFunction'>;
export type JobDescription = FieldBrand<string, 'JobDescription'>;
export type JobUserJobId = FieldBrand<string, 'JobUserJobId'>;
export type JobWorkplace = FieldBrand<string, 'JobWorkplace'>;
export type JobWorkAuthorization = FieldBrand<string, 'JobWorkAuthorization'>;
export type JobEmployment = FieldBrand<string, 'JobEmployment'>;
export type JobBaseSalary = FieldBrand<string, 'JobBaseSalary'>;
export type JobExperience = FieldBrand<string, 'JobExperience'>;
export type JobSeniorityLevel = FieldBrand<string, 'JobSeniorityLevel'>;
export type JobGeography = FieldBrand<string, 'JobGeography'>;
export type JobNumberOfOpenings = FieldBrand<string, 'JobNumberOfOpenings'>;
export type JobNotes = FieldBrand<string, 'JobNotes'>;
export type JobSkillIds = SkillIds;
export type JobUserId = FieldBrand<UserId, 'JobUserId'>;
export type JobUser = FieldBrand<
  {
    id: UserId;
    name: UserName;
  },
  'JobUser'
>;
export type JobJobTitleId = FieldBrand<JobTitleId, 'JobJobTitleId'>;
export type JobJobTitle = FieldBrand<
  { id: JobTitleId; name: JobTitleName },
  'JobJobTitle'
>;
export type JobDepartmentId = FieldBrand<DepartmentId, 'JobDepartmentId'>;
export type JobDepartment = FieldBrand<
  { id: DepartmentId; name: DepartmentName; nanoId: DepartmentNanoId },
  'JobDepartment'
>;
export type JobDisciplineId = FieldBrand<DisciplineId, 'JobDisciplineId'>;
export type JobDiscipline = FieldBrand<
  { id: DisciplineId; name: DisciplineName },
  'JobDiscipline'
>;
export type JobJobFunctionId = FieldBrand<JobFunctionId, 'JobJobFunctionId'>;
export type JobJobFunction = FieldBrand<
  { id: JobFunctionId; name: JobFunctionName },
  'JobJobFunction'
>;
export type JobCityId = FieldBrand<CityId, 'JobCityId'>;
export type JobCityName = FieldBrand<CityName, 'JobCityName'>;
export type JobCityLabel = FieldBrand<CityLabel, 'JobCityLabel'>;
export type JobCityState = FieldBrand<CityState, 'JobCityState'>;
export type JobCityCountry = FieldBrand<CityCountry, 'JobCityCountry'>;
export type MayBeJobCityId = MayBe<JobCityId>;
export type JobCity = FieldBrand<
  {
    id: CityId;
    name: CityName;
    label: CityLabel;
    state: CityState;
    country: CityCountry;
  },
  'JobCity'
>;
export type MayBeJobCity = MayBe<JobCity>;
export type JobCityIds = FieldBrand<CityIds, 'JobCityIds'>;
export type MayBeJobCityIds = MayBe<JobCityIds>;
export type JobCities = FieldBrand<
  {
    id: CityId;
    name: CityName;
    label: CityLabel;
  }[],
  'JobCities'
>;
export type MayBeJobCities = MayBe<JobCities>;
export type JobHiringManagerId = FieldBrand<UserId, 'JobHiringManagerId'>;
export type JobHiringManagerName = FieldBrand<UserName, 'JobHiringManagerName'>;
export type MayBeJobHiringManagerId = MayBe<JobHiringManagerId>;
export type JobHiringManager = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: MayBeUserImage;
    firstName: MayBeUserFirstName;
    lastName: MayBeUserLastName;
    company: MayBeUserCompany;
  },
  'JobHiringManager'
>;
export type MayBeJobHiringManager = MayBe<JobHiringManager>;
export type JobRecruitingCoordinatorId = FieldBrand<
  UserId,
  'JobRecruitingCoordinatorId'
>;
export type MayBeJobRecruitingCoordinatorId = MayBe<JobRecruitingCoordinatorId>;
export type JobRecruitingCoordinator = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: MayBeUserImage;
    company: MayBeUserCompany;
  },
  'JobRecruitingCoordinator'
>;
export type MayBeJobRecruitingCoordinator = MayBe<JobRecruitingCoordinator>;
export type JobRecruiterId = FieldBrand<UserId, 'JobRecruiterId'>;
export type MayBeJobRecruiterId = MayBe<JobRecruiterId>;
export type JobRecruiter = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    fullName: UserFullName;
    title: UserTitle;
    image: MayBeUserImage;
    state: MayBeUserState;
    city: MayBeUserCity;
    company: MayBeUserCompany;
    providedRole: UserProvidedRole;
  },
  'JobRecruiter'
>;
export type MayBeJobRecruiter = MayBe<JobRecruiter>;
export type JobCompanyId = FieldBrand<CompanyId, 'JobCompanyId'>;
export type MayBeJobCompanyId = MayBe<JobCompanyId>;
export type JobCompany = FieldBrand<
  {
    id: CompanyId;
    name: CompanyName;
    image: CompanyImage;
    description: CompanyDescription;
    foundedAt: CompanyFoundedAt;
    companyType: CompanyCompanyType;
    revenue: CompanyRevenue;
    industry: CompanyIndustry;
    sector: CompanySector;
    size: CompanySize;
    employeesNumber: CompanyEmployeesNumber;
  },
  'JobCompany'
>;
export type MayBeJobCompany = MayBe<JobCompany>;
export type JobInterviewerIds = FieldBrand<UserIds, 'JobInterviewerIds'>;
export type JobInterviewers = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: MayBeUserImage;
    company: MayBeUserCompany;
  }[],
  'JobInterviewers'
>;
export type JobCompanyIds = FieldBrand<CompanyIds, 'JobCompanyIds'>;
export type JobCompanies = FieldBrand<
  {
    id: CompanyId;
    name: CompanyName;
    image: CompanyImage;
  }[],
  'JobCompanies'
>;
export type JobIndustryIds = FieldBrand<IndustryIds, 'JobIndustryIds'>;
export type JobIndustries = FieldBrand<
  { id: IndustryId; name: IndustryName }[],
  'JobIndustries'
>;
export type JobSkillBadgeIds = FieldBrand<SkillBadgeIds, 'JobSkillIds'>;
export type JobSkillBadges = FieldBrand<
  {
    id: SkillBadgeId;
    nanoId: SkillBadgeNanoId;
    rating: SkillBadgeRating;
    experience: SkillBadgeExperience;
    certified: SkillBadgeCertified;
    skill: SkillBadgeSkill;
    skillLicense: SkillBadgeSkillLicense;
    requirements: SkillBadgeRequirements;
    experienceType: SkillBadgeExperienceType;
  }[],
  'JobSkillBadges'
>;
export type JobWorkflowStages = FieldBrand<
  {
    id: WorkflowStageId;
    nanoId: WorkflowStageNanoId;
    name: WorkflowStageName;
    arrangement: WorkflowStageArrangement;
    color: WorkflowStageColor;
    workflowStageTasks: WorkflowStageWorkflowStageTasks;
  }[],
  'JobWorkflowStages'
>;
export type JobCreatedAt = FieldBrand<ItemCreatedAt, 'JobCreatedAt'>;
export type JobUpdatedAt = FieldBrand<ItemUpdatedAt, 'JobUpdatedAt'>;
export type JobJobCandidates = FieldBrand<
  {
    id: JobCandidateId;
    nanoId: JobCandidateNanoId;
  }[],
  'JobJobCandidates'
>;
export type JobStudyField = FieldBrand<string, 'JobStudyField'>;
export type JobDegree = FieldBrand<string, 'JobDegree'>;
export type JobCertificationIds = FieldBrand<
  CertificationIds,
  'JobCertificationIds'
>;
export type JobCertifications = FieldBrand<
  {
    id: CertificationId;
    name: CertificationName;
    nanoId: CertificationNanoId;
  }[],
  'JobCertifications'
>;
export type JobSpecializationIds = FieldBrand<
  SpecializationIds,
  'JobSpecializationIds'
>;
export type JobSpecializations = FieldBrand<
  {
    id: SpecializationId;
    name: SpecializationName;
    nanoId: SpecializationNanoId;
  }[],
  'JobSpecializations'
>;
export type JobPaidTimeOff = FieldBrand<string, 'JobPaidTimeOff'>;

export const enum JobStatuses {
  NONE = 'none',
  PUBLISHED = 'published',
  CLOSED = 'closed',
  CANCELLED = 'canceled',
  DRAFT = 'draft',
  PAUSED = 'paused'
}
export type JobStatus = FieldBrand<JobStatuses, 'JobStatus'>;

export const enum JobPriorities {
  NONE = 'none',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low'
}

export const JobPriorityColorMapper = {
  [JobPriorities.NONE]: 'gray.500',
  [JobPriorities.HIGH]: 'red.500',
  [JobPriorities.MEDIUM]: 'yellow.500',
  [JobPriorities.LOW]: 'primary.500'
};

export type JobPriorityColorMapperKey = keyof typeof JobPriorityColorMapper;

export type JobPriority = FieldBrand<JobPriorities, 'JobPriority'>;

export const enum JobFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  JOB_TITLE_ID = 'jobTitleId',
  JOB_TITLE = 'jobTitle',
  JOB_FUNCTION_ID = 'jobFunctionId',
  JOB_FUNCTION = 'jobFunction',
  DISCIPLINE_ID = 'disciplineId',
  DISCIPLINE = 'discipline',
  DEPARTMENT_ID = 'departmentId',
  DEPARTMENT = 'department',
  DEPARTMENT_KEY = 'departmentKey',
  DESCRIPTION = 'description',
  USER_JOB_ID = 'userJobId',
  WORKPLACE = 'workplace',
  WORK_AUTHORIZATION = 'workAuthorization',
  EMPLOYMENT = 'employment',
  ARCHIVED_AT = 'archivedAt',
  BASE_SALARY = 'baseSalary',
  EXPERIENCE = 'experience',
  SENIORITY_LEVEL = 'seniorityLevel',
  NUMBER_OF_OPENINGS = 'numberOfOpenings',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  USER_ID = 'userId',
  USER = 'user',
  CITY_ID = 'cityId',
  CITY = 'city',
  GEOGRAPHY = 'geography',
  NOTES = 'notes',
  SKILL_IDS = 'skillIds',
  HIRING_MANAGER_ID = 'hiringManagerId',
  HIRING_MANAGER = 'hiringManager',
  RECRUITING_COORDINATOR_ID = 'recruitingCoordinatorId',
  RECRUITING_COORDINATOR = 'recruitingCoordinator',
  INTERVIEWER_IDS = 'interviewerIds',
  INTERVIEWERS = 'interviewers',
  COMPANY_IDS = 'companyIds',
  COMPANIES = 'companies',
  CITY_IDS = 'cityIds',
  CITIES = 'cities',
  SKILL_BADGE_IDS = 'skillBadgeIds',
  SKILL_BADGES = 'skillBadges',
  RECRUITER_ID = 'recruiterId',
  RECRUITER = 'recruiter',
  WORKFLOW_STAGES = 'workflowStages',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  JOB_CANDIDATES = 'jobCandidates',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  TASK_NAME = 'taskName', // sandbox
  TASK_TYPE = 'taskType', // sandbox
  TASK_CANDIDATE = 'taskCandidate', // sandbox
  TASK_APPLICATION = 'taskApplication', // sandbox
  ASSIGN_TASK = 'assignTask', // sandbox
  TASK_DUE_DATE = 'taskDueDate', // sandbox
  PRIMARY_JOB_FUNCTION = 'primaryJobFunction',
  CERTIFICATION_IDS = 'certificationIds',
  CERTIFICATIONS = 'certifications',
  STUDY_FIELD = 'studyField',
  DEGREE = 'degree',
  PAID_TIME_OFF = 'paidTimeOff',
  SPECIALIZATION_IDS = 'specializationIds',
  SPECIALIZATIONS = 'specializations',
  INDUSTRIES = 'industries',
  INDUSTRY_IDS = 'industryIds',
  STATUS = 'status',
  JOB_CANDIDATES_COUNT = 'jobCandidatesCount',
  CANDIDATE_SHARES_COUNT = 'candidateSharesCount',
  CANDIDATE_SHARE_RECIPIENT_IDS = 'candidateShareRecipientIds',
  CANDIDATE_SUBMISSION_RECIPIENT_IDS = 'candidateSubmissionRecipientIds',
  JOB_PRIORITY = 'jobPriority',
  MY = 'my'
}

export const enum JobsCityFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  LABEL = 'label',
  STATE = 'state',
  COUNTRY = 'country'
}

export const enum JobsHiringManagerFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name'
}
