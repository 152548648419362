import { Flex, HStack, StackDivider } from '@chakra-ui/react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { ViewJobCandidateDetailsJobHiringTeam } from '../../../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobHiringTeam';
import { renderJobTitle } from '../../../../../../../jobs/utils/renderJobTitle';
import { CareerSiteApplyProfilePageJob } from '../../../../components/CareerSiteApplyProfilePage/CareerSiteApplyProfilePage.types';
import { PureButtonWithRefHelper } from '../../../../../../../../helpers/buttons/PureButtonWithRefHelper';
import { NextLinkHelper } from '../../../../../../../../helpers/NextLinkHelper';
import { JobsRoutes } from '../../../../../../../jobs/JobsRoutes';
import { Text } from '../../../../../../../../helpers/Text';
import { RelativeDateHelper } from '../../../../../../../../helpers/RelativeDateHelper';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import { JobsIcon } from '../../../../../../../../icons/JobsIcon';
import { JobLocationHelper } from '../../../../../../../jobs/helpers/JobLocationHelper';
import { renderJobEmployment } from '../../../../../../../jobs/utils/renderJobEmployment';
import { JobPriorityDropdown } from '../../../../../../../updates/components/JobPriorityDropdown';

interface CareerSiteApplyProfilePageJobHeaderProps {
  job: CareerSiteApplyProfilePageJob;
}

export default function CareerSiteApplyProfilePageJobHeader({
  job
}: CareerSiteApplyProfilePageJobHeaderProps) {
  return (
    <HStack pb={4} spacing={4} divider={<StackDivider />} flexWrap="wrap">
      <Heading level="h2" fontSize="md" fontWeight="semibold" lineHeight={1.5}>
        {renderJobTitle(job)}
      </Heading>

      <Flex gap={2} alignItems="center">
        <Text>Job Priority</Text>
        <JobPriorityDropdown priority="high" />
      </Flex>

      <JobLocationHelper job={job} withIcon />

      <HStack spacing={2}>
        <JobsIcon w="20px" h="20px" color="gray.500" />

        <Text>{renderJobEmployment(job.employment) || '-'}</Text>
      </HStack>

      <HStack spacing={2}>
        <ClockIcon w="20px" h="20px" color="gray.500" />

        <Text>
          <RelativeDateHelper date={job.createdAt as string} />
        </Text>
      </HStack>

      <NextLinkHelper href={JobsRoutes.viewDetails(job.nanoId)} passHref>
        <PureButtonWithRefHelper
          as="a"
          size="medium"
          hierarchy="link"
          i18nText="Job Details"
          _hover={{ textDecoration: 'none' }}
        />
      </NextLinkHelper>

      <ViewJobCandidateDetailsJobHiringTeam />
    </HStack>
  );
}
