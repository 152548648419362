import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback
} from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/nextjs';

import { ErrorMessage } from '../../../types';

import { FetchCurrentUserIndexResponse } from '../../../main/users/queries/fetchCurrentUserIndex.query';

import { AuthenticatedUser } from './AuthenticatedUser';
import { AnonymousUser } from './AnonymousUser';

import { useFetchCurrentUser } from '../useFetchCurrentUser';

import { AuthRoutes } from '../../AuthRoutes';

import { CurrentUserInfo } from './useAuth.types';

interface AuthContextOptions {
  authFetched: boolean;
  authErrorMessage: ErrorMessage | null;
  currentUser: AuthenticatedUser | AnonymousUser;
  isAuthenticated: boolean;
  currentUserRefetch: () => Promise<unknown>;
  authenticate: (updatedUser: CurrentUserInfo) => void;
  deAuthenticate: () => void;
}

const AuthContext = createContext<AuthContextOptions>({
  authFetched: false,
  authErrorMessage: null,
  currentUser: new AnonymousUser(),
  isAuthenticated: false,
  currentUserRefetch: () => {
    throw new Error('useAuth currentUserRefetch should be initialized');
  },
  authenticate: (updatedUser) => {
    const errorMessage = 'useAuth authenticate should be initialized';
    console.error(errorMessage, { updatedUser });
    throw new Error('useAuth authenticate should be initialized');
  },
  deAuthenticate: () => {
    const errorMessage = 'useAuth deAuthenticate should be initialized';
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
});

interface AuthProviderProps {
  children: ReactNode;
  ssrCurrentUser: FetchCurrentUserIndexResponse | null;
}

export function AuthProvider({ children, ssrCurrentUser }: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<
    AuthenticatedUser | AnonymousUser
  >(
    ssrCurrentUser?.uuid
      ? new AuthenticatedUser(ssrCurrentUser)
      : new AnonymousUser()
  );
  const [authFetched, setAuthFetched] = useState<boolean>(
    !!ssrCurrentUser?.uuid
  );
  const [authErrorMessage, setAuthErrorMessage] = useState<ErrorMessage | null>(
    null
  );

  const router = useRouter();
  const queryClient = useQueryClient();

  // const isAuthRoute =
  //   new RegExp(`^/${AuthRoutes.signIn()}`).test(router.pathname) ||
  //   new RegExp(`^/${AuthRoutes.signUp()}`).test(router.pathname) ||
  //   new RegExp(`^/${MainRoutes.redirect()}`).test(router.pathname) ||
  //   new RegExp(`^/${AuthRoutes.resetPassword()}`).test(router.pathname) ||
  //   new RegExp(`^/${AuthRoutes.forgotPassword()}`).test(router.pathname) ||
  //   new RegExp(`^/${AuthRoutes.securityCheck()}`).test(router.pathname) ||
  //   new RegExp(`^/${AuthRoutes.magicLink()}`).test(router.pathname) ||
  //   new RegExp(`^/${AuthRoutes.createProfile()}`).test(router.pathname) ||
  //   new RegExp(`^/${AuthRoutes.acceptInvitation()}`).test(
  //     router.pathname.replace('/[slug]', '')
  //   ) ||
  //   new RegExp(`^/${CareerSiteRoutes.index()}`).test(router.pathname);

  const isAuthRoute =
    /^\/sign-in/.test(router.pathname) ||
    /^\/sign-up/.test(router.pathname) ||
    /^\/confirm-email/.test(router.pathname) ||
    /^\/confirm-email/.test(router.pathname.replace('/[email]', '')) ||
    /^\/redirect/.test(router.pathname) ||
    /^\/reset-password/.test(router.pathname) ||
    /^\/forgot-password/.test(router.pathname) ||
    /^\/security-check/.test(router.pathname) ||
    /^\/reset-password/.test(router.pathname) ||
    /^\/magic-link/.test(router.pathname) ||
    /^\/review-submissions\/\[slug]$/.test(router.pathname) ||
    /^\/career-site\/jobs\/\[jobNanoId]$/.test(router.pathname) ||
    /^\/career-site\/assessment\/\[jobNanoId]$/.test(router.pathname) ||
    /^\/career-site\/jobs\/\[jobNanoId]\/confirm-email\/\[email]$/.test(
      router.pathname
    ) ||
    /^\/create-profile/.test(router.pathname) ||
    /^\/create-profile/.test(router.pathname.replace('/[slug]', '')) ||
    /^\/accept-invitation/.test(router.pathname.replace('/[slug]', ''));

  // /^\/career-site/.test(router.pathname) ||
  //    /^\/welcome-back/.test(router.pathname) ||

  const { currentUserErrorMessage, currentUserRefetch } = useFetchCurrentUser({
    placeholderCurrentUser: ssrCurrentUser || undefined,
    enabled: !isAuthRoute,
    onSuccess: (loadedCurrentUser) => {
      if (loadedCurrentUser) {
        setAuthErrorMessage(null);
        setCurrentUser(
          loadedCurrentUser
            ? new AuthenticatedUser(loadedCurrentUser)
            : new AnonymousUser()
        );
        if (loadedCurrentUser?.nanoId) {
          Sentry.configureScope((scope) =>
            scope.setUser({
              uuid: loadedCurrentUser.uuid,
              nanoId: loadedCurrentUser.nanoId
            })
          );
        }
      }
      setAuthFetched(true);
    },
    onError: async (err) => {
      if (err?.request?.status === 401) {
        setCurrentUser(new AnonymousUser());
        queryClient.clear();
        if (!isAuthRoute) {
          await router.push(AuthRoutes.signIn());
        }
      }
      setAuthErrorMessage(err?.message);
      setAuthFetched(true);
    }
  });

  const authContext = {
    authFetched,
    authErrorMessage: authErrorMessage || currentUserErrorMessage,
    currentUser,
    isAuthenticated: !!currentUser,
    currentUserRefetch,
    authenticate: useCallback<(updatedUser: CurrentUserInfo) => void>(
      (updatedUser) => {
        setAuthFetched(true);
        setCurrentUser(
          updatedUser ? new AuthenticatedUser(updatedUser) : new AnonymousUser()
        );
      },
      [setAuthFetched, setCurrentUser]
    ),
    deAuthenticate: useCallback<() => void>(() => {
      setAuthFetched(true);
      setCurrentUser(new AnonymousUser());
    }, [setAuthFetched, setCurrentUser])
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export const useCurrentUser = () => {
  const context = useContext(AuthContext);

  return context.currentUser;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
