import { useRouter } from 'next/router';
import { ReactNode, useCallback } from 'react';
import { ShareCandidatesProfileProvider } from '../../../../../../context';
import { JobCandidateNanoId } from '../../../../../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../../../../jobsTypes';
import { ViewJobCandidateDetailsProvider } from '../../contexts/ViewJobCandidateDetailsContext';
import { ViewJobCandidateDetailsSendAssessmentProvider } from '../../contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { ViewJobCandidateDetailsSubmissionProvider } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { ViewJobCandidateDetailsPageLayoutContent } from '../ViewJobCandidateDetailsPageLayoutContent';
import { HiringPortalJobCandidateDetailsRoutes } from '../../../../../hiringPortal/HiringPortalJobCandidateDetailsRoutes';
import { PipelineKanbanRoutes } from '../../../../../pipeline/PipelineKanbanRoutes';

interface ViewJobCandidateDetailsPageLayoutProps {
  children: ReactNode;
  jobNanoId: JobNanoId;
  jobCandidateNanoId: JobCandidateNanoId;
  isHiringPortalPath?: boolean;
}

export default function ViewJobCandidateDetailsPageLayout({
  children,
  jobNanoId,
  jobCandidateNanoId,
  isHiringPortalPath = false
}: ViewJobCandidateDetailsPageLayoutProps) {
  const router = useRouter();

  const isBasePath =
    router.pathname ===
      '/pipeline/kanban/view/[jobNanoId]/candidates/[jobCandidateNanoId]' ||
    router.pathname ===
      '/pipeline/kanban/view/[jobNanoId]/candidates/[jobCandidateNanoId]/submission' ||
    router.pathname ===
      '/pipeline/kanban/view/[jobNanoId]/candidates/[jobCandidateNanoId]/profile' ||
    router.pathname ===
      '/hiring-portal/candidates/[jobNanoId]/[jobCandidateNanoId]' ||
    router.pathname ===
      '/hiring-portal/candidates/[jobNanoId]/[jobCandidateNanoId]/profile';

  const openSubmit =
    router.pathname ===
    '/pipeline/kanban/view/[jobNanoId]/candidates/[jobCandidateNanoId]/submission';

  const handleRouteToProfile = useCallback(() => {
    if (isBasePath) return;
    const route = isHiringPortalPath
      ? HiringPortalJobCandidateDetailsRoutes.profile(
          jobNanoId,
          jobCandidateNanoId
        )
      : PipelineKanbanRoutes.viewCandidateDetailsProfile(
          jobNanoId,
          jobCandidateNanoId
        );
    router.push(route);
  }, [jobCandidateNanoId, jobNanoId, router, isHiringPortalPath, isBasePath]);

  const handleRemoveOpenSubmitQuery = useCallback(() => {
    const pathname = isHiringPortalPath
      ? `/hiring-portal/candidates/${jobNanoId}/${jobCandidateNanoId}`
      : `/pipeline/kanban/view/${jobNanoId}/candidates/${jobCandidateNanoId}`;
    router.replace(pathname);
  }, [router, jobCandidateNanoId, jobNanoId, isHiringPortalPath]);

  return (
    <ShareCandidatesProfileProvider jobNanoId={jobNanoId}>
      <ViewJobCandidateDetailsProvider>
        <ViewJobCandidateDetailsSubmissionProvider
          jobNanoId={jobNanoId}
          isSubmitProfileOpen={!!openSubmit}
          isHiringPortalPath={isHiringPortalPath}
          jobCandidateNanoId={jobCandidateNanoId}
          onRemoveOpenSubmitFromQuery={handleRemoveOpenSubmitQuery}
          handleRouteToProfile={handleRouteToProfile}
        >
          <ViewJobCandidateDetailsSendAssessmentProvider
            jobCandidateNanoId={jobCandidateNanoId}
          >
            <ViewJobCandidateDetailsPageLayoutContent
              jobCandidateNanoId={jobCandidateNanoId}
              isHiringPortalPath={isHiringPortalPath}
              jobNanoId={jobNanoId}
              isBasePath={isBasePath}
            >
              {children}
            </ViewJobCandidateDetailsPageLayoutContent>
          </ViewJobCandidateDetailsSendAssessmentProvider>
        </ViewJobCandidateDetailsSubmissionProvider>
      </ViewJobCandidateDetailsProvider>
    </ShareCandidatesProfileProvider>
  );
}
