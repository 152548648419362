import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchJobScorecardsQuery,
  FetchJobScorecardsCacheKey,
  FetchJobScorecardsTotalCount,
  FetchJobScorecardsPage,
  FetchJobScorecardsPageSize,
  FetchJobScorecardsError,
  FetchJobScorecardsErrorMessage,
  FetchJobScorecardsIsLoading,
  FetchJobScorecardsIsFetched,
  FetchJobScorecardsIsPlaceholderData,
  FetchJobScorecardsEnabled,
  FetchJobScorecardsPrefetchNextPage,
  FetchJobScorecardsRefetchInterval,
  FetchJobScorecardsCountType,
  FetchJobScorecardsFilters,
  FetchJobScorecardsSort,
  FetchJobScorecardID,
  FetchJobScorecardQuery,
  FetchJobScorecardCacheKey
} from '../../jobScorecardsTypes';

import { JobScorecardsBffRequests } from '../../JobScorecardsBffRequests';

interface PaginatedJobScorecardsBaseOptions<
  PaginatedJobScorecardsResponseType
> {
  cacheKey: FetchJobScorecardsCacheKey;
  query: FetchJobScorecardsQuery;
  countType?: FetchJobScorecardsCountType;
  initialPage?: FetchJobScorecardsPage;
  initialPageSize?: FetchJobScorecardsPageSize;
  initialFilters?: FetchJobScorecardsFilters;
  initialSort?: FetchJobScorecardsSort;
  enabled?: FetchJobScorecardsEnabled;
  prefetchNextPage?: FetchJobScorecardsPrefetchNextPage;
  refetchInterval?: FetchJobScorecardsRefetchInterval;
  placeholderJobScorecards?: PaginatedJobScorecardsResponseType[];
  placeholderJobScorecardsCount?: FetchJobScorecardsTotalCount;
  placeholderData?: () => PaginatedJobScorecardsResponse<PaginatedJobScorecardsResponseType>;
}

interface PaginatedJobScorecardsOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedJobScorecardsOptionsWithItem {
  fetchItemCacheKey: FetchJobScorecardCacheKey;
  itemQuery: FetchJobScorecardQuery;
}

type PaginatedJobScorecardsOptions<PaginatedJobScorecardsResponseType> =
  PaginatedJobScorecardsBaseOptions<PaginatedJobScorecardsResponseType> &
    (
      | PaginatedJobScorecardsOptionsWithoutItem
      | PaginatedJobScorecardsOptionsWithItem
    );

interface PaginatedJobScorecardsResponse<PaginatedJobScorecardsResponseType> {
  data: PaginatedJobScorecardsResponseType[];
  totalCount: FetchJobScorecardsTotalCount;
}

function usePaginatedJobScorecards<PaginatedJobScorecardsResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  refetchInterval,
  placeholderJobScorecards,
  placeholderJobScorecardsCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery
}: PaginatedJobScorecardsOptions<PaginatedJobScorecardsResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedJobScorecardsResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    refetchInterval,
    placeholderItems: placeholderJobScorecards,
    placeholderItemsCount: placeholderJobScorecardsCount,
    placeholderData,
    queryFn: (params) =>
      JobScorecardsBffRequests.fetchJobScorecards<PaginatedJobScorecardsResponseType>(
        {
          query,
          countType,
          page: params.page as FetchJobScorecardsPage,
          pageSize: params.pageSize as FetchJobScorecardsPageSize,
          filters: params.filters as FetchJobScorecardsFilters,
          sort: params.sort as FetchJobScorecardsSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            JobScorecardsBffRequests.fetchJobScorecard(
              itemId as FetchJobScorecardID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    jobScorecards: items as PaginatedJobScorecardsResponseType[],
    jobScorecardsTotalCount: itemsTotalCount as FetchJobScorecardsTotalCount,
    jobScorecardsError: itemsError as FetchJobScorecardsError,
    jobScorecardsErrorMessage:
      itemsErrorMessage as FetchJobScorecardsErrorMessage,
    jobScorecardsIsLoading: itemsIsLoading as FetchJobScorecardsIsLoading,
    jobScorecardsIsFetched: itemsIsFetched as FetchJobScorecardsIsFetched,
    jobScorecardsIsPlaceholderData:
      itemsIsPlaceholderData as FetchJobScorecardsIsPlaceholderData,
    jobScorecardsCurrentPage: itemsCurrentPage as FetchJobScorecardsPage,
    jobScorecardsCurrentPageSize:
      itemsCurrentPageSize as FetchJobScorecardsPageSize,
    jobScorecardsCurrentFilters:
      itemsCurrentFilters as FetchJobScorecardsFilters,
    jobScorecardsCurrentSort: itemsCurrentSort as FetchJobScorecardsSort,
    fetchJobScorecards: fetchItems,
    paginateJobScorecards: paginateItems,
    changeJobScorecardsPageSize: changeItemsPageSize,
    filterJobScorecards: filterItems,
    changeJobScorecardsFilters: changeItemsFilters,
    clearJobScorecardsFilters: clearItemsFilters,
    sortJobScorecards: sortItems,
    prefetchJobScorecards: prefetchItems,
    prefetchJobScorecard: prefetchItem
  };
}

export default usePaginatedJobScorecards;
