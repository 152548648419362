import { Flex, HStack, StackDivider, Tag } from '@chakra-ui/react';
import { AvatarHelper } from '../../../../../../../../helpers/AvatarHelper';
import { CareerSiteApplyProfilePageJobResumeRecruiter } from '../../../../components/CareerSiteApplyProfilePage/CareerSiteApplyProfilePage.types';
import { Heading } from '../../../../../../../../helpers/Heading';
import startCase from 'lodash/startCase';
import { LinkedInIcon } from '../../../../../../../../icons/LinkedInIcon';
import { NextLinkHelper } from '../../../../../../../../helpers/NextLinkHelper';
import { renderJobResumeRecruiterStatus } from '../../../../../../../jobResumeRecruiters/utils/renderJobResumeRecruiterStatus';
import { Text } from '../../../../../../../../helpers/Text';
import { renderJobResumeRecruiterStatusColor } from '../../../../../../../jobResumeRecruiters/utils/renderJobResumeRecruiterStatusColor';
import { JobsIcon } from '../../../../../../../../icons/JobsIcon';
import { LocationIcon } from '../../../../../../../../icons/LocationIcon';

interface CareerSiteApplyProfilePageBioProps {
  jobResumeRecruiter: CareerSiteApplyProfilePageJobResumeRecruiter;
}

export default function CareerSiteApplyProfilePageBio({
  jobResumeRecruiter
}: CareerSiteApplyProfilePageBioProps) {
  return (
    <Flex p={4} gap={4} flexDir="column" bg="white">
      <HStack spacing={4}>
        <AvatarHelper h={14} w={14} image={jobResumeRecruiter.image} />

        <HStack spacing={3}>
          <Heading
            as="h1"
            flex={1}
            level="h2"
            noOfLines={1}
            color="gray.900"
            wordBreak="break-all"
          >
            {startCase(jobResumeRecruiter.name)}
          </Heading>

          {jobResumeRecruiter.linkedinUrl && (
            <NextLinkHelper href={jobResumeRecruiter.linkedinUrl} passHref>
              <Flex
                as="a"
                w={5}
                h={5}
                bg="#069"
                alignItems="center"
                borderRadius="full"
                justifyContent="center"
              >
                <LinkedInIcon borderRadius="full" w={3.5} h={3.5} />
              </Flex>
            </NextLinkHelper>
          )}

          {jobResumeRecruiter.status && (
            <Tag
              px={2}
              height="fit-content"
              color={
                renderJobResumeRecruiterStatusColor(jobResumeRecruiter.status)
                  ?.color
              }
              bg={
                renderJobResumeRecruiterStatusColor(jobResumeRecruiter.status)
                  ?.bg
              }
              borderRadius="base"
              border="1px solid"
              borderColor={
                renderJobResumeRecruiterStatusColor(jobResumeRecruiter.status)
                  ?.border
              }
            >
              <Text textStyle="body2Medium" textTransform="capitalize">
                {renderJobResumeRecruiterStatus(jobResumeRecruiter.status)}
              </Text>
            </Tag>
          )}
        </HStack>
      </HStack>

      <HStack spacing={4} divider={<StackDivider />}>
        {jobResumeRecruiter.title && (
          <HStack direction="row" spacing={3}>
            <JobsIcon w="20px" h="20px" fill="gray.500" />
            <Text
              textStyle="body1Regular"
              wordBreak="break-all"
              color="gray.900"
              fontWeight={400}
              noOfLines={1}
            >
              {jobResumeRecruiter.title}
            </Text>
          </HStack>
        )}

        {(jobResumeRecruiter.city?.name || jobResumeRecruiter.state) && (
          <HStack direction="row" spacing={3}>
            <LocationIcon w="20px" h="20px" color="gray.500" />

            <Text
              textStyle="body1Regular"
              color="gray.900"
              wordBreak="break-all"
              fontWeight={400}
              noOfLines={1}
            >
              {jobResumeRecruiter.city?.label}
            </Text>
          </HStack>
        )}
      </HStack>
    </Flex>
  );
}
