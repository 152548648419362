import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { DepartmentNanoId } from '../departments/departmentsTypes';
import { HiringPortalSourcingRoutes } from './HirngPortalSourcingRoutes';

const basePath = (
  departmentNanoId: DepartmentNanoId,
  jobCandidateNanoId: JobCandidateNanoId
) =>
  HiringPortalSourcingRoutes.viewPerson(departmentNanoId, jobCandidateNanoId);

export class HiringPortalSourcingJobCandidateDetailsRoutes {
  static index(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}`;
  }

  static edit(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/edit`;
  }

  static profile(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/profile`;
  }

  static tasks(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/tasks`;
  }

  static files(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/files`;
  }

  static assessment(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/assessment`;
  }

  static projects(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/projects`;
  }

  static references(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/references`;
  }

  static activities(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/activities`;
  }

  static scoreCards(
    departmentNanoId: DepartmentNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${basePath(departmentNanoId, jobCandidateNanoId)}/score-cards`;
  }
}
