import {
  JobCandidateSearchType,
  JobCandidateSearchTypes
} from '../../jobCandidatesTypes';

function renderJobCandidateSearchType(
  jobCandidateSearchTypeLabel: JobCandidateSearchType
) {
  switch (jobCandidateSearchTypeLabel) {
    case JobCandidateSearchTypes.NONE:
      return 'None';
    case JobCandidateSearchTypes.USER:
      return 'User';
    case JobCandidateSearchTypes.LEAD:
      return 'Lead';
    case JobCandidateSearchTypes.PROSPECT:
      return 'Recruit';
    case JobCandidateSearchTypes.APPLICANT:
      return 'Applicant';
    case JobCandidateSearchTypes.CUSTOMER:
      return 'Customer';
    default:
      return 'User';
  }
}

export default renderJobCandidateSearchType;
