import { HStack, Stack, StackDivider, VStack } from '@chakra-ui/react';
import compact from 'lodash/compact';
import truncate from 'lodash/truncate';
import { JobsIcon } from '../../../../icons/JobsIcon';
import { LocationIcon } from '../../../../icons/LocationIcon';
import { renderLocation } from '../../../../main/common/utils/renderLocation';
import { JobCandidateLabelHelper } from '../../../../main/jobCandidates/helpers/JobCandidateLabelHelper';
import { renderJobCandidateName } from '../../../../main/jobCandidates/utils/renderJobCandidateName';
import { AvatarHelper } from '../../../AvatarHelper';
import { Rating } from '../../../Rating';
import { Text } from '../../../Text';
import { ScheduleHelperJobCandidate } from '../../ScheduleHelper.types';

interface ScheduleHelperCandidateProps {
  jobCandidate: ScheduleHelperJobCandidate;
}

export default function ScheduleHelperCandidate({
  jobCandidate
}: ScheduleHelperCandidateProps) {
  const location = jobCandidate?.city && renderLocation(jobCandidate);

  return (
    <>
      <Stack>
        <HStack spacing={2}>
          <AvatarHelper h={8} w={8} image={jobCandidate.image} />

          <VStack spacing={-1.5} alignItems="start">
            <HStack spacing={2}>
              <Text
                flex={1}
                noOfLines={1}
                color="gray.900"
                textStyle="body1Medium"
                wordBreak="break-all"
              >
                {renderJobCandidateName(jobCandidate)}
              </Text>

              {jobCandidate.searchTypeLabel && (
                <JobCandidateLabelHelper
                  searchTypeLabel={jobCandidate.searchTypeLabel}
                />
              )}
            </HStack>

            <HStack spacing={4}>
              <Rating value={4} readonly />
            </HStack>
          </VStack>
        </HStack>

        <HStack spacing={4} divider={<StackDivider />}>
          <HStack direction="row" spacing={3}>
            {(jobCandidate.jobTitle || jobCandidate.company?.name) && (
              <JobsIcon w="20px" h="20px" fill="gray.500" />
            )}
            <Text
              textStyle="body1Regular"
              wordBreak="break-all"
              color="gray.900"
              fontWeight={400}
              noOfLines={1}
            >
              {compact([
                truncate(jobCandidate.jobTitle, {
                  length: 35,
                  omission: '...'
                }),
                jobCandidate.company?.name
              ]).join(' @ ')}
            </Text>
          </HStack>

          {location && (
            <HStack direction="row" spacing={3}>
              <LocationIcon w="20px" h="20px" color="gray.500" />

              <Text
                textStyle="body1Regular"
                color="gray.900"
                wordBreak="break-all"
                fontWeight={400}
                noOfLines={1}
              >
                {location}
              </Text>
            </HStack>
          )}
        </HStack>
      </Stack>
    </>
  );
}
