import {
  FetchDepartmentsCacheKey,
  FetchDepartmentCacheKey,
  DepartmentNanoId
} from './departmentsTypes';

export class DepartmentsCache {
  static indexCacheKey() {
    return 'departments' as FetchDepartmentsCacheKey;
  }

  static showCacheKey() {
    return 'department' as FetchDepartmentCacheKey;
  }

  static showDepartmentCacheKey(departmentNanoId: DepartmentNanoId) {
    return `department-${departmentNanoId}` as FetchDepartmentCacheKey;
  }

  static existsCacheKey() {
    return 'departments-exists' as FetchDepartmentsCacheKey;
  }
}
