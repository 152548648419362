import {
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDivider
} from '@chakra-ui/react';
import { Heading } from '../../../../../../helpers/Heading';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { renderJobTitle } from '../../../../../jobs/utils/renderJobTitle';
import { JobLocationHelper } from '../../../../../jobs/helpers/JobLocationHelper';
import { DeleteIcon } from '../../../../../../icons/DeleteIcon';
import { Text } from '../../../../../../helpers/Text';
import { JobPriorityDropdown } from '../../../../../updates/components/JobPriorityDropdown';
import { JobsIcon } from '../../../../../../icons/JobsIcon';
import { renderJobEmployment } from '../../../../../jobs/utils/renderJobEmployment';
import { ClockIcon } from '../../../../../../icons/ClockIcon';
import { RelativeDateHelper } from '../../../../../../helpers/RelativeDateHelper';
import { JobsRoutes } from '../../../../../jobs/JobsRoutes';
import { PureButtonWithRefHelper } from '../../../../../../helpers/buttons/PureButtonWithRefHelper';
import { ViewJobCandidateDetailsJobHiringTeam } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobHiringTeam';
import { ViewJobCandidateDetailsJobDetailsJob } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/components/ViewJobCandidateDetailsJobDetails';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSubmissionContext';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../../../../jobs/pages/ViewJobCandidateDetailsPage/contexts/ViewJobCandidateDetailsSendAssessmentContext';
import { IsFetched } from '../../../../../../types';
import { LoadingSkeleton } from '../../../../../../helpers/LoadingSkeleton';
import { HiringPortalRoutes } from '../../../../../hiringPortal/HiringPortalRoutes';

interface SourcingTalentProfileHeaderMenuProps {
  job: ViewJobCandidateDetailsJobDetailsJob;
  jobs?: ViewJobCandidateDetailsJobDetailsJob[];
  jobIsFetched: IsFetched;
  isHiringPortalPage?: boolean;
}

export default function SourcingTalentProfileHeaderMenu({
  job,
  jobs,
  jobIsFetched,
  isHiringPortalPage
}: SourcingTalentProfileHeaderMenuProps) {
  const { isCandidateSubmissionOpen } =
    useViewJobCandidateDetailsSubmissionContext();

  const { isSendEmailAssessmentOpen } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const isRightSidebarOpen =
    isCandidateSubmissionOpen || isSendEmailAssessmentOpen;

  return (
    <>
      <Flex
        h={14}
        top={0}
        gap={6}
        zIndex={99}
        position="sticky"
        overflow="hidden"
        bg={isRightSidebarOpen ? 'gray.50' : 'white'}
        pl={isRightSidebarOpen ? { base: 4, xl: 12 } : 4}
        pr={4}
      >
        {isRightSidebarOpen ? (
          <Flex alignItems="center">
            <Heading level="h3">
              Candidate {isCandidateSubmissionOpen && 'Submission'}{' '}
              {isSendEmailAssessmentOpen && 'Assessment'}
            </Heading>
          </Flex>
        ) : (
          <>
            <Flex
              top={0}
              flexDir="column"
              position="relative"
              transitionDuration="slow"
              w="300px"
            >
              <Flex flex={1} alignItems="center">
                <Heading level="h2" color="gray.900" noOfLines={1}>
                  Prospects
                </Heading>
              </Flex>
            </Flex>

            <Flex gap={4} flex={1} alignItems="center">
              <LoadingSkeleton count={1} loaded={jobIsFetched}>
                {job ? (
                  <HStack
                    ml="auto"
                    spacing={4}
                    divider={<StackDivider />}
                    flexWrap="wrap"
                  >
                    <Menu isLazy>
                      <MenuButton
                        sx={{
                          span: {
                            display: 'flex',
                            gap: '4px',
                            alignItems: 'center'
                          }
                        }}
                      >
                        <Heading
                          level="h2"
                          fontSize="md"
                          fontWeight="semibold"
                          lineHeight={1.5}
                        >
                          {renderJobTitle(job)}
                        </Heading>

                        {jobs?.length ? <ChevronDownIcon /> : null}
                      </MenuButton>

                      {jobs?.length ? (
                        <MenuList>
                          {jobs?.map((job) => (
                            <MenuItem key={job.nanoId}>
                              <Flex
                                justifyContent="space-between"
                                alignItems="start"
                                gap={2}
                              >
                                <Stack spacing={2}>
                                  <Heading
                                    level="h2"
                                    fontSize="md"
                                    fontWeight="semibold"
                                    lineHeight={1.5}
                                    color="primary.500"
                                  >
                                    {renderJobTitle(job)}
                                  </Heading>

                                  <JobLocationHelper
                                    textStyle="body2Regular"
                                    job={job}
                                  />
                                </Stack>

                                <DeleteIcon mt={1} />
                              </Flex>
                            </MenuItem>
                          ))}
                        </MenuList>
                      ) : null}
                    </Menu>

                    <Flex gap={2} alignItems="center">
                      <Text>Job Priority</Text>
                      <JobPriorityDropdown priority="high" />
                    </Flex>

                    <JobLocationHelper job={job} withIcon />

                    <HStack spacing={2}>
                      <JobsIcon w="20px" h="20px" color="gray.500" />

                      <Text>{renderJobEmployment(job.employment) || '-'}</Text>
                    </HStack>

                    <HStack spacing={2}>
                      <ClockIcon w="20px" h="20px" color="gray.500" />

                      <Text>
                        <RelativeDateHelper date={job.createdAt as string} />
                      </Text>
                    </HStack>

                    <NextLinkHelper
                      href={
                        isHiringPortalPage
                          ? HiringPortalRoutes.candidatesJobDetails(job.nanoId)
                          : JobsRoutes.viewDetails(job.nanoId)
                      }
                      passHref
                    >
                      <PureButtonWithRefHelper
                        as="a"
                        size="medium"
                        hierarchy="link"
                        i18nText="Job Details"
                        _hover={{ textDecoration: 'none' }}
                      />
                    </NextLinkHelper>

                    <ViewJobCandidateDetailsJobHiringTeam />
                  </HStack>
                ) : null}
              </LoadingSkeleton>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
}
