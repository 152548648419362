import {
  FetchDepartmentCacheKey,
  FetchDepartmentNanoID,
  FetchDepartmentQuery,
  FetchDepartmentEnabled,
  FetchDepartmentError,
  FetchDepartmentErrorMessage,
  FetchDepartmentIsFetched,
  FetchDepartmentIsRefetching,
  FetchDepartmentIsLoading,
  FetchDepartmentIsPlaceholderData
} from '../../departmentsTypes';

import { useFetchItemByNanoId } from '../../../common/hooks/useFetchItemByNanoId';

import { DepartmentsBffRequests } from '../../DepartmentsBffRequests';

interface FetchDepartmentByNanoIdOptions<FetchDepartmentResponseType> {
  departmentNanoId: FetchDepartmentNanoID;
  query: FetchDepartmentQuery;
  cacheKey: FetchDepartmentCacheKey;
  enabled?: FetchDepartmentEnabled;
  placeholderDepartment?: FetchDepartmentResponseType;
  placeholderData?: () => FetchDepartmentResponseType;
  onSuccess?: (data: FetchDepartmentResponseType | null) => void;
}

function useFetchDepartmentByNanoId<FetchDepartmentResponseType>({
  departmentNanoId,
  query,
  cacheKey,
  enabled,
  placeholderDepartment,
  placeholderData,
  onSuccess
}: FetchDepartmentByNanoIdOptions<FetchDepartmentResponseType>) {
  const {
    item,
    itemError,
    itemErrorMessage,
    itemIsFetched,
    itemIsRefetching,
    itemIsLoading,
    itemIsPlaceholderData,
    refetchItem
  } = useFetchItemByNanoId<FetchDepartmentResponseType, FetchDepartmentError>({
    itemNanoId: departmentNanoId,
    cacheKey,
    enabled,
    placeholderItem: placeholderDepartment,
    placeholderData,
    onSuccess,
    queryFn: () =>
      DepartmentsBffRequests.fetchDepartmentByNanoId<FetchDepartmentResponseType>(
        departmentNanoId,
        {
          query
        }
      )
  });

  return {
    department: item,
    departmentError: itemError,
    departmentErrorMessage: itemErrorMessage as FetchDepartmentErrorMessage,
    departmentIsFetched: itemIsFetched as FetchDepartmentIsFetched,
    departmentIsRefetching: itemIsRefetching as FetchDepartmentIsRefetching,
    departmentIsLoading: itemIsLoading as FetchDepartmentIsLoading,
    departmentIsPlaceholderData:
      itemIsPlaceholderData as FetchDepartmentIsPlaceholderData,
    refetchDepartment: refetchItem
  };
}

export default useFetchDepartmentByNanoId;
