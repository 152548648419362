import snakeCase from 'lodash/snakeCase';

import {
  FetchDepartmentQuery,
  DepartmentCreatedAt,
  DepartmentFields,
  DepartmentId,
  DepartmentNanoId,
  DepartmentName,
  DepartmentUser,
  DepartmentCompany,
  DepartmentCompanyId,
  DepartmentJobsCount
} from '../departmentsTypes';

export interface FetchDepartmentResponse {
  [DepartmentFields.ID]: DepartmentId;
  [DepartmentFields.NANO_ID]: DepartmentNanoId;
  [DepartmentFields.NAME]: DepartmentName;
  [DepartmentFields.USER]: DepartmentUser;
  [DepartmentFields.CREATED_AT]: DepartmentCreatedAt;
  [DepartmentFields.COMPANY]: DepartmentCompany;
  [DepartmentFields.COMPANY_ID]: DepartmentCompanyId;
  [DepartmentFields.JOBS_COUNT]: DepartmentJobsCount;
}

export const fetchDepartmentColumns = [
  DepartmentFields.ID,
  DepartmentFields.NANO_ID,
  DepartmentFields.NAME,
  DepartmentFields.CREATED_AT,
  DepartmentFields.COMPANY,
  DepartmentFields.COMPANY_ID,
  DepartmentFields.JOBS_COUNT
];

const fetchDepartmentSBColumns = fetchDepartmentColumns.map(
  (fetchDepartmentColumn) => snakeCase(fetchDepartmentColumn)
);

export const fetchDepartmentQuery = fetchDepartmentSBColumns.join(
  ','
) as FetchDepartmentQuery;
