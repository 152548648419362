import {
  Box,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  useDisclosure
} from '@chakra-ui/react';
import { MouseEvent } from 'react';
import { Button } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import { EyeIcon } from '../../../../../../../../icons/EyeIcon';
import {
  CandidateSubmissionFields,
  CandidateSubmissionVisibilityOptionTypeEnum
} from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';

export const ViewJobCandidateDetailsVisibilityOptionTypes = {
  [CandidateSubmissionVisibilityOptionTypeEnum.HiringManagers]:
    'Hiring Managers',
  [CandidateSubmissionVisibilityOptionTypeEnum.Recruiters]:
    'Hiring Managers, Recruiters',
  [CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam]:
    'All hiring team (Hiring Managers, Recruiters, Collaborators)'
};

export default function CustomiseCandidateSubmissionEvaluationVisibility() {
  const {
    visibilityOptionType,
    setCandidateSubmissionFormValue,
    validationErrors
  } = useViewJobCandidateDetailsSubmissionContext();

  const {
    isOpen: isOpenDropdown,
    onToggle: onToggleDropdown,
    onClose: onCloseDropdown
  } = useDisclosure();

  const visibilityArray = Object.values(
    CandidateSubmissionVisibilityOptionTypeEnum
  );

  const changeVisibility = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    setCandidateSubmissionFormValue(
      CandidateSubmissionFields.VISIBILITY_OPTION_TYPE,
      value as never
    );

    onCloseDropdown();
  };

  return (
    <Popover
      isOpen={isOpenDropdown}
      onClose={onCloseDropdown}
      placement="bottom-start"
      isLazy
    >
      <PopoverTrigger>
        <Stack p={4} bg="gray.100" spacing={1.5} borderRadius="base">
          <Text>Profile visibility</Text>
          <HStack spacing={2} alignItems="center">
            <EyeIcon />

            <Text color="gray.600" textStyle="body2Medium">
              Visible to
            </Text>
            <Flex>
              <Button
                size="small"
                hierarchy="link"
                color="primary.500"
                textStyle="body2Medium"
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleDropdown();
                }}
                _hover={{
                  textDecoration: 'none'
                }}
              >
                <Box as="span">{visibilityOptionType}</Box>

                <ChevronDownIcon w={4} h={4} fill="primary.500" />
              </Button>
            </Flex>
          </HStack>

          {validationErrors.visibilityOptionTypeValidationError ? (
            <Text color="red.500" textStyle="body2Medium" wordBreak="break-all">
              {validationErrors.visibilityOptionTypeValidationError}
            </Text>
          ) : null}

          <HStack spacing={2} alignItems="center">
            <EyeIcon />

            <Text color="gray.600" textStyle="body2Medium">
              Not visible to candidates
            </Text>
          </HStack>
        </Stack>
      </PopoverTrigger>

      <Portal>
        <PopoverContent w="350px" p={1} maxH={60} overflow="hidden">
          <Flex flexDir="column" overflow="auto">
            {visibilityArray.map((visibility) => {
              const isSelected = visibilityOptionType === visibility;

              return (
                <Button
                  h="auto"
                  size="small"
                  color="inherit"
                  textAlign="left"
                  lineHeight={1.5}
                  fontSize="inherit"
                  borderRadius="none"
                  fontWeight="inherit"
                  hierarchy="unstyled"
                  isDisabled={isSelected}
                  onClick={(e) => {
                    e.stopPropagation();
                    changeVisibility(e);
                  }}
                  value={visibility}
                  key={visibility}
                  _hover={{
                    bg: 'gray.100'
                  }}
                >
                  <Flex px={2} py={1.5} gap={1.5} alignItems="flex-start">
                    <Flex h={5} alignItems="center">
                      <Flex
                        w={2.5}
                        h={2.5}
                        flexShrink={0}
                        border="2px solid"
                        borderColor={isSelected ? 'primary.500' : 'gray.400'}
                        transitionDuration="slow"
                        justifyContent="center"
                        boxSizing="content-box"
                        borderRadius="full"
                        alignItems="center"
                      >
                        <Flex
                          w={1.5}
                          h={1.5}
                          rounded="full"
                          transitionDuration="slow"
                          bg={isSelected ? 'primary.500' : 'transparent'}
                        />
                      </Flex>
                    </Flex>

                    <Box
                      flex={1}
                      as="span"
                      fontSize="xs"
                      whiteSpace="break-spaces"
                    >
                      {ViewJobCandidateDetailsVisibilityOptionTypes[visibility]}
                    </Box>
                  </Flex>
                </Button>
              );
            })}
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
