import { Box, Grid, GridItem } from '@chakra-ui/layout';
import { Show, useMediaQuery } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ReactNode, useCallback } from 'react';

import { useSubscribeToPreviousRouter } from '../../common/hooks/utils/usePreviousRouter';
import { MainActiveLink, useLeftSidebarContext } from '../../context';

import { JobsRoutes } from '../../main/jobs/JobsRoutes';
import { MainRoutes } from '../../main/MainRoutes';
import { TalentPoolsRoutes } from '../../main/talentPools/TalentPoolsRoutes';

import { JobsPermissions } from '../../main/jobs/JobsPermissions';
import { MainPermissions } from '../../main/MainPermissions';

import { CandidatesIcon } from '../../icons/CandidatesIcon';
import { CareerSiteIcon } from '../../icons/CareerSiteIcon';
import { DashboardIcon } from '../../icons/DashboardIcon';
import { HelpIcon } from '../../icons/HelpIcon';
import { JobsIcon } from '../../icons/JobsIcon';
import { MenuIconRelationships } from '../../icons/MenuIconRelationships';
import { MenuIconScreening } from '../../icons/MenuIconScreening';
import { SettingsIcon } from '../../icons/SettingsIcon';

import { CheckPermissionLayout } from '../../main/common/layouts/CheckPermissionLayout';

import { Header } from './components/Header';
import { LeftSidebar } from './components/LeftSidebar';
import { RightSidebar } from './components/RightSidebar';

import { SidebarMenuItem } from '../../helpers/SidebarMenuItem';

import { MobileFooterNav } from '../../helpers/MobileFooterNav';

import { AppLayoutRequiredProps } from './AppLayout.types';
import { SourcingRoutes } from '../../main/sourcing/SourcingRoutes';
import { MenuIconPipelines } from '../../icons/MenuIconPipelines';
import { PersonIcon } from '../../icons/PersonIcon';
import { PipelineRoutes } from '../../main/pipeline/PipelineRoutes';

export type ActiveLink =
  | 'dashboard'
  | 'updates'
  | 'recruit'
  | 'hire'
  | 'jobs'
  | 'job-talent-pools'
  | 'screening'
  | 'pipeline'
  | 'calendar'
  | 'inbox'
  | 'sourcing'
  | 'prospects'
  | 'talent-pools'
  | 'relationships'
  | 'career-site'
  | 'settings'
  | 'help';

interface AppLayoutProps {
  action?: string;
  children: ReactNode;
  activeLink: MainActiveLink;
  showRightSidebar?: boolean;
}

function AppLayout({
  action,
  activeLink,
  onSignOut,
  children,
  headerMenuItems,
  totalTalentCredits,
  showRightSidebar = true
}: AppLayoutProps & AppLayoutRequiredProps) {
  const router = useRouter();

  useSubscribeToPreviousRouter();

  const { isDocked } = useLeftSidebarContext();

  const handleMenuItemOnMouseOver = useCallback<(url: string) => void>(
    (url) => router.prefetch(url),
    [router]
  );

  const [isDesktop] = useMediaQuery('(min-width: 992px)', {
    ssr: true,
    fallback: false
  });

  return (
    <CheckPermissionLayout
      appAction={MainPermissions.MAIN_APP_ACCESS}
      action={action || JobsPermissions.READ_JOBS_INDEX_PAGE}
    >
      <Show above="lg">
        <LeftSidebar colorScheme="dark">
          <SidebarMenuItem
            title="Dashboard"
            url={MainRoutes.index()}
            icon={DashboardIcon}
            isActive={activeLink === 'dashboard'}
            onMouseOver={handleMenuItemOnMouseOver}
          />
          <SidebarMenuItem
            title="Jobs"
            url={JobsRoutes.index()}
            icon={JobsIcon}
            isActive={activeLink === 'jobs'}
            onMouseOver={handleMenuItemOnMouseOver}
            isChildActive={['pipeline'].includes(activeLink)}
          >
            <SidebarMenuItem
              title="Pipelines"
              icon={MenuIconScreening}
              url={PipelineRoutes.index()}
              isActive={['pipeline'].includes(activeLink)}
              onMouseOver={handleMenuItemOnMouseOver}
            />
          </SidebarMenuItem>

          <SidebarMenuItem
            title="Talent Pools"
            url={TalentPoolsRoutes.index()}
            icon={CandidatesIcon}
            isActive={activeLink === 'talent-pools'}
            onMouseOver={handleMenuItemOnMouseOver}
            isChildActive={['relationships'].includes(activeLink)}
          >
            <SidebarMenuItem
              title="Relationships"
              url={TalentPoolsRoutes.relationships()}
              icon={MenuIconRelationships}
              isActive={activeLink === 'relationships'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
          </SidebarMenuItem>

          {/* <SidebarMenuItem
            title="Recruit"
            icon={RecruitIcon}
            url={RecruitRoutes.index()}
            isActive={activeLink?.startsWith('recruit')}
            onMouseOver={handleMenuItemOnMouseOver}
          /> */}

          <SidebarMenuItem
            title="Sourcing"
            url={SourcingRoutes.index()}
            icon={PersonIcon}
            isActive={activeLink === 'sourcing'}
            isChildActive={['prospects'].includes(activeLink)}
            onMouseOver={handleMenuItemOnMouseOver}
          >
            <SidebarMenuItem
              title="Prospects"
              url={SourcingRoutes.viewIndex()}
              icon={MenuIconPipelines}
              isActive={activeLink === 'prospects'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
          </SidebarMenuItem>

          <SidebarMenuItem
            title="Career Site"
            url="/career-site"
            icon={CareerSiteIcon}
            isActive={activeLink === 'career-site'}
            onMouseOver={handleMenuItemOnMouseOver}
          />

          <Box mt="auto">
            <SidebarMenuItem
              title="Settings"
              url={MainRoutes.settings()}
              icon={SettingsIcon}
              isActive={activeLink === 'settings'}
              onMouseOver={handleMenuItemOnMouseOver}
            />

            <SidebarMenuItem
              title="Help"
              url="/help"
              icon={HelpIcon}
              isActive={activeLink === 'help'}
              onMouseOver={handleMenuItemOnMouseOver}
            />
          </Box>
        </LeftSidebar>
      </Show>

      <Grid
        gridTemplateRows={{ lg: 'max-content 1fr' }}
        height="100%"
        as="section"
        bg="gray.50"
        h="100vh"
        flexDirection="column"
        transitionDuration="slow"
        transitionProperty="margin-left"
        ml={{ lg: isDocked ? 20 : 60 }}
        mr={{ lg: showRightSidebar ? '72px' : 0 }}
        position="relative"
        zIndex={1}
      >
        {isDesktop && (
          <GridItem display={{ base: 'none', lg: 'unset' }}>
            <Header
              onSignOut={onSignOut}
              headerMenuItems={headerMenuItems}
              totalTalentCredits={totalTalentCredits || 200}
            />
          </GridItem>
        )}

        <GridItem
          overflow="auto"
          h={{ base: 'calc(100vh - 68px)', lg: 'auto' }}
        >
          {children}
        </GridItem>

        {!isDesktop && <MobileFooterNav activeLink={activeLink} />}
      </Grid>

      {showRightSidebar && (
        <Show above="lg">
          <RightSidebar activeLink={activeLink} />
        </Show>
      )}
    </CheckPermissionLayout>
  );
}

export default AppLayout;
