import { Flex, HStack, Stack } from '@chakra-ui/react';
import join from 'lodash/join';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import { ReactNode } from 'react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { Text } from '../../../../../../../../helpers/Text';
import { CheckIcon } from '../../../../../../../../icons/CheckIcon';
import { CareerSiteApplyProfilePageJobResumeRecruiter } from '../../../../components/CareerSiteApplyProfilePage/CareerSiteApplyProfilePage.types';
import { renderJobResumeRecruiterStatus } from '../../../../../../../jobResumeRecruiters/utils/renderJobResumeRecruiterStatus';

interface CareerSiteApplyProfilePageOverviewPreferencesProps {
  jobResumeRecruiter: CareerSiteApplyProfilePageJobResumeRecruiter;
}

function CareerSiteApplyProfilePageOverviewPreferences({
  jobResumeRecruiter
}: CareerSiteApplyProfilePageOverviewPreferencesProps) {
  const { employmentTypes, workplaceTypes, cities, workAuthorization } =
    jobResumeRecruiter;

  return (
    <Stack p={4} gap={3} flex={1} bg="white" borderRadius="base">
      <Flex gap={2} alignItems="center">
        <Heading
          m={0}
          p={0}
          level="h4"
          fontSize="md"
          lineHeight={1}
          fontWeight="semibold"
        >
          Preferences
        </Heading>
      </Flex>

      <Stack spacing={4} color="gray.600">
        <OverviewPreferenceItem
          label="Employment status"
          value={startCase(jobResumeRecruiter.employmentStatus)}
        />

        <OverviewPreferenceItem
          label="Job market status"
          value={renderJobResumeRecruiterStatus(jobResumeRecruiter.status)}
        />

        <OverviewPreferenceItem
          label="Work authorization"
          value={startCase(workAuthorization)}
        />

        <OverviewPreferenceItem
          label="Employment type"
          value={startCase(join(employmentTypes, ', '))}
        />

        <OverviewPreferenceItem
          label="Preferred locations"
          value={join(map(cities, 'label'), ' / ')}
        />

        <OverviewPreferenceItem
          label="Workplace type"
          value={startCase(join(workplaceTypes, ', '))}
        />

        <OverviewPreferenceItem
          label="Phone call availability"
          value={startCase(jobResumeRecruiter.phoneCallAvailability)}
        />
      </Stack>
    </Stack>
  );
}

export default CareerSiteApplyProfilePageOverviewPreferences;

interface OverviewPreferenceItem {
  label: string;
  value: ReactNode;
  isMatch?: boolean;
}

const OverviewPreferenceItem = ({
  label,
  value,
  isMatch
}: OverviewPreferenceItem) => (
  <Stack spacing={1}>
    <HStack>
      <Text
        noOfLines={1}
        color="gray.800"
        wordBreak="break-all"
        textStyle="upperCase1Medium"
      >
        {label}
      </Text>

      {isMatch && <CheckIcon w={4} h={4} fill="green.600" />}
    </HStack>

    <Text textStyle="body1Regular" lineHeight={1.5}>
      {value || '--'}
    </Text>
  </Stack>
);
