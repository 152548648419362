import { StackProps, TypographyProps } from '@chakra-ui/react';
import toString from 'lodash/toString';
import { Controller, FieldValues } from 'react-hook-form';
import { MapSizeVariant } from '../../../../utils/mapSize';
import {
  RadioGroupFieldControl,
  RadioGroupFieldControlOnInputChange,
  RadioGroupFieldControlOptions
} from './components/RadioGroupFieldControl';
import {
  RadioGroupFieldReactHookFormControl,
  RadioGroupFieldReactHookFormFieldPath
} from './RadioGroupField.types';

interface RadioGroupFieldProps<T extends FieldValues> {
  control: RadioGroupFieldReactHookFormControl<T>;
  name: RadioGroupFieldReactHookFormFieldPath<T>;
  errorMessage?: string | null;
  options: RadioGroupFieldControlOptions;
  onInputChange?: RadioGroupFieldControlOnInputChange;
  stackDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  fontSize?: TypographyProps['fontSize'];
  spacing?: StackProps['spacing'];
  size?: MapSizeVariant;
  isRequired?: boolean;
  label?: string;
}

function RadioGroupField<T extends FieldValues>({
  name,
  size,
  label,
  options,
  spacing,
  control,
  fontSize,
  isRequired,
  errorMessage,
  stackDirection
}: RadioGroupFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        return (
          <RadioGroupFieldControl
            name={name}
            size={size}
            label={label}
            spacing={spacing}
            options={options}
            onChange={onChange}
            fontSize={fontSize}
            isRequired={isRequired}
            errorMessage={errorMessage}
            stackDirection={stackDirection}
            value={toString(value)}
          />
        );
      }}
    />
  );
}

export default RadioGroupField;
