import {
  FieldBrand,
  DateTime,
  ItemID,
  ItemUUID,
  ItemNanoId,
  ItemCreatedAt,
  MayBe
} from '../../../types';

import {
  CityId,
  CityNanoId,
  CityName,
  CityLabel
} from '../../cities/citiesTypes';

import {
  CompanyId,
  CompanyNanoId,
  CompanyName,
  CompanyImage
} from '../../companies/companiesTypes';

import { JobCandidateId } from '../../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../../jobResumeRecruiters/jobResumeRecruitersTypes';

import {
  StateId,
  StateNanoId,
  StateName,
  StateIsoCode
} from '../../states/statesTypes';

import {
  UserId,
  UserNanoId,
  UserName,
  UserImage
} from '../../users/usersTypes';
import { JobDepartmentKey } from '../../jobs/jobsTypes';
import {
  IndustryIds,
  IndustryId,
  IndustryName
} from '../../industries/industriesTypes';
import {
  SpecializationIds,
  SpecializationId,
  SpecializationName
} from '../../specializations/specializationsTypes';
import { JobTitleId, JobTitleName } from '../../jobTitles/jobTitlesTypes';
import {
  DisciplineId,
  DisciplineName
} from '../../disciplines/disciplinesTypes';
import {
  JobFunctionId,
  JobFunctionIds,
  JobFunctionName
} from '../../jobFunctions/jobFunctionsTypes';

export type EmploymentId = FieldBrand<ItemID, 'EmploymentId'>;
export type EmploymentUuid = FieldBrand<ItemUUID, 'EmploymentUuid'>;
export type MayBeEmploymentUuid = MayBe<EmploymentUuid>;
export type EmploymentNanoId = FieldBrand<ItemNanoId, 'EmploymentNanoId'>;
export type MayBeEmploymentNanoId = MayBe<EmploymentNanoId>;
export type EmploymentName = FieldBrand<string, 'EmploymentName'>;
export type EmploymentTitle = FieldBrand<string, 'EmploymentTitle'>;
export type MayBeEmploymentTitle = MayBe<EmploymentTitle>;
export type EmploymentCompanyName = FieldBrand<string, 'EmploymentCompanyName'>;
// export type EmploymentJobFunction = FieldBrand<string, 'EmploymentJobFunction'>;
export type EmploymentEmploymentType = FieldBrand<
  EmploymentTypes,
  'EmploymentEmploymentType'
>;
export type EmploymentDescription = FieldBrand<string, 'EmploymentDescription'>;
export type EmploymentStartDate = FieldBrand<DateTime, 'EmploymentStartDate'>;
export type MayBeEmploymentStartDate = MayBe<EmploymentStartDate>;
export type EmploymentEndDate = FieldBrand<DateTime, 'EmploymentEndDate'>;
export type MayBeEmploymentEndDate = MayBe<EmploymentEndDate>;
export type EmploymentCurrently = FieldBrand<boolean, 'EmploymentCurrently'>;
export type EmploymentUserId = FieldBrand<UserId, 'EmploymentUserId'>;
export type EmploymentUser = FieldBrand<
  {
    id: UserId;
    nanoId: UserNanoId;
    name: UserName;
    image: UserImage;
  },
  'EmploymentUser'
>;
export type EmploymentJobTitleId = FieldBrand<
  JobTitleId,
  'EmploymentJobTitleId'
>;
export type EmploymentJobTitle = FieldBrand<
  {
    id: JobTitleId;
    name: JobTitleName;
  },
  'EmploymentJobTitle'
>;
export type EmploymentDisciplineId = FieldBrand<
  DisciplineId,
  'EmploymentDisciplineId'
>;
export type EmploymentDiscipline = FieldBrand<
  {
    id: DisciplineId;
    name: DisciplineName;
  },
  'EmploymentDiscipline'
>;
export type EmploymentJobFunctionId = FieldBrand<
  JobFunctionId,
  'EmploymentJobFunctionId'
>;
export type EmploymentJobFunctionIds = FieldBrand<
  JobFunctionIds,
  'EmploymentJobFunctionIds'
>;
export type EmploymentJobFunction = FieldBrand<
  {
    id: JobFunctionId;
    name: JobFunctionName;
  },
  'EmploymentJobFunction'
>;
export type EmploymentJobFunctions = FieldBrand<
  {
    id: JobFunctionId;
    name: JobFunctionName;
  }[],
  'EmploymentJobFunctions'
>;
export type EmploymentCompanyId = FieldBrand<CompanyId, 'EmploymentCompanyId'>;
export type MayBeEmploymentCompanyId = MayBe<EmploymentCompanyId>;
export type EmploymentCompany = FieldBrand<
  {
    id: CompanyId;
    nanoId: CompanyNanoId;
    name: CompanyName;
    image: CompanyImage;
  },
  'EmploymentCompany'
>;
export type MayBeEmploymentCompany = MayBe<EmploymentCompany>;
export type EmploymentStateId = FieldBrand<StateId, 'EmploymentStateId'>;
export type MayBeEmploymentStateId = MayBe<EmploymentStateId>;
export type EmploymentState = FieldBrand<
  {
    id: StateId;
    nanoId: StateNanoId;
    name: StateName;
    isoCode: StateIsoCode;
  },
  'EmploymentState'
>;
export type MayBeEmploymentState = MayBe<EmploymentState>;
export type EmploymentCityId = FieldBrand<CityId, 'EmploymentCityId'>;
export type MayBeEmploymentCityId = MayBe<EmploymentCityId>;
export type EmploymentCity = FieldBrand<
  {
    id: CityId;
    nanoId: CityNanoId;
    name: CityName;
    label: CityLabel;
  },
  'EmploymentCity'
>;
export type MayBeEmploymentCity = MayBe<EmploymentCity>;
export type EmploymentJobCandidateId = FieldBrand<
  JobCandidateId,
  'EmploymentJobCandidateId'
>;
export type MayBeEmploymentJobCandidateId = MayBe<EmploymentJobCandidateId>;
export type EmploymentJobResumeId = FieldBrand<
  JobResumeId,
  'EmploymentJobResumeId'
>;
export type MayBeEmploymentJobResumeId = MayBe<EmploymentJobResumeId>;
export type EmploymentJobResumeRecruiterId = FieldBrand<
  JobResumeRecruiterId,
  'EmploymentJobResumeRecruiterId'
>;
export type MayBeEmploymentJobResumeRecruiterId =
  MayBe<EmploymentJobResumeRecruiterId>;
export type EmploymentCreatedAt = FieldBrand<
  ItemCreatedAt,
  'EmploymentCreatedAt'
>;
export type EmploymentJobDepartment = FieldBrand<
  JobDepartmentKey,
  'EmploymentJobDepartment'
>;
export type MayBeEmploymentJobDepartment = MayBe<EmploymentJobDepartment>;

export type MayBeEmploymentReasonForLeaving = FieldBrand<
  string,
  'EmploymentReasonForLeaving'
>;

export type MayBeEmploymentMoreAboutLeaving = FieldBrand<
  string,
  'EmploymentMoreAboutLeaving'
>;

export type MayBeEmploymentSupervisorsName = FieldBrand<
  string,
  'EmploymentSupervisorsName'
>;

export type EmploymentJobSatisfaction = FieldBrand<
  string,
  'EmploymentJobSatisfaction'
>;

export type MayBeEmploymentSupervisorsTitle = FieldBrand<
  string,
  'EmploymentSupervisorsTitle'
>;
// export type EmploymentJobFunctions = FieldBrand<
//   string[],
//   'EmploymentJobDepartments'
// >;

export type EmploymentSpecializationIds = FieldBrand<
  SpecializationIds,
  'JobResumeRecruiterSpecializationIds'
>;
export type EmploymentSpecializations = FieldBrand<
  { id: SpecializationId; name: SpecializationName }[],
  'JobResumeRecruiterSpecializations'
>;

export type EmploymentIndustryIds = FieldBrand<
  IndustryIds,
  'JobResumeRecruiterIndustryIds'
>;
export type EmploymentIndustries = FieldBrand<
  { id: IndustryId; name: IndustryName }[],
  'JobResumeRecruiterIndustries'
>;

export const enum EmploymentTypes {
  NONE = 'none',
  FULL_TIME = 'full_time',
  PART_TIME = 'part_time',
  CONTRACT = 'contract',
  INTERN = 'intern'
}

export const employmentTypes: EmploymentEmploymentType[] = [
  EmploymentTypes.NONE as EmploymentEmploymentType,
  EmploymentTypes.FULL_TIME as EmploymentEmploymentType,
  EmploymentTypes.PART_TIME as EmploymentEmploymentType,
  EmploymentTypes.CONTRACT as EmploymentEmploymentType,
  EmploymentTypes.INTERN as EmploymentEmploymentType
];

export const enum EmploymentFields {
  ID = 'id',
  UUID = 'uuid',
  NANO_ID = 'nanoId',
  NAME = 'name',
  EMPLOYMENT_TYPE = 'employmentType',
  DESCRIPTION = 'description',
  JOB_DEPARTMENT = 'jobDepartment',
  JOB_FUNCTION = 'jobFunction',
  JOB_FUNCTION_ID = 'jobFunctionId',
  JOB_FUNCTIONS = 'jobFunctions',
  JOB_FUNCTION_IDS = 'jobFunctionIds',
  DISCIPLINE_ID = 'disciplineId',
  DISCIPLINE = 'discipline',
  JOB_TITLE_ID = 'jobTitleId',
  JOB_TITLE = 'jobTitle',
  SPECIALIZATION_IDS = 'specializationIds',
  SPECIALIZATIONS = 'specializations',
  INDUSTRY_IDS = 'industryIds',
  INDUSTRIES = 'industries',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  CURRENTLY = 'currently',
  USER_ID = 'userId',
  USER = 'user',
  CONTACT_ID = 'contactId',
  COMPANY_ID = 'companyId',
  COMPANY = 'company',
  STATE_ID = 'stateId',
  STATE = 'state',
  CITY_ID = 'cityId',
  CITY = 'city',
  JOB_CANDIDATE_ID = 'jobCandidateId',
  JOB_RESUME_ID = 'jobResumeId',
  JOB_RESUME_RECRUITER_ID = 'jobResumeRecruiterId',
  CREATED_AT = 'createdAt',
  SUPERVISORS_NAME = 'supervisorsName',
  SUPERVISORS_TITLE = 'supervisorsTitle',
  REASON_FOR_LEAVING = 'reasonForLeaving',

  MORE_ABOUT_LEAVING = 'moreAboutLeaving',

  JOB_SATISFACTION = 'jobSatisfaction'
}
