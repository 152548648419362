import { usePaginatedItems } from '../../../common/hooks/usePaginatedItems';

import {
  FetchJobCandidatesQuery,
  FetchJobCandidatesCacheKey,
  FetchJobCandidatesTotalCount,
  FetchJobCandidatesPage,
  FetchJobCandidatesPageSize,
  FetchJobCandidatesError,
  FetchJobCandidatesErrorMessage,
  FetchJobCandidatesIsLoading,
  FetchJobCandidatesIsFetched,
  FetchJobCandidatesIsPlaceholderData,
  FetchJobCandidatesEnabled,
  FetchJobCandidatesPrefetchNextPage,
  FetchJobCandidatesCountType,
  FetchJobCandidatesFilters,
  FetchJobCandidatesSort,
  FetchJobCandidateID,
  FetchJobCandidateQuery,
  FetchJobCandidateCacheKey
} from '../../jobCandidatesTypes';

import { JobCandidatesRequests } from '../../JobCandidatesRequests';
import { JobCandidatesBffRequests } from '../../JobCandidatesBffRequests';

interface PaginatedJobCandidatesBaseOptions<
  PaginatedJobCandidatesResponseType
> {
  cacheKey: FetchJobCandidatesCacheKey;
  query: FetchJobCandidatesQuery;
  countType?: FetchJobCandidatesCountType;
  initialPage?: FetchJobCandidatesPage;
  initialPageSize?: FetchJobCandidatesPageSize;
  initialFilters?: FetchJobCandidatesFilters;
  initialSort?: FetchJobCandidatesSort;
  enabled?: FetchJobCandidatesEnabled;
  prefetchNextPage?: FetchJobCandidatesPrefetchNextPage;
  placeholderJobCandidates?: PaginatedJobCandidatesResponseType[];
  placeholderJobCandidatesCount?: FetchJobCandidatesTotalCount;
  placeholderData?: () => PaginatedJobCandidatesResponse<PaginatedJobCandidatesResponseType>;
  onSuccess?: (
    data: PaginatedJobCandidatesResponse<PaginatedJobCandidatesResponseType>
  ) => void;
}

interface PaginatedJobCandidatesOptionsWithoutItem {
  fetchItemCacheKey?: never;
  itemQuery?: never;
}

interface PaginatedJobCandidatesOptionsWithItem {
  fetchItemCacheKey: FetchJobCandidateCacheKey;
  itemQuery: FetchJobCandidateQuery;
}

type PaginatedJobCandidatesOptions<PaginatedJobCandidatesResponseType> =
  PaginatedJobCandidatesBaseOptions<PaginatedJobCandidatesResponseType> &
    (
      | PaginatedJobCandidatesOptionsWithoutItem
      | PaginatedJobCandidatesOptionsWithItem
    );

interface PaginatedJobCandidatesResponse<PaginatedJobCandidatesResponseType> {
  data: PaginatedJobCandidatesResponseType[];
  totalCount?: FetchJobCandidatesTotalCount;
}

function usePaginatedJobCandidates<PaginatedJobCandidatesResponseType>({
  cacheKey,
  query,
  countType,
  initialPage,
  initialPageSize,
  initialFilters,
  initialSort,
  enabled,
  prefetchNextPage,
  placeholderJobCandidates,
  placeholderJobCandidatesCount,
  placeholderData,
  fetchItemCacheKey,
  itemQuery,
  onSuccess
}: PaginatedJobCandidatesOptions<PaginatedJobCandidatesResponseType>) {
  const {
    items,
    itemsTotalCount,
    itemsError,
    itemsErrorMessage,
    itemsIsFetched,
    itemsIsLoading,
    itemsIsPlaceholderData,
    itemsCurrentPage,
    itemsCurrentPageSize,
    itemsCurrentFilters,
    itemsCurrentSort,
    fetchItems,
    paginateItems,
    changeItemsPageSize,
    filterItems,
    changeItemsFilters,
    clearItemsFilters,
    sortItems,
    prefetchItems,
    prefetchItem
  } = usePaginatedItems<PaginatedJobCandidatesResponseType>({
    cacheKey,
    initialPage,
    initialPageSize,
    initialFilters,
    initialSort,
    enabled,
    prefetchNextPage,
    placeholderItems: placeholderJobCandidates,
    placeholderItemsCount: placeholderJobCandidatesCount,
    placeholderData,
    onSuccess: (data) =>
      onSuccess?.(
        data as PaginatedJobCandidatesResponse<PaginatedJobCandidatesResponseType>
      ),
    queryFn: (params) =>
      JobCandidatesBffRequests.fetchJobCandidates<PaginatedJobCandidatesResponseType>(
        {
          query,
          countType,
          page: params.page as FetchJobCandidatesPage,
          pageSize: params.pageSize as FetchJobCandidatesPageSize,
          filters: params.filters as FetchJobCandidatesFilters,
          sort: params.sort as FetchJobCandidatesSort
        }
      ),
    ...(itemQuery
      ? {
          fetchItemCacheKey,
          queryItemFn: (itemId) =>
            JobCandidatesRequests.fetchJobCandidate(
              itemId as FetchJobCandidateID,
              {
                query: itemQuery
              }
            )
        }
      : {})
  });

  return {
    jobCandidates: items as PaginatedJobCandidatesResponseType[],
    jobCandidatesTotalCount: itemsTotalCount as FetchJobCandidatesTotalCount,
    jobCandidatesError: itemsError as FetchJobCandidatesError,
    jobCandidatesErrorMessage:
      itemsErrorMessage as FetchJobCandidatesErrorMessage,
    jobCandidatesIsLoading: itemsIsLoading as FetchJobCandidatesIsLoading,
    jobCandidatesIsFetched: itemsIsFetched as FetchJobCandidatesIsFetched,
    jobCandidatesIsPlaceholderData:
      itemsIsPlaceholderData as FetchJobCandidatesIsPlaceholderData,
    jobCandidatesCurrentPage: itemsCurrentPage as FetchJobCandidatesPage,
    jobCandidatesCurrentPageSize:
      itemsCurrentPageSize as FetchJobCandidatesPageSize,
    jobCandidatesCurrentFilters:
      itemsCurrentFilters as FetchJobCandidatesFilters,
    jobCandidatesCurrentSort: itemsCurrentSort as FetchJobCandidatesSort,
    fetchJobCandidates: fetchItems,
    paginateJobCandidates: paginateItems,
    changeJobCandidatesPageSize: changeItemsPageSize,
    filterJobCandidates: filterItems,
    changeJobCandidatesFilters: changeItemsFilters,
    clearJobCandidatesFilters: clearItemsFilters,
    sortJobCandidates: sortItems,
    prefetchJobCandidates: prefetchItems,
    prefetchJobCandidate: prefetchItem
  };
}

export default usePaginatedJobCandidates;
