export const VERSION = '0.0.190';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const SHOW_DEBUG = process.env.NEXT_PUBLIC_SHOW_DEBUG || false;
export const USE_CONSOLE = process.env.NEXT_PUBLIC_USE_CONSOLE || false;

export const SKIP_PERMISSIONS_CHECKS =
  process.env.SKIP_PERMISSIONS_CHECKS || false;

export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME;
export const APP_HOST = process.env.NEXT_PUBLIC_APP_HOST || '';

export const BFF_URI =
  process.env.BFF_URI || process.env.NEXT_PUBLIC_BFF_URI || '';

export const SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL || '';
export const SUPABASE_ANON_KEY =
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY || '';

export const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

export const STATIC_URL = process.env.NEXT_PUBLIC_STATIC_URL;

export const DEFAULT_COUNTRY_ID = process.env.NEXT_PUBLIC_DEFAULT_COUNTRY_ID;

export const DEFAULT_COUNTRY_CODE =
  process.env.NEXT_PUBLIC_DEFAULT_COUNTRY_CODE;

export const GMAIL_OAUTH_CLIENT_ID =
  process.env.NEXT_PUBLIC_GMAIL_OAUTH_CLIENT_ID || '';
export const GMAIL_OAUTH_REDIRECT_URI =
  process.env.NEXT_PUBLIC_GMAIL_OAUTH_REDIRECT_URI || '';

export const TERMS_PAGE_NANO_ID =
  process.env.NEXT_PUBLIC_TERMS_PAGE_NANO_ID || '';
export const PRIVACY_POLICY_PAGE_NANO_ID =
  process.env.NEXT_PUBLIC_PRIVACY_POLICY_PAGE_NANO_ID || '';
